import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class TermoService {
  constructor(private http: HttpClient) { }
  baseUrl = "api/Termo/"

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8'
    })
  }

  upload(arquivo: File) {
    const file: File = arquivo;
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    return this.http.post(this.baseUrl + 'upload', formData);
  }

  ultimaAtualizacao() {
    return this.http.get<any>(this.baseUrl);
  }

}
