import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { LoginService } from "../../services/loginService";
import { SolicitarVinculoService } from "../../services/solicitarVinculoService";
import { ToastService } from "../../services/toastService";
import moment from 'moment';

@Component({
  selector: 'app-criar-vinculo',
  templateUrl: './criar.html',
})
export class CriarVinculo {
  idInfoUsuario: number;
  idInfoUsuarioVincular: number;
  cnpj: string;
  nomeFantasia: string;
  razaoSocial: string;
  endereco: string;
  codigoConsulta: any;

  constructor(private loginService: LoginService,
    private route: ActivatedRoute,
    private vinculoService: SolicitarVinculoService,
    private toastService: ToastService,
    private router: Router) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      if (params.has("id")) {
        this.idInfoUsuario = +params.get("id");
      }
    });
  }

  consultarCodigo() {

    if (this.codigoConsulta == null || this.codigoConsulta.trim() == "") { this.toastService.show('Informe um código', { classname: 'bg-warning text-light', delay: 5000 }); return; } 

    this.loginService.obterInfoEmpresa(this.codigoConsulta).subscribe(res => {
      if (res == null) { this.toastService.show('Grupo não encontrado', { classname: 'bg-warning text-light', delay: 5000 }); return; }

      this.idInfoUsuarioVincular = res.id;
      this.cnpj = res.cnpj;
      this.nomeFantasia = res.nomeFantasia;
      this.razaoSocial = res.razaoSocial;
      this.endereco = res.endereco;
    });
  }

  limparConsulta() {
    this.idInfoUsuarioVincular = null;
    this.cnpj = null;
    this.nomeFantasia = null;
    this.razaoSocial = null;
    this.codigoConsulta = null;
  }

  efetuarSolicitacao() {

    if (this.idInfoUsuario == null) { this.toastService.show('Não foi possível te indentificar, tente novamente mais tarde', { classname: 'bg-warning text-light', delay: 5000 }); return }
    if (this.idInfoUsuarioVincular == null) { this.toastService.show('Pesquise um grupo para vincular', { classname: 'bg-warning text-light', delay: 5000 }); return }
    var data = moment().format('YYYY-MM-DDTHH:mm');
    this.vinculoService.criarSolicitacao(new DtoCriarSolicitacaoVinculo(this.idInfoUsuario, this.idInfoUsuarioVincular, data)).subscribe(res => {
      if (res == null) { this.toastService.show('Erro ao solicitar vinculo, tente novamenete', { classname: 'bg-warning text-light', delay: 5000 }); return}
      if (res.codigo != 200) { this.toastService.show(res.mensagem, { classname: 'bg-warning text-light', delay: 5000 }); return}

      var currentUser = this.loginService.currentUserValue;
      if (currentUser != null) {
        this.toastService.show('Solicitação criada com sucesso!', { classname: 'bg-success text-light', delay: 5000 });
        this.router.navigate(['/usuario/editar/' + currentUser.usuarioId]);
      }
    });
  }
}

export class DtoCriarSolicitacaoVinculo {
  idInfoUsuarioSolicitante: number;
  idInfoUsuarioVinculado: number;
  dtSolicitacao: any;

  constructor(idInfoUsuarioSolicitante, idInfoUsuarioVinculado, dtSolicitacao) {
    this.idInfoUsuarioSolicitante = idInfoUsuarioSolicitante;
    this.idInfoUsuarioVinculado = idInfoUsuarioVinculado;
    this.dtSolicitacao = dtSolicitacao;
  }
}
