import { Component } from '@angular/core';
import { ToastService } from '../../services/toastService';
import { ComponenteService } from '../../services/componenteService';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import { Router } from '@angular/router';
import { PortoService } from '../../services/portoService';
@Component({
  selector: 'app-listagemComponente',
  templateUrl: './listagemComponente.html',
  styleUrls: ['./listagemComponente.css']
})
export class ListagemComponente {
  modalOptions: NgbModalOptions;
  title = 'ng-bootstrap-modal-demo';
  closeResult: string;
  resposta: object = {};
  listaComponente: any[] = [];
  listaPortos: any[] = [];
  componenteSelecionado: any = {};
  novoComponente: Componente = new Componente();
  edComponente: any = {};
  tamanhosPaginas = [25, 50, 75, 100];
  filtro: any = {
    tamanhoPagina: 100,
    numeroPagina: 1
  };
  carregando = false;
  constructor(
    private router: Router,
    private componenteService: ComponenteService,
    private portoService: PortoService,
    private toastService: ToastService,
    private modalService: NgbModal) {

    this.modalOptions = {
      backdrop: 'static',
      backdropClass: 'customBackdrop'
    }
  }

  ngOnInit() {
    this.obterListaComponente(1);
    this.carregarPortos();
  }


  obterListaComponente(pagina) {
    this.filtro.numeroPagina = pagina;
    this.carregando = true;
    this.componenteService.obterListaComponente(this.filtro).subscribe(response => {
      this.carregando = false;
      if (response.codigo === 200) {
        this.listaComponente = response.result.retorno;
        this.filtro.total = response.result.qtdTotal;
      }
      else
        this.toastService.show(response.mensagem, { classname: 'bg-danger text-light', delay: 5000 })
    })
  }

  limparCampo() {
    this.novoComponente = new Componente();
  }

  carregarPortos() {
    this.portoService.obterTodosAtivos().subscribe(response => {
      this.listaPortos = response.result;
    });
  }

  selecionarComponente(id: number, ehEdicao: boolean) {
    this.componenteSelecionado = this.listaComponente.find((componente) => {
      return componente.componenteId === id;
    })
    if (ehEdicao) {
      this.edComponente.componenteId = this.componenteSelecionado.componenteId;
      this.edComponente.nome = this.componenteSelecionado.nome;
      this.edComponente.ativo = this.componenteSelecionado.ativo;
      this.edComponente.portoId = this.componenteSelecionado.portoId;
    }
  }

  cadastrarComponente(nome, portoId) {
    this.novoComponente.nome = nome;
    this.novoComponente.portoId = portoId;
    if (this.validarComponente(false)) {
      this.componenteService.cadastrarComponente(this.novoComponente).subscribe(response => {
        if (response.codigo === 200) {
          this.toastService.show('Componente cadastrado com sucesso!', { classname: 'bg-success text-light', delay: 5000 })
          this.obterListaComponente(1);
          this.router.navigate([`/componenteItem/${response.result}`]);
        }
        else {
          this.toastService.show(response.mensagem, { classname: 'bg-danger text-light', delay: 5000 })
        }
        this.modalService.dismissAll();
      });
    }
  }

  validarComponente(ehEdicao: boolean): boolean {

    if (ehEdicao) {
      return this.validarDados(this.edComponente);
    }

    if (!ehEdicao) {
      return this.validarDados(this.novoComponente);
    }
  }

  validarDados(componente: any): boolean {
    if (componente.nome == "" || componente.nome == undefined) {
      this.toastService.show("Preencha o nome do Componente", { classname: 'bg-warning text-light', delay: 5000 })
      return false;
    }


    return true;
  }

  inativarComponente() {
    this.componenteService.inativarComponente(this.componenteSelecionado.componenteId).subscribe(response => {
      if (response.codigo === 200) {
        this.toastService.show('Componente inativado com sucesso!', { classname: 'bg-success text-light', delay: 5000 })
        this.listaComponente = this.listaComponente.filter((componente) => {
          return componente.componenteId !== this.componenteSelecionado.componenteId;
        });
      }
      else {
        this.toastService.show(response.mensagem, { classname: 'bg-danger text-light', delay: 5000 })
      }
      this.modalService.dismissAll();
    });
  }



  open(content) {

    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', backdrop: false }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  editarComponente() {
    if (this.validarComponente(true)) {

      this.componenteService.editarComponente(this.edComponente).subscribe(response => {
        if (response.codigo === 200) {
          this.toastService.show('Componente editado com sucesso!', { classname: 'bg-success text-light', delay: 5000 })
          this.obterListaComponente(1);
        }
        else {
          this.toastService.show(response.mensagem, { classname: 'bg-danger text-light', delay: 5000 })
        }
        this.modalService.dismissAll();
      })
    };
  }



  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


}

export class Componente {
  componenteId: number;
  nome: string;
  dtCadastro: string;
  portoId: number;

  constructor() {
    this.dtCadastro = moment().format('YYYY-MM-DDTHH:mm');
    this.nome = "";
    this.portoId = null;
  }

}
