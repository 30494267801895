import { Component, ViewChild } from '@angular/core';
import { FreteService } from '../../services/freteService';
import { PracaService } from '../../services/pracaService';
import { ToastService } from '../../services/toastService';
import { PortoService } from '../../services/portoService';
import { ActivatedRoute } from '@angular/router';
import * as XLSX from 'xlsx';
import { EstadoService } from '../../services/estadoService';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
@Component({
  selector: 'app-fretes',
  templateUrl: './fretes.html',
  styleUrls: ['./frete.css'],
})

export class Fretes {
  locale = 'pt-br';
  constructor(private freteService: FreteService, private pracaService: PracaService, private toastService: ToastService, private route: ActivatedRoute,
    private portoService: PortoService, private estadoService: EstadoService, private _localeService: BsLocaleService) {
    this._localeService.use(this.locale);
  }

  editando: boolean = false;
  id: number = 0;
  praca: any = {};

  carregando: boolean = false;

  filtroFerrovia: any = {
    tamanhoPagina: 10,
    numeroPagina: 1,
  };
  filtroRodovia: any = {
    tamanhoPagina: 10,
    numeroPagina: 1,
  };
  filtro: any = {};

  listaFerrovia: any[] = [];
  listaRodovia: any = [];
  listaTotais: any = [];
  anosRodo: any = [];
  anosFerro: any = [];

  frete: any = {};
  freteRodovia: any = {
    dataRodo: ""
  }
  freteFerrovia: any = {
    dataFerro: ""
  }

  listaEstado: Array<DtoEstado>;

  dropdownSettings = {
    singleSelection: false,
    idField: 'idEstado',
    textField: 'nomeEstado',
    selectAllText: 'Selecionar Todos',
    unSelectAllText: 'Desmarcar Todos',
    itemsShowLimit: 3,
    allowSearchFilter: true
  };

  nomePorto: string;
  portosList: any[];
  @ViewChild('tabset', { static: true }) tabset: any;
  loading = false;

  ngOnInit() {

    this.route.paramMap.subscribe(params => {
      this.id = +params.get("id");
    });

    this.filtroFerrovia.pracaId = this.id;
    this.filtroRodovia.pracaId = this.id;
    this.frete.pracaId = this.id;

    if (this.id > 0) {
      this.obterPortos();
      //this.obterPracaPorId(this.id);
      this.obterTodosEstados();
    }

  }

  cadastrarFrete(tipoFrete, dataFrete, valor) {

    this.frete.dataFrete = dataFrete;
    this.frete.valor = valor;
    this.frete.tipoFrete = tipoFrete.toString();
    this.frete.estadosSelecionados = this.filtro.estadosSelecionados;

    if (!this.validarCadastroFrete()) return;

    this.freteService.cadastrarFrete(this.frete).subscribe(response => {
      if (response.codigo === 200 && response.result) {
        this.toastService.show('Frete cadastrado com sucesso!', { classname: 'bg-success text-light', delay: 5000 })
        this.obterFretes();
      }
      else {
        this.toastService.show(response.mensagem, { classname: 'bg-danger text-light', delay: 5000 })
      }
    });
  }

  validarCadastroFrete(): boolean {

    if (this.frete.dataFrete == "" || this.frete.dataFrete == null) {
      this.toastService.show("Selecione a Data do Frete", { classname: 'bg-danger text-light', delay: 5000 });
      return false;
    }

    if (this.frete.valor == 0 || this.frete.valor == null) {
      this.toastService.show("Preencha o Valor do Frete", { classname: 'bg-danger text-light', delay: 5000 });
      return false;
    }

    if (this.frete.tipoFrete == "" || this.frete.tipoFrete == null) {
      this.toastService.show("Selecione o Tipo do Frete", { classname: 'bg-danger text-light', delay: 5000 });
      return false;
    }

    if (this.frete.estadosSelecionados == null || this.frete.estadosSelecionados.length == 0) {
      this.toastService.show("Selecione o Estado de visualização do frete", { classname: 'bg-danger text-light', delay: 5000 });
      return false;
    }

    return true;
  }

  SelecionarPorto(porto) {
    var idPorto = +porto.tabId;
    this.filtroFerrovia.idPorto = idPorto;
    this.filtroFerrovia.numeroPagina = 1;
    this.filtroRodovia.idPorto = idPorto;
    this.filtroRodovia.numeroPagina = 1;
    this.filtro.idPorto = idPorto;
    this.nomePorto = porto.tabTitle;
    this.obterFretes();
  }

  aoAbrirCalendario(container) {
    container.monthSelectHandler = (event: any): void => {
      container._store.dispatch(container._actions.select(event.date));
    };
    container.setViewMode('month');
  }

  obterFretes() {
    this.carregando = true;

    this.listaFerrovia = [];
    this.listaRodovia = [];
    this.listaTotais = [];

    this.filtroFerrovia.estadosSelecionados = this.filtro.estadosSelecionados;
    this.filtroRodovia.estadosSelecionados = this.filtro.estadosSelecionados;
    this.obterFretePorPraca(1, 1);
    this.obterFretePorPraca(1, 2);
  }

  obterFreteRodoviario() {
    this.obterFretePorPraca(1, 1);
  }

  obterFreteFerroviario() {
    this.obterFretePorPraca(1, 2);
  }

  SelecionarTipoFrete(event) {
    this.filtro.tipoFrete = event.nextId;
  }

  obterFretePorPraca(pagina, tipoFrete) {

    if (tipoFrete == 1) {
      this.filtroRodovia.pagina = pagina;
      this.filtroRodovia.tipoFrete = tipoFrete.toString();
      this.carregando = true;
      this.freteService.obterAnosPor(this.filtroRodovia).subscribe(response => {
        this.anosRodo = response.result;
      });

      this.freteService.obterFretePorPraca(this.filtroRodovia).subscribe(response => {
        if (response.codigo === 200) {
          this.listaRodovia = response.result.retorno;
          this.filtroRodovia.total = response.result.qtdTotal;
          this.listaTotais = response.result.totais;
          this.frete.idPorto = parseInt(this.filtroRodovia.idPorto);
          this.carregando = false;

        }
        else {
          this.toastService.show(response.mensagem, { classname: 'bg-danger text-light', delay: 5000 })
          this.carregando = false;
        }
          
      })
    }
    else {
      this.filtroFerrovia.pagina = pagina;
      this.filtroFerrovia.tipoFrete = tipoFrete.toString();
      this.carregando = true;
      this.freteService.obterAnosPor(this.filtroFerrovia).subscribe(response => {
        this.anosFerro = response.result;
      });
      this.freteService.obterFretePorPraca(this.filtroFerrovia).subscribe(response => {
        if (response.codigo === 200) {
          this.listaFerrovia = response.result.retorno;
          this.filtroFerrovia.total = response.result.qtdTotal;
          this.listaTotais = response.result.totais;
          this.carregando = false;
        }
        else {
          this.toastService.show(response.mensagem, { classname: 'bg-danger text-light', delay: 5000 })
          this.carregando = false;
        }
      })
    }
  }

  obterPracaPorId(id) {
    this.pracaService.obterPracaPorId(id).subscribe(response => {
      if (response.codigo === 200) {
        this.praca = response.result;
        this.filtro.estadosSelecionados = [];
        this.filtro.estadosSelecionados.push(response.result.estado);
        if (response.result.idPorto != "0" && response.result.idPorto != undefined) {
          this.tabset.select(response.result.idPorto);
          this.filtroFerrovia.idPorto = response.result.idPorto;
          this.filtroRodovia.idPorto = response.result.idPorto;
        } else if (response.result.idPorto == "0" && response.result.idPorto != undefined) {
          this.filtroFerrovia.idPorto = this.frete.idPorto;
          this.filtroRodovia.idPorto = this.frete.idPorto;
        }
        this.obterFretes();
      }
      else
        this.toastService.show(response.mensagem, { classname: 'bg-danger text-light', delay: 5000 })
    })
  }

  abaAtivaPorto(idPorto) {
    return this.filtroFerrovia.idPorto == idPorto;
  }

  obterPortos() {
    this.loading = true;
    this.portoService.obterTodosAtivos().subscribe(a => {
      this.loading = false;
      this.portosList = a.result;

      this.frete.idPorto = a.result[0].portoId;

    })
  }

  atualizarFrete(frete) {
    if (frete.valor != null && frete.valor >= 0) {
      let dto = {
        valor: frete.valor,
        freteId: frete.freteId
      }
      this.freteService.editarFrete(dto).subscribe(response => {
        if (response.codigo === 200) {
          this.toastService.show('Frete editada com sucesso!', { classname: 'bg-success text-light', delay: 5000 })

        }
      })
    }
    else
      this.toastService.show("Valor inválido", { classname: 'bg-danger text-light', delay: 5000 })
  }

  inativarFrete(frete) {
    this.freteService.inativarFrete(frete.freteId).subscribe(response => {
      if (response.codigo === 200) {
        this.toastService.show('Frete inativada com sucesso!', { classname: 'bg-success text-light', delay: 5000 })
        this.listaFerrovia = this.listaFerrovia.filter(ferro => {
          return ferro.freteId != frete.freteId;
        });
        this.listaRodovia = this.listaRodovia.filter(rodo => {
          return rodo.freteId != frete.freteId;
        });
      }
      else
        this.toastService.show(response.mensagem, { classname: 'bg-danger text-light', delay: 5000 })
    });
  }

  obterTodosEstados() {
    this.estadoService.obterTodos().subscribe(response => {
      this.listaEstado = response;
      this.obterPracaPorId(this.frete.pracaId);
    })
  }

  voltar() {
    window.history.go(-1);
  }

  exportexcel(): void {
    /* table id is passed over here */
    let tbRodovias = document.getElementById('tbRodovias');
    let tbFerrovias = document.getElementById('tbFerrovias');
    let tbTotais = document.getElementById('tbTotais');

    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(tbRodovias);
    const ww: XLSX.WorkSheet = XLSX.utils.table_to_sheet(tbFerrovias);
    const wz: XLSX.WorkSheet = XLSX.utils.table_to_sheet(tbTotais);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Rodovia');
    XLSX.utils.book_append_sheet(wb, ww, 'Ferrovia');
    XLSX.utils.book_append_sheet(wb, wz, 'Total');
    var nmPorto = this.portosList.filter((item: any) => item.portoId == this.filtroFerrovia.idPorto)[0]?.nome;

    if (nmPorto == null) {
      this.toastService.show("Nenhum porto encontrado para o relatório", { classname: 'bg-danger text-light', delay: 5000 })
      return
    }

    /* save to file */
    XLSX.writeFile(wb, nmPorto + '.xlsx');

  }

  
}
