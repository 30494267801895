<div id="app">
  <main *ngIf="menuVisible">


    <app-nav-menu *ngIf="estaLogado()" class="navbar" style="display: block; position: fixed; z-index: 999; top: 0; width: 100%"></app-nav-menu>

    <app-aside-menu *ngIf="estaLogado()" class="aside"></app-aside-menu>
    <div [ngClass]="{'wrapper-main': estaLogado()}">

      <nb-layout>
        <nb-layout-column style="margin:0 !important; padding:0 !important">

          <router-outlet>

          </router-outlet>
        </nb-layout-column>
      </nb-layout>

      <!--<app-loader *ngIf="true"></app-loader>-->
    </div>
  </main>
  <app-book-ofertas *ngIf="!menuVisible"></app-book-ofertas>
  <app-toasts></app-toasts>

</div>

<ng-template #updateDatas let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Bem vindo!</h5>
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>
      Mantenha seus dados atualizados, complete as informações do seu cadastro.
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="atualizarDepois()" data-dismiss="modal">{{'btn.completarDepois'|translate}}</button>
    <button type="button" class="btn btn-primary" (click)="irParaPerfil()">{{'btn.sim'|translate}}</button>
  </div>
</ng-template>

