<div class="login-wrapper">
  <div class="login-view d-none d-lg-block">
    <span class="wrapper-hero">
      <h1>Projeto BRIC</h1>
      <p>Auxiliando você a encontrar a precificação de Agrícola.</p>
    </span>
    <footer>
      ©LARP Desenvolvimento - Copyright {{copyright}}
    </footer>
  </div>
  <div class="login-form">
    <div class="card w-100">
      <div class="card-body">
        <form class="col-12" #ambienteForm="ngForm" novalidate>
          <span class="logo">
            <img src="../../assets/brand/BRIC_INVERSE.png" alt="Brand">
          </span>
          <h3 class="text-center text-primary font-weight-bold mb-3" style="color:#015A2C !important">Bem-vindo ao Projeto BRIC</h3>
          <h4 class="text-center text-dark mb-3">{{'ambiente.selecioneUmAmbiente'|translate}}</h4>
          <div class="form-group">
            <!--<label>Email</label>-->
            <select class="form-control" [(ngModel)]="dados.ambienteSelecionado" [class.is-invalid]="ambiente.invalid && ambiente.touched" name="ambiente" #ambiente required>
              <option *ngFor="let ambiente of listaAmbientes" value="{{ambiente.idEstado}}">{{ambiente.nomeEstado}}</option>
            </select>
            <!--<input type="text" class="form-control"
                   [class.is-invalid]="Email.invalid && Email.touched && Email.errors.email" [(ngModel)]="dados.email"
                   name="Email" #Email required email>-->
          </div>
          <div class="form-group">
            <button class="btn btn-success btn-lg w-100" (click)="$event.preventDefault();selecionarAmbiente()" [disabled]="ambienteForm.form.invalid">{{'btn.logar'|translate}}</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
