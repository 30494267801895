import { Component } from '@angular/core';
import { ServicoCBotService } from '../../services/servicoCBoatService';
import { CurvaCambioService } from '../../services/CurvaCambioService';
import { ToastService } from '../../services/toastService';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Router, NavigationEnd } from '@angular/router';
import { ProdutoEnum } from '../../enums/ProdutoEnum';


@Component({
  selector: 'app-tabelaCBoat',
  templateUrl: './tabelaCBoat.html',
  styleUrls: ['./tabelaCBoat.css']
})
export class TabelaCBoat {
  modalOptions: NgbModalOptions;
  title = 'ng-bootstrap-modal-demo';
  closeResult: string;
  cBoat: any = [];
  servicosDoUsuarioLogado: any = [];
  setIntervalId: any;
  ultimaAtualizacao: number;
  graoSelecionado: number;

  constructor(private router: Router, private curvaCambioService: CurvaCambioService,
    private servicoCBotService: ServicoCBotService,
    private toastService: ToastService, private modalService: NgbModal) {
    this.modalOptions = {
      size: 'lg'
    }
    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        clearInterval(this.setIntervalId);
      }
    });
  }

  open(content) {
    this.modalService.open(content, this.modalOptions).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  selecionarGrao(grao: ProdutoEnum) {
    this.cBoat = [];
    this.graoSelecionado = grao;
    this.listarCurvaPorGrao(grao);
  }

  listarServicos() {
    this.servicoCBotService.ObterDoUsuarioLogado().subscribe(response => {
      this.servicosDoUsuarioLogado = response.result;
    });
  }

  listarCurvaPorGrao(grao: ProdutoEnum) {
    this.curvaCambioService.obterCboat(grao).subscribe(response => {
      this.cbotCallback(response)
    });
  }

  ngOnInit() {
    this.graoSelecionado = ProdutoEnum.SOJA;
    this.listarServicos();

    this.listarCurvaPorGrao(this.graoSelecionado);

    this.setIntervalId = setInterval(() => {
      this.listarCurvaPorGrao(this.graoSelecionado);
    }, 6000);

  }

  private cbotCallback(response) {

    if (response.mensagem == "CME") {

      if (response.url == null) {
        this.toastService.show("Serviço indiponível no momento");
        return;
      }
      
      var timer = new Date().getTime();
      var url = response.url;
      var concat = url + timer;
      this.curvaCambioService.obterCboatCME(concat).subscribe(cbot => this.cBoat = cbot);
    }
    else
      this.cBoat = JSON.parse(response.result);

    this.toastService.show('Atualizado!', { classname: 'bg-success text-light', delay: 5000 });
    this.ultimaAtualizacao = Date.now();

  }
}
