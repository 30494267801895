

import { RouterModule } from '@angular/router';
import { NgxSpinnerModule } from "ngx-spinner";
import { LoaderInterceptor } from './loader.interceptor';
import { AuthGuard } from './auth/auth.guard';
import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { AsideMenuComponent } from './aside/aside-menu.component';
import { HomeComponent } from './home/home.component';
import { CurvaCambioService } from './services/CurvaCambioService';
import { FreteService } from './services/freteService';
import { CadastrarCurvaCambio } from './curvaCambio/cadastrarCurvaCambio/CadastrarCurvaCambio';
import { ListagemCurvaCambio } from './curvaCambio/listagemCurvaCambio/listagemCurvaCambio';
import { Fretes } from './frete/fretes/fretes';
import { Componentes } from './componente/componentes/componentes';
import { ListagemComponente } from "./componente/listagemComponente/listagemComponente"
import { ListagemPorto } from './componente/listagemPorto/listagemPorto';
import { ListagemPraca } from './frete/listagemPraca/listagemPraca';
import { ListagemPremio } from './premio/listagemPremio/listagemPremio';
import { ListagemIncoterm } from './incoterm/listagemIncoterm/listagemIncoterm';
import { ListagemUsuario } from './usuario/listagemUsuario/listagemUsuario';
import { ListagemEstado } from './estado/listagemEstado/listagemEstado';
import { Precificacao } from './precificacao/soja/cadastroPrecificacao/precificacao';
import { PrecificacaoMilho } from './precificacao/milho/precificacao-milho';

import { ListagemPrecificacao } from './precificacao/soja/listagemPrecificacao/listagemPrecificacao';
import { Configuracao } from './configuracao/configuracao';
import { TabelaCBoat } from './cBoat/TabelaCBoat/tabelaCBoat';
import { CotacaoService } from './services/cotacaoService';
import { CBoatService } from './services/cBoatService';
import { IncotermService } from './services/incotermService';
import { PortoService } from './services/portoService';

import { ComponenteItemService } from './services/componenteItemService';
import { ComponenteService } from './services/componenteService';
import { ServicoCBotService } from './services/servicoCBoatService';
import { TaxaDIService } from './services/taxaDIService';
import { PremioService } from './services/premioService';
import { ToastsContainer } from './components/toast/toastComponent';
import { LoaderService } from './services/loaderService';
import { PerfilService } from './services/perfilService';
import { TipoArmazemService } from './services/tipoArmazemService';
import { ConfiguracaoService } from './services/configuracaoService';
import { PrecificacaoService } from './services/precificacaoService';
import { LoaderComponent } from './components/loader/loader.component';
import { Login } from './login/login';
import { Ambientes } from './login/ambientes';
import { LoginService } from './services/loginService';
import { EstadoService } from './services/estadoService';
import { RecuperarSenha } from './recuperarSenha/recuperarSenha';
import { AlterarSenha } from './alterarSenha/alterarSenha';
import { NgxCurrencyDirective } from "ngx-currency";
import { PopoverModule } from 'ngx-bootstrap/popover';
import { CommonModule, NgTemplateOutlet, registerLocaleData } from '@angular/common';
import localePtBr from '@angular/common/locales/pt';
import { EditarUsuario } from './usuario/editar/editarUsuario';
import { TipoUsuarioService } from './services/tipoUsuarioService';
import { AuthorizationService } from './services/authorizationService';
import { SharedService } from './services/sharedService';
import { CriarVinculo } from "./vinculo/criar/criar";
import { SolicitarVinculoService } from './services/solicitarVinculoService';
import { CotacaoDolarComponent } from './cotacao-dolar/cotacao-dolar.component';
import { AvisoAguardarAprocaoComponent } from './aviso-aguardar-aprocao/aviso-aguardar-aprocao.component';
import { BookOfertas } from './precificacao/bookOfertas/bookOfertas';
import { SignUpComponent } from './signup/signUp.component';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule, NgbPaginationModule, NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDatepickerModule, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';
import { ProdutoService } from './services/produtoService';
import { OverlayContainer } from '@angular/cdk/overlay';
import { MyOverlayContainer } from './MyOverlayContainer';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { defineLocale, ptBrLocale } from 'ngx-bootstrap/chronos';
import { routes } from './app.module.routing';
import {
  NbAlertModule, NbAutocompleteModule, NbButtonModule, NbCardModule, NbDatepickerModule,
  NbDialogModule, NbIconModule, NbLayoutModule, NbListModule, NbSelectModule, NbSidebarModule,
  NbSpinnerModule, NbStepperModule, NbTabsetModule, NbThemeModule, NbWindowModule, NbWindowService
} from '@nebular/theme';

import { PracaService } from './services/pracaService';
import { RecuperarSenhaService } from './services/recuperarSenhaService';
import { ToastService } from './services/toastService';
import { NbMomentDateModule } from '@nebular/moment';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { CdkTableModule } from '@angular/cdk/table';
import { MatDialogModule } from '@angular/material/dialog';
import { LOCALE_ID, NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { GoogleMapsModule } from '@angular/google-maps';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { EditarboletaComponent } from './precificacao/boleta/editarboleta/editarboleta.component';
import { Globals } from './globals/globals';

defineLocale("pt-br", ptBrLocale);
registerLocaleData(localePtBr);


@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    Globals,
    CurvaCambioService,
    CotacaoService,
    CBoatService,
    PracaService,
    FreteService,
    PremioService,
    LoaderService,
    [AuthGuard],
    LoginService,
    TaxaDIService,
    IncotermService,
    PortoService,
    ComponenteItemService,
    ComponenteService,
    ServicoCBotService,
    PrecificacaoService,
    ConfiguracaoService,
    EstadoService,
    PerfilService,
    ProdutoService,
    NbWindowService,
    TipoUsuarioService,
    AuthorizationService,
    SharedService,
    TipoArmazemService,
    SolicitarVinculoService,
    RecuperarSenhaService,
    ToastService,
    { provide: OverlayContainer, useClass: MyOverlayContainer },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'pt' }
  ],
  declarations: [
    AppComponent,

    NavMenuComponent,
    AsideMenuComponent,
    HomeComponent,
    CadastrarCurvaCambio,
    ToastsContainer,
    LoaderComponent,
    ListagemCurvaCambio,
    Fretes,
    ListagemPraca,
    ListagemPremio,
    ListagemIncoterm,
    Login,
    TabelaCBoat,
    ListagemPorto,
    Componentes,
    ListagemComponente,
    ListagemUsuario,
    Precificacao,
    PrecificacaoMilho,
    ListagemPrecificacao,
    RecuperarSenha,
    AlterarSenha,
    Configuracao,
    BookOfertas,
    EditarUsuario,
    Ambientes,
    ListagemEstado,
    SignUpComponent,
    CriarVinculo,
    CotacaoDolarComponent,
    AvisoAguardarAprocaoComponent,
    EditarboletaComponent
  ],
  imports: [
    FormsModule,
    BrowserModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    CommonModule,
    NgbPaginationModule,
    NgxSpinnerModule,
    NbTabsetModule,
    MatDialogModule,
    BrowserAnimationsModule,
    BsDatepickerModule.forRoot(),
    PopoverModule.forRoot(),
    NbSidebarModule.forRoot(),
    NgbModule,
    NbMomentDateModule,
    NbThemeModule.forRoot(),
    NbSelectModule,
    NbListModule,
    NbTabsetModule,
    NbLayoutModule,
    NbWindowModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbDialogModule.forRoot(),
    NbButtonModule,
    NbCardModule,
    NbSpinnerModule,
    RouterModule.forRoot(routes),
    NgMultiSelectDropDownModule.forRoot(),
    MatSortModule,
    MatTableModule,
    MatMenuModule,
    MatIconModule,
    MatSelectModule,
    CdkTableModule,
    NgxMaskDirective,
    NgxMaskPipe,
    NgxCurrencyDirective,
    GoogleMapsModule,
    ReactiveFormsModule,
    NgbToastModule,
    NgTemplateOutlet,
    NbStepperModule,
    NbEvaIconsModule,
    NbIconModule,
    NbAlertModule,
    NbAutocompleteModule,
    AutocompleteLibModule

  ],
  bootstrap: [AppComponent],
  exports: [
    HttpClientModule,
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ]
})

export class AppModule {
  constructor(private bsLocaleService: BsLocaleService) {
    this.bsLocaleService.use('pt-br');
  }
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
