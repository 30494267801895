import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FreteService {
  constructor(private http: HttpClient) { }

  baseUrl = "api/Frete/"

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8'
    })
  }


  public cadastrarFrete(dto) {
    return this.http.post<any>(this.baseUrl + "CadastrarFrete", JSON.stringify(dto), this.httpOptions);
  }

  public obterListaFrete(dto) {
    return this.http.post<any>(this.baseUrl + "ObterListaFrete", JSON.stringify(dto), this.httpOptions);
  }

  public obterFretePorId(id) {
    return this.http.get<any>(this.baseUrl + "ObterFretePorId?freteId=" + id, this.httpOptions);
  }

  public editarFrete(dto) {
    return this.http.post<any>(this.baseUrl + "EditarFrete", JSON.stringify(dto), this.httpOptions);
  }

  public inativarFrete(id) {
    return this.http.get<any>(this.baseUrl + "InativarFrete?freteId=" + id, this.httpOptions);
  }

  public obterFretePorPraca(dto) {
    return this.http.post<any>(this.baseUrl + "ObterFretePorPraca", JSON.stringify(dto), this.httpOptions);
  }

  public obterAnosPor(dto) {
    return this.http.post<any>(this.baseUrl + "ObterAnosPor", JSON.stringify(dto), this.httpOptions);
  }
}
