<main>
  <nb-card [nbSpinner]="loading">
    <nb-card-body>
      <h3>{{'Menus.Estados' |translate}}</h3>
    </nb-card-body>
    <nb-card-body>
      <div class="d-md-flex d-sm-block justify-content-between align-items-center">
        <div class="d-flex align-items-end">
          <div class="form-group">
            <label>{{'estadoo.nome' |translate}}</label>
            <input type="text" class="form-control" [(ngModel)]="filtro.nomeEstado">
          </div>
          <button class="btn btn-success mb-3 ml-3" (click)="filtrar()">{{'btn.filtrar' |translate}} <i class="mdi mdi-filter"></i></button>
          <button class="btn btn-secondary mb-3 ml-3" (click)="limpar()">{{'btn.limpar' |translate}} <i class="mdi mdi-eraser"></i></button>
        </div>
        <!--<button class="btn btn-success" (click)="open(novaPraca)">Nova Praca</button>-->
      </div>
      <div class="table-gp">
        <table class="table">
          <thead class="thead-dark">
            <tr>
              <th>
                <label>{{'estadoo.nome' |translate}}</label>
              </th>
              <th>
                <label>{{'estadoo.sigla' |translate}}</label>
              </th>
              <th>
                <label>{{'estadoo.status' |translate}}</label>
              </th>
              <th>
                <label>{{'estadoo.acao' |translate}}</label>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let estado of listaEstado">
              <td>{{estado.nomeEstado}}</td>
              <td>{{estado.uf}}</td>
              <td>
                <ng-container *ngIf="estado.ativo == true">
                  {{'estadoo.ativo' |translate}}   <!-- Conteúdo 0 aqui -->
                </ng-container>
                <ng-container *ngIf="estado.ativo == false">
                  {{'estadoo.desativado' |translate}}   <!-- Conteúdo 0 aqui -->
                </ng-container>
              </td>

              <!-- <td ng-if="{{estado.ativo == false}}">Inativo</td>-->
              <td>
                <div class="d-flex">
                  <button (click)="ativarInativar(estado)" class="btn btn-outline-success">
                    <i class="mdi mdi-pencil"></i>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

    </nb-card-body>
  </nb-card>
</main>

