  <aside class="gp-aside">
 
    <ul class="gp-navbar-nav">
      <li class="gp-nav-item" [routerLinkActive]="['link-active']">
        <a class="gp-nav-link text-dark" [routerLink]="['/']" (click)="irParaMeuPerfil()">
          <i class="mdi mdi-account-box mdi-24px"></i>
          <span>{{'Menus.MeuPerfil'|translate}} <span class="badge badge-danger" *ngIf="numSolicitacaoVinculos !=null && numSolicitacaoVinculos > 0">{{numSolicitacaoVinculos}}</span></span>
        </a>
      </li>
      <li class="gp-nav-item" [routerLinkActive]="['link-active']" *ngIf="menuCurvaCambio">
        <a class="gp-nav-link text-dark" [routerLink]="['/']" (click)="dropmenu()">
          <i class="mdi mdi-chart-bell-curve mdi-24px"></i>
          <span>{{'Menus.CurvaCambio'|translate}}</span>
        </a>
      </li>
      <li class="gp-nav-item d-none d-lg-flex" [routerLinkActive]="['link-active']" *ngIf="menuFrete">
        <a class="gp-nav-link text-dark" [routerLink]="['/listagemPraca']" (click)="dropmenu()">
          <i class="mdi mdi-truck mdi-24px"></i>
          <span>{{'Menus.Frete'|translate}}</span>
        </a>
      </li>
      <li class="gp-nav-item d-none d-lg-flex" [routerLinkActive]="['link-active']" *ngIf="menuPremio">
        <a class="gp-nav-link text-dark" [routerLink]="['/listagemPremio']" (click)="dropmenu()">
          <i class="mdi mdi-medal mdi-24px"></i>
          <span>{{'Menus.Premio'|translate}}</span>
        </a>
      </li>
      <li class="gp-nav-item" [routerLinkActive]="['link-active']" *ngIf="menuCBOT">
        <a class="gp-nav-link text-dark" [routerLink]="['/cBot']" (click)="dropmenu()">
          <i class="mdi mdi-robot mdi-24px"></i>
          <span>{{'Menus.Cbot'|translate}}</span>
        </a>
      </li>
      <li class="gp-nav-item d-none d-lg-flex" [routerLinkActive]="['link-active']" *ngIf="menuInconterm">
        <a class="gp-nav-link text-dark" [routerLink]="['/incoterm']" (click)="dropmenu()">
          <i class="mdi mdi-chart-gantt mdi-24px"></i>
          <span>{{'Menus.Incoterm'|translate}}</span>
        </a>
      </li>
      <li class="gp-nav-item d-none d-lg-flex" [routerLinkActive]="['link-active']" *ngIf="menuPortos">
        <a class="gp-nav-link text-dark" [routerLink]="['/listagemPorto']" (click)="dropmenu()">
          <i class="mdi mdi-bank-transfer mdi-24px"></i>
          <span>{{'Menus.Portos'|translate}}</span>
        </a>
      </li>
      <li class="gp-nav-item d-none d-lg-flex" [routerLinkActive]="['link-active']" *ngIf="menuComponente">
        <a class="gp-nav-link text-dark" [routerLink]="['/componentes']" (click)="dropmenu()">
          <i class="mdi mdi-rhombus-split mdi-24px"></i>
          <span>{{'Menus.Componente'|translate}}</span>
        </a>
      </li>

      <li class="gp-nav-item" *ngIf="menuPrecificacao" [routerLinkActive]="['link-active']">
          <a class="gp-nav-link text-dark"  [routerLink]="['/precificacao']" (click)="dropmenu()">
            <i class="mdi mdi-cash mdi-24px"></i>
            <span>{{'Menus.Precificacao'|translate}}</span>
          </a>
      </li>
      <li class="gp-nav-item" [routerLinkActive]="['link-active']" *ngIf="menuBookOfertas">
        <a class="gp-nav-link text-dark" [routerLink]="['/bookOfertas']" (click)="dropmenu()">
          <i class="mdi mdi-book-open-page-variant mdi-24px"></i>
          <span>{{'Menus.BookOfertas'|translate}}</span>
        </a>
      </li>
      <li class="gp-nav-item" [routerLinkActive]="['link-active']" *ngIf="menuConcluidos">
        <a class="gp-nav-link text-dark" [routerLink]="['/NegocioConcluido']" (click)="dropmenu()">
          <i class="mdi mdi-check-all mdi-24px"></i>
          <span>{{'Menus.Concluidos'|translate}}</span>
        </a>
      </li>
      <li class="gp-nav-item d-none d-lg-flex" [routerLinkActive]="['link-active']" *ngIf="menuUsuarios">
        <a class="gp-nav-link text-dark" [routerLink]="['/usuario/listagem']" (click)="dropmenu()">
          <i class="mdi mdi-account mdi-24px"></i>
          <span>{{'Menus.Usuários'|translate}} <span class="badge badge-danger" title="Cadastro pendentes" *ngIf="qtdUsuarioSolicitPendente !=null && qtdUsuarioSolicitPendente > 0">{{qtdUsuarioSolicitPendente}}</span></span>
        </a>
      </li>
      <li class="gp-nav-item d-none d-lg-flex" [routerLinkActive]="['link-active']" *ngIf="menuEstado">
        <a class="gp-nav-link text-dark" [routerLink]="['/listagemEstado']" (click)="dropmenu()">
          <i class="mdi mdi-map mdi-24px"></i>
          <span>{{'Menus.Estados'|translate}}</span>
        </a>
      </li>
      <li class="gp-nav-item d-none d-lg-flex" [routerLinkActive]="['link-active']" *ngIf="menuConfig">
        <a class="gp-nav-link text-dark" [routerLink]="['/config']" (click)="dropmenu()">
          <i class="mdi mdi-cog mdi-24px"></i>
          <span>{{'Menus.Config'|translate}}</span>
        </a>
      </li>

      <li class="gp-nav-item" [routerLinkActive]="['link-active']">
        <a class="gp-nav-link text-danger" href="#" (click)="$event.preventDefault();deslogar()">
          <i class="mdi mdi-power mdi-24px"></i>
          <span>{{'Menus.Sair'|translate}}</span>
        </a>
      </li>
      <li class="gp-nav-item gp-more">
        <a class="gp-nav-link " (click)="expandir()">
          <i class="mdi mdi-dots-vertical mdi-24px"></i>
        </a>
      </li>
    </ul>
    
  </aside>
