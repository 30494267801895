import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TipoArmazemService {
  constructor(private http: HttpClient) { }

  baseUrl = "api/TipoArmazem/"

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8'
    })
  }

  public obterTodos() {
    return this.http.get<any>(this.baseUrl + "ObterTodos", this.httpOptions);
  }
}
