import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class SolicitarVinculoService {

  constructor(private http: HttpClient) { }

  baseUrl = "api/Vinculo/"

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8'
    })
  }

  public criarSolicitacao(dto) {
    return this.http.post<any>(this.baseUrl + "CriarSolicitacao", dto, this.httpOptions);
  }

  public responderVinculo(dto) {
    return this.http.post<any>(this.baseUrl + "ResponderVinculo", JSON.stringify(dto), this.httpOptions);
  }

  public obterQtdSolicitacoes(idUsuario) {
    return this.http.get<any>(this.baseUrl + "ObterQtdSolicitacoes/" + idUsuario, this.httpOptions);
  }


}
