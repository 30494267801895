import { Component, OnInit } from "@angular/core";
import { ToastService } from '../services/toastService';
import { RecuperarSenhaService } from '../services/recuperarSenhaService';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-alteraSenha',
  templateUrl: './alterarSenha.html',
})

export class AlterarSenha implements OnInit {

  public dados: RecuperarSenha;
  alterandoSenha = false;
  constructor(private toastService: ToastService, private recuperarSenhaService: RecuperarSenhaService, private route: ActivatedRoute, private router: Router) {
    this.dados = new RecuperarSenha();
  }

  ngOnInit() {
    this.alterandoSenha = false;
    this.route.params.subscribe(args => {
      if (args.token != null) {
        this.dados.token = args.token;
      }
    });
  }

  public alterarSenha() {
    this.alterandoSenha = true;
    this.recuperarSenhaService.alterarSenha(this.dados).subscribe(response => {
      this.alterandoSenha = false;
      if (response.retorno) {
        this.toastService.show(response.mensagem, { classname: 'bg-success text-light', delay: 5000 })
        this.router.navigate(['/sign-in']);
      }
      else
        this.toastService.show(response.mensagem, { classname: 'bg-danger text-light', delay: 5000 })
    })
  }
}

class RecuperarSenha {

  senha: string;
  confirmacaoSenha: string;
  token: string;

}
