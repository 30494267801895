import { Component, ViewChild } from '@angular/core';
import { PremioService } from '../../services/premioService';
import { ToastService } from '../../services/toastService';
import { ProdutoService } from '../../services/produtoService';
import { ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { PortoService } from '../../services/portoService';
import { NbDialogService } from '@nebular/theme';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';

@Component({
  selector: 'app-listagemPremio',
  templateUrl: './listagemPremio.html',
  styleUrls: ['./listagemPremio.css']
})
export class ListagemPremio {

  modalOptions: NgbModalOptions;
  title = 'ng-bootstrap-modal-demo';
  closeResult: string;
  dataPremio: any;
  resposta: object = {};
  listaPremio: any[] = [];
  listaGraos: any[] = [];
  premioSelecionado: any = {};
  novoPremio: any = {
    grao: 1
  };
  filtro: any = {
    tamanhoPagina: 10,
    numeroPagina: 1,
    grao: 1,
    idPorto: 0
  };
  idPorto: number;
  nomePorto: string;
  portosList: any[];
  dialogRef: any;
  locale = 'pt-br';
  carregandoPortos = true;
  constructor(private premioService: PremioService,
    private toastService: ToastService, private modalService: NbDialogService, private portoService: PortoService, private produtoService: ProdutoService, private _localeService: BsLocaleService) {
    this.modalOptions = {
      size: 'lg'
    }
    this._localeService.use(this.locale);
  }

  ngOnInit() {
    this.obterPortos();
    this.obterProdutos();
  }

  abaAtiva(idPorto) {
    return this.idPorto == idPorto; 
  }

  carregarPremiosPorPorto(porto) {
    var idPorto = porto.tabId;

    this.idPorto = +idPorto;
    this.filtro.idPorto = +idPorto;

    this.nomePorto = porto.nomePorto;
    this.obterListaPremio(1);
  }

  aoAbrirCalendario(container) {
    container.monthSelectHandler = (event: any): void => {
      container._store.dispatch(container._actions.select(event.date));
    };
    container.setViewMode('month');
  }

  obterProdutos() {
    this.produtoService.obterListaProduto().subscribe(response => {
      this.listaGraos = response;
    });
  }
  cadastrarPremio(grao, dataPremio, valor) {
    var valido = true;
    if (grao == '' || grao == undefined) {
      this.toastService.show("Grão obrigatório", { classname: 'bg-danger text-light', delay: 5000 })
      valido = false;
    }
    if (dataPremio == '' || dataPremio == undefined) {
      this.toastService.show("data obrigatória", { classname: 'bg-danger text-light', delay: 5000 })
      valido = false;
    }
    if (valor == '' || valor == undefined) {
      this.toastService.show("Valor obrigatório", { classname: 'bg-danger text-light', delay: 5000 })
      valido = false;
    }
    if (valido) {
      var dto = {
        grao: parseInt(grao),
        valor: valor,
        dataPremio: dataPremio,
        idPorto: this.idPorto
      }
      this.premioService.cadastrarPremio(dto).subscribe(response => {
        if (response.codigo === 200) {
          this.dataPremio = null;
          this.toastService.show('Premio cadastrado com sucesso!', { classname: 'bg-success text-light', delay: 5000 })
          this.listaPremio.push(response.result);
        }
        else {
          this.toastService.show(response.mensagem, { classname: 'bg-danger text-light', delay: 5000 })
        }

      })
    }

  }

  handleDateChange(event) { debugger; }

  obterListaPremio(pagina) {

    this.filtro.pagina = pagina;

    var filtro = this.filtro;
    if (filtro.idPorto == 0 || filtro.idPorto == undefined) {

      this.filtro.idPorto = +this.idPorto;

    }


    this.premioService.obterListaFrete(this.filtro).subscribe(response => {
      if (response.codigo === 200) {
        this.listaPremio = response.result.retorno;
        this.filtro.total = response.result.qtdTotal
      }
      else
        this.toastService.show(response.mensagem, { classname: 'bg-danger text-light', delay: 5000 })
    })
  }

  selecionarPremio(id) {
    this.premioSelecionado = this.listaPremio.find(premio => {
      return premio.premioId === id;
    })
  }

  editarPremio(premio) {
    this.premioService.editarPremio(premio).subscribe(response => {
      if (response.codigo === 200)
        this.toastService.show('Premio editado com sucesso!', { classname: 'bg-success text-light', delay: 5000 })
      else {
        this.toastService.show(response.mensagem, { classname: 'bg-danger text-light', delay: 5000 })
      }
    })
  }

  inativarPremio() {
    this.premioService.inativarPremio(this.premioSelecionado).subscribe(response => {
      if (response.codigo === 200) {
        this.close();
        this.toastService.show('Premio inativado com sucesso!', { classname: 'bg-success text-light', delay: 5000 })
        this.listaPremio = this.listaPremio.filter(premio => {
          if (premio.premioId !== this.premioSelecionado.premioId)
            return premio;
        })
      }
      else {
        this.toastService.show(response.mensagem, { classname: 'bg-danger text-light', delay: 5000 })
      }
    })
  }

  focar(input) {
    input.target.select();
  }

  obterPortos() {
    this.portoService.obterTodosAtivos().subscribe(a => {
      this.portosList = a.result;
      this.idPorto = this.portosList[0].portoId;
      this.carregandoPortos = false;
      this.obterListaPremio(1);
    })
  }

  open(content) {
    this.dialogRef = this.modalService.open(content, this.modalOptions);
  }

  close() {
    this.dialogRef.close();
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


}
