import { Component } from '@angular/core';
import { ToastService } from '../services/toastService';
import { LoginService } from '../services/loginService';
import { Router } from '@angular/router';

@Component({
  selector: 'app-ambientes',
  templateUrl: './ambientes.html',
})
export class Ambientes {
 
  constructor(private toastService: ToastService, private loginService: LoginService, private router: Router) {}

  dados: any = {};
  copyright: string;
  listaAmbientes: any;

  selecionarAmbiente() {

    if (this.dados.ambienteSelecionado != null) {

      localStorage.setItem('ambienteSelecionado', this.dados.ambienteSelecionado);

      let idUsuario = +this.loginService.currentUserValue.usuarioId;
      let idEstado = +this.dados.ambienteSelecionado;

      if (idUsuario == 0 || idEstado == 0) {
        this.toastService.show("Erro ao selecionar ambiente.", { classname: 'bg-danger text-light', delay: 5000 })
        return;
      }

      this.loginService.atualizarCookieEstadoSelecionado(idUsuario, idEstado).subscribe(response => {
        if (response == false)
          this.toastService.show("Houve um erro ao selecionar o ambiente.", { classname: 'bg-danger text-light', delay: 5000 })
      });

      this.router.navigate(['']);

    }
  }

  carregarAmbientesUsuario() {
    this.listaAmbientes = this.loginService.currentUserValue.estadosDeAcesso;
  }

  ngOnInit() {
    this.carregarAmbientesUsuario();
    this.copyright = new Date().getFullYear().toString();
  }
}
