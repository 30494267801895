import { Component, ViewChild } from '@angular/core';
import { ComponenteItemService } from '../../services/componenteItemService';
import { ToastService } from '../../services/toastService';
import { ProdutoService } from '../../services/produtoService';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { AuthorizationService } from '../../services/authorizationService';
import { ActivatedRoute } from '@angular/router';
import { ComponenteService } from '../../services/componenteService';
import { Observable } from 'rxjs/internal/Observable';

@Component({
  selector: 'app-componentes',
  templateUrl: './componentes.html',
  styleUrls: ['./component.styled.css']
})
export class Componentes {

  editando: boolean = false;
  componenteId: number;
  novoComponente: any = {};
  filtro: any = {
    tamanhoPagina: 10,
    numeroPagina: 1,
    grao: 1
  };
  edicaoComponente: any = {};
  listaComponenteAgrupados: any[] = [];
  listaMoeda: any[] = [];
  listaUnidade: any[] = [];
  listaNomes: any[] = [
    "Elevação",
    "Inframar",
    "TUP",
    "Fumigação",
    "Sup.Embarque",
    "Classificação",
    "Despacho",
    "Armazenagem",
    "Custo Filial",
    "Financeiro",
    "Quebra",
    "Ret.FOB Origem",
    "Ret.Transbordo",
    "Ret.Pool"];
  listaGraos: any[] = [];
  componente: any = {};
  listaCalculos: any[] = [
    {
      id: 0,
      calculo: '',
      desc: ''
    },
    {
      id: 1,
      calculo: 'FOB Origem',
      desc: 'Cbot +  Premio * Grão - frete Rodoviario * %retenção'
    },
    {
      id: 2,
      calculo: 'Transbordo',
      desc: 'Cbot +  Premio * Grão - frete Ferroviario * %retenção'
    },
    {
      id: 3,
      calculo: 'Pool',
      desc: 'Cbot +  Premio * Grão * %retenção'
    }]
  ehAdministrador = false;
  anos: any[];
  nomePorto: string;
  @ViewChild('tabset', { static: true }) tabset;

  constructor(private componenteItemService: ComponenteItemService,
    private authorizationService: AuthorizationService,
    private toastService: ToastService,
    private produtoService: ProdutoService,
    private route: ActivatedRoute,
    private componenteService: ComponenteService) {
  }
  ngOnInit() {

    this.obterMoedaUnidade();
    this.obterListaProdutos();
    this.listarAnos();
    this.ehAdministrador = this.authorizationService.ehAdministrador();
    this.route.paramMap.subscribe(params => {
      if (params.has("id")) {
        this.componenteId = +params.get("id");
        this.obterDetalherComponente();
      }
    });

  }

  obterComponenteItemPorGrao() {
    this.componenteItemService.obterComponenteItemPor(this.componenteId, this.filtro.grao).subscribe(response => {
      if (response.codigo == 200) {
        this.edicaoComponente.componentesItens = response.result;
      }
    });
  }


  listarAnos() {
    var currentYear = new Date();
    var anoAtual = currentYear.getFullYear();
    currentYear.setFullYear(anoAtual + 5);
    var anoFuturo = currentYear.getFullYear();
    this.anos = []
    for (var i = anoAtual; i <= anoFuturo; i++) {
      this.anos.push(i);
    }
  }
  mostrarDescComponenteSelecionado(calculo) {
    if (calculo == null || calculo == 0) return '';
    return this.listaCalculos.filter(f => f.id == calculo)[0].desc;
  }

  cadastrarComponente(ano, nome, custo, moeda, unidade, calculo) {
    this.componente.ano = ano;
    this.componente.nome = nome;
    this.componente.custo = custo;
    this.componente.moeda = moeda.toString();
    this.componente.unidade = unidade.toString();
    this.componente.calculo = parseInt(calculo);
    this.componente.idGrao = this.filtro.grao;
    this.componente.componenteId = this.componenteId;

    this.componenteItemService.cadastrarComponente(this.componente).subscribe(response => {
      if (response.codigo === 200) {
        this.toastService.show('Componente cadastrado com sucesso!', { classname: 'bg-success text-light', delay: 5000 })

        this.obterDetalherComponente();

      }
      else {
        this.toastService.show(response.mensagem, { classname: 'bg-danger text-light', delay: 5000 })
      }

    });
  }

  obterDetalherComponente() {
    this.componenteService.obterComponentePorId(this.componenteId, this.filtro.grao).subscribe(response => {
      if (response.codigo == 200) {
        this.edicaoComponente = response.result;
      }
    });
  }

  obterListaProdutos() {
    this.produtoService.obterListaProduto().subscribe(response => {
      if (response) {
        this.listaGraos = response;
      }
    })
  }



  obterMoedaUnidade() {
    this.componenteItemService.obterMoedaUnidade().subscribe(response => {
      if (response.codigo === 200) {
        this.listaMoeda = response.result.moedas;
        this.listaUnidade = response.result.unidades;
      }
    })
  }

  atualizarComponente(componente) {
    if (componente.custo != undefined) {
      let dto = {
        ano: componente.ano,
        nome: componente.nome,
        custo: componente.custo,
        moeda: componente.moeda,
        unidade: componente.unidade,
        componenteItemId: componente.componenteItemId,
        calculo: parseInt(componente.calculo)
      }
      this.componenteItemService.editarComponente(dto).subscribe(response => {
        if (response.codigo === 200) {
          this.toastService.show('Componente editado com sucesso!', { classname: 'bg-success text-light', delay: 5000 })

        }
      })
    }
    //else
    //    this.toastService.show("custo inválido", { classname: 'bg-danger text-light', delay: 5000 })
  }

  inativarComponente(componente) {
    this.componenteItemService.inativarComponente(componente).subscribe(response => {
      if (response.codigo === 200) {
        this.toastService.show('Componente inativado com sucesso!', { classname: 'bg-success text-light', delay: 5000 })
        this.obterDetalherComponente();
      }
      else
        this.toastService.show(response.mensagem, { classname: 'bg-danger text-light', delay: 5000 })
    });
  }



  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term === '' ? []
        : this.listaNomes.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    )

  //obterNomesComponente() {
  //  this.componenteItemService.obterNomesComponente().subscribe(response => {
  //    if (response.codigo === 200)
  //      this.listaNomes = response.result;
  //    else
  //      this.toastService.show(response.mensagem, { classname: 'bg-danger text-light', delay: 5000 })
  //  })
  //}

}
