<main class="col-12">
  <nb-card [nbSpinner]="loading">
    <nb-card-header>
      <h3 *ngIf="ehEdicao">{{'titulo.edicaoUsuario'|translate}}</h3>
      <h3 *ngIf="!ehEdicao">{{'titulo.criarUsuario'|translate}}</h3>
    </nb-card-header>
    <nb-card-body>
      <div>
        <form #usuarioForm="ngForm" novalidate>

          <ul class="nav nav-tabs">
            <li class="nav-item">
              <span class="nav-link {{formulario == 1 ? 'active' : ''}}" *ngIf="ehAdministrador" (click)="atualizarForm(1)">Dados principais</span>
            </li>
            <li class="nav-item" *ngIf="usuarioEdicao.empresa != null">
              <span class="nav-link {{formulario == 2 ? 'active' : ''}}" (click)="atualizarForm(2)">Informações adicionais</span>
            </li>
            <li class="nav-item" *ngIf="usuarioEdicao.empresa != null">
              <span class="nav-link {{formulario == 3 ? 'active' : ''}}" (click)="atualizarForm(3)">Telefones</span>
            </li>
            <li class="nav-item" *ngIf="usuarioEdicao.empresa != null">
              <span class="nav-link {{formulario == 4 ? 'active' : ''}}" (click)="atualizarForm(4)">Armazens</span>
            </li>
            <li class="nav-item" *ngIf="usuarioEdicao.empresa != null">
              <span class="nav-link {{formulario == 5 ? 'active' : ''}}" (click)="atualizarForm(5)">Propriedades/Fazendas</span>
            </li>
            <li class="nav-item" *ngIf="usuarioEdicao.empresa != null">
              <span class="nav-link {{formulario == 6 ? 'active' : ''}}" (click)="atualizarForm(6)">Períodos de chuvas</span>
            </li>
            <li class="nav-item" *ngIf="usuarioEdicao.empresa != null">
              <span class="nav-link {{formulario == 6 ? 'active' : ''}}" (click)="atualizarForm(7)">Vínculos <span class="badge badge-danger" *ngIf="numSolicitacaoVinculos !=null && numSolicitacaoVinculos > 0">{{numSolicitacaoVinculos}}</span></span>
            </li>
          </ul>
          <div class="row">
            <div class="col-12">
              <div *ngIf="formulario == 1">
                <div class="form-group col-6 pl-0">
                  <label>   {{'usuarios.nome'|translate}}</label>
                  <input class="form-control" type="text" [(ngModel)]="usuarioEdicao.nome"
                         [class.is-invalid]="nome.invalid && nome.touched" name="nome" #nome required />
                </div>
                <div class="form-group col-6 pl-0">
                  <label>   {{'usuarios.email'|translate}}</label>
                  <input type="email" class="form-control" [(ngModel)]="usuarioEdicao.email"
                         [class.is-invalid]="email.invalid && email.touched" name="email" #email required
                         pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$">
                </div>
                <div class="form-group col-6 pl-0" [hidden]="ehEdicao">
                  <label>   {{'usuarios.senha'|translate}}</label>
                  <input type="password" class="form-control" [(ngModel)]="usuarioEdicao.senha"
                         [class.is-invalid]="senha.invalid && senha.touched" name="senha" #senha minlength="6" required>
                  <small class="text-danger" [class.d-none]="senha.valid || senha.untouched">
                    {{'usuarios.senhaAviso'|translate}}
                  </small>
                </div>
                <div class="form-group col-1 pl-0">
                  <label>{{'usuarios.ativo'|translate}}</label>
                  <input type="checkbox" class="form-control" [(ngModel)]="usuarioEdicao.ativo" name="ativo" />
                </div>
                <div class="form-group col-6 pl-0">
                  <label>{{'usuarios.servicosCBOT'|translate}}</label>
                  <ng-multiselect-dropdown [placeholder]="'Selecione o Serviço CBot'"
                                           [data]="dropdownList"
                                           [(ngModel)]="usuarioEdicao.listaServicosCBot"
                                           [ngModelOptions]="{standalone: true}"
                                           [settings]="dropdownSettings"
                                           (onSelect)="onItemSelect($event)"
                                           (onSelectAll)="onSelectAll($event)">
                  </ng-multiselect-dropdown>
                </div>
                <div class="form-group col-6 pl-0">
                  <label>{{'usuarios.perfil'|translate}}</label>
                  <ng-multiselect-dropdown [placeholder]="'Selecione o tipo de usuário'"
                                           [data]="dropdownTipoUsuariolList"
                                           [(ngModel)]="listaPerfisSelecionados"
                                           [ngModelOptions]="{standalone: true}"
                                           [settings]="dropdownTipoUsuarioSettings"
                                           (onSelect)="onItemSelect($event)"
                                           (onSelectAll)="onSelectAll($event)">
                  </ng-multiselect-dropdown>
                </div>

                <div class="form-group col-6 pl-0">
                  <label>{{'Menus.Estados'|translate}}</label>
                  <ng-multiselect-dropdown [placeholder]="'Selecione o Estados'"
                                           [data]="listaEstado"
                                           [(ngModel)]="usuarioEdicao.estadosDeAcesso"
                                           [ngModelOptions]="{standalone: true}"
                                           [settings]="dropdownEstadosSettings"
                                           (onSelect)="onItemSelect($event)"
                                           (onSelectAll)="onSelectAll($event)">
                  </ng-multiselect-dropdown>
                </div>



                <div class="form-group col-6 pl-0">
                  <label>{{'usuarios.linguagem'|translate}}</label>
                  <select class="form-control" [(ngModel)]="usuarioEdicao.linguagem" name="linguagem">
                    <option [ngValue]="1">Português</option>
                    <option [ngValue]="2">Inglês</option>
                    <option [ngValue]="3">Chinês</option>
                  </select>
                </div>
              </div>
              <!--Informacoes Adicionais-->
              <div *ngIf="formulario == 2 && usuarioEdicao.empresa != null">
                <div class="m-2">
                  <div class="row">
                    <div class="form-group col-6 pl-0">
                      <label>Grupo Empresarial</label>
                      <input type="text" class="form-control"
                             [class.is-invalid]="grupoEmpresarial.invalid && grupoEmpresarial.touched && grupoEmpresarial.errors.grupoEmpresarial"
                             [(ngModel)]="usuarioEdicao.empresa.grupoEmpresarial"
                             name="grupoEmpresarial" #grupoEmpresarial required>
                    </div>
                    <div class="form-group col-6 pl-0">
                      <label>CNPJ</label>
                      <input type="text" class="form-control" [textMask]="{mask: cnpjMask}"
                             [class.is-invalid]="cnpj.invalid && cnpj.touched && cnpj.errors.cnpj"
                             [(ngModel)]="usuarioEdicao.empresa.cnpj"
                             name="cnpj" #cnpj required>
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group col-6 pl-0">
                      <label>Razão Social</label>
                      <input type="text" class="form-control"
                             [class.is-invalid]="razaoSocial.invalid && razaoSocial.touched && razaoSocial.errors.razaoSocial"
                             [(ngModel)]="usuarioEdicao.empresa.razaoSocial"
                             name="razaoSocial" #razaoSocial required>
                    </div>
                    <div class="form-group col-6 pl-0">
                      <label>Nome Fantasia</label>
                      <input type="text" class="form-control"
                             [class.is-invalid]="nomeFantasia.invalid && nomeFantasia.touched && nomeFantasia.errors.nomeFantasia"
                             [(ngModel)]="usuarioEdicao.empresa.nomeFantasia"
                             name="nomeFantasia" #nomeFantasia required>
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group col-6 pl-0">
                      <label>Inscrição Estadual</label>
                      <input type="text" class="form-control"
                             [class.is-invalid]="inscricaoEstadual.invalid && inscricaoEstadual.touched && inscricaoEstadual.errors.inscricaoEstadual"
                             [(ngModel)]="usuarioEdicao.empresa.inscricaoEstadual"
                             name="inscricaoEstadual" #inscricaoEstadual required>
                    </div>
                    <div class="form-group col-6 pl-0">
                      <label>Endereço</label>
                      <input type="text" class="form-control" placeholder="Exemplo: Rua, número, cidade - estado"
                             [class.is-invalid]="endereco.invalid && endereco.touched && endereco.errors.endereco"
                             [(ngModel)]="usuarioEdicao.empresa.endereco"
                             name="endereco" #endereco required>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Fim Informacoes Adicionais-->
              <!--Telefones-->
              <div *ngIf="formulario == 3 && usuarioEdicao.empresa != null">
                <div class="m-2">
                  <div class="row">
                    <div class="form-group col-4 pl-0">
                      <label>{{'telefone.tipoTelefone'|translate}}</label>
                      <select class="form-control" [(ngModel)]="telefone.tipoTelefone" name="tipoTelefone">
                        <option [ngValue]="0">Fixo</option>
                        <option [ngValue]="1">Celular</option>
                      </select>
                    </div>
                    <div class="form-group col-4 pl-0">
                      <label>{{'telefone.numero'|translate}}</label>
                      <input *ngIf="telefone.tipoTelefone == 0" type="text" class="form-control"
                             [(ngModel)]="telefone.numero"
                             [textMask]="{mask: maskFixo}"
                             [class.is-invalid]="telefoneFixo.invalid && telefoneFixo.touched"
                             name="numero"
                             #telefoneFixo required>
                      <input *ngIf="telefone.tipoTelefone == 1 || telefone.tipoTelefone == null"
                             type="text" class="form-control"
                             [(ngModel)]="telefone.numero"
                             [textMask]="{mask: maskCelular}"
                             [class.is-invalid]="telefoneCelular.invalid && telefoneCelular.touched" name="numero" #telefoneCelular required>
                    </div>
                    <div class="form-group col-4 pl-4">
                      <button class="btn btn-success" *ngIf="telefone.idTelefone == null" style="margin-top: 28px;" (click)="adicionarTelefone()">{{'btn.cadastrar'|translate}} <i class="mdi mdi-cellphone-basic"></i></button>

                    </div>
                  </div>
                  <table class="table">
                    <thead>
                      <tr>
                        <td>
                          {{'telefone.tipoTelefone'|translate}}
                        </td>

                        <td colspan="2">
                          {{'telefone.numero'|translate}}
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let telefoneList of usuarioEdicao.empresa?.telefones">
                        <td>{{telefoneList.tipoTelefone == 0 ? 'Fixo' : 'Celular'}}</td>
                        <td>{{telefoneList.numero}}</td>
                        <td>
                          <div class="d-flex">
                            <button class="btn btn-outline-danger" (click)="removerTelefone(telefoneList)">
                              <i class="mdi mdi-delete"></i>
                            </button>
                          </div>
                        </td>
                      </tr>
                      <tr *ngIf="usuarioEdicao.empresa?.telefones.length == 0">
                        <td colspan="3" class="text-center">
                          <span>Nenhum telefone cadastrado</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <!--Fim telefone-->
              <!--Armazens-->
              <div *ngIf="formulario == 4 && usuarioEdicao.empresa != null">
                <div class="m-2">
                  <div class="row">
                    <div class="form-group col-6 pl-0">
                      <label>{{'armazem.proprio'|translate}}</label>
                      <input type="checkbox" class="form-control" [(ngModel)]="armazem.proprio"
                             [class.is-invalid]="proprio.invalid && proprio.touched" name="proprio" #proprio
                             required>
                    </div>
                    <div class="form-group col-6 pl-0">
                      <label>{{'armazem.tulhaCoberta'|translate}}</label>
                      <input type="checkbox" class="form-control" [(ngModel)]="armazem.tulhaCoberta"
                             [class.is-invalid]="tulhaCoberta.invalid && tulhaCoberta.touched" name="tulhaCoberta" #tulhaCoberta
                             required>
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group col-6 pl-0">
                      <label>{{'armazem.numCDA'|translate}}</label>
                      <input type="text" class="form-control" [(ngModel)]="armazem.numCDA"
                             [class.is-invalid]="numCDA.invalid && numCDA.touched" name="numCDA" #numCDA required>
                    </div>
                    <div class="form-group col-6 pl-0">
                      <label>{{'armazem.recebimentoTonHora'|translate}}</label>
                      <input type="number" class="form-control" [(ngModel)]="armazem.recebimentoTonHora"
                             name="recebimentoTonHora" #recebimentoTonHora>
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group col-6 pl-0">
                      <label>{{'armazem.expedicaoTonHora'|translate}}</label>
                      <input type="number" class="form-control" [(ngModel)]="armazem.expedicaoTonHora"
                             name="expedicaoTonHora" #expedicaoTonHora>
                    </div>

                    <div class="form-group col-6 pl-0">
                      <label>{{'armazem.metragemBalanca'|translate}}</label>
                      <input type="number" class="form-control" [(ngModel)]="armazem.metragemBalanca"
                             name="metragemBalanca" #metragemBalanca>
                    </div>
                  </div>
                  <div class="row">

                    <div class="form-group col-6 pl-0">
                      <label>{{'armazem.tipo'|translate}}</label>
                      <select class="form-control" [(ngModel)]="armazem.idTipoArmazem" name="idTipoArmazem">
                        <option *ngFor="let tipoArmazem of listaTiposArmazem" [ngValue]="tipoArmazem.idTipoArmazem">
                          {{tipoArmazem.tipo}}
                        </option>
                      </select>

                    </div>
                  </div>

                  <button class="btn btn-success float-right" *ngIf="armazem.idArmazem == null" (click)="adicionarArmazem()">{{'btn.cadastrar'|translate}} <i class="mdi mdi-plus"></i></button>
                  <button class="btn btn-success float-right" *ngIf="armazem.idArmazem != null" (click)="adicionarArmazem()">{{'btn.editar'|translate}} <i class="mdi mdi-pencil"></i></button>
                  <table class="table">
                    <thead>
                      <tr>
                        <td>
                          {{'armazem.proprio'|translate}}
                        </td>
                        <td>
                          {{'armazem.tulhaCoberta'|translate}}
                        </td>
                        <td>
                          {{'armazem.numCDA'|translate}}
                        </td>
                        <td>
                          {{'armazem.recebimentoTonHora'|translate}}
                        </td>
                        <td>
                          {{'armazem.expedicaoTonHora'|translate}}
                        </td>

                        <td>
                          {{'armazem.metragemBalanca'|translate}}
                        </td>
                        <td>
                          {{'armazem.tipo'|translate}}
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let armazem of usuarioEdicao.empresa.armazens">
                        <td>
                          {{armazem.proprio ? 'Sim' : 'Não'}}
                        </td>
                        <td>
                          {{armazem.tulhaCoberta ? 'Sim' : 'Não'}}
                        </td>
                        <td>
                          {{armazem.numCDA}}
                        </td>
                        <td>
                          {{armazem.recebimentoTonHora || 'Não informado'}}
                        </td>
                        <td>
                          {{armazem.expedicaoTonHora || 'Não informado'}}
                        </td>

                        <td>
                          {{armazem.metragemBalanca || 'Não informado'}}
                        </td>
                        <td>
                          {{armazem.tipo || 'Não informado'}}
                        </td>
                        <td>
                          <div class="d-flex">
                            <button class="btn btn-outline-success mr-2" title="{{'btn.editar'|translate}}" (click)="editarArmazem(armazem)">
                              <i class="mdi mdi-pencil"></i>
                            </button>
                            <button class="btn btn-outline-danger" title="{{'btn.remover'|translate}}" (click)="removerArmazem(armazem)">
                              <i class="mdi mdi-delete"></i>
                            </button>
                          </div>
                        </td>
                      </tr>
                      <tr *ngIf="usuarioEdicao.empresa.armazens.length == 0">
                        <td colspan="7" class="text-center">
                          <span>Nenhum armazem cadastrado</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <!--Fim Armazens-->
              <!--Propriedades/Fazendas-->
              <div [hidden]="!(formulario == 5 && usuarioEdicao.empresa != null)">
                <div class="m-2">
                  <div class="form-group">
                    <label>Endereço</label>
                    <input type="text" class="form-control" (keydown.enter)="$event.preventDefault()" placeholder="Pesquisa a localidade" autocorrect="off" autocapitalize="off" spellcheck="off" #search>
                  </div>

                  <google-map height="500px"
                              width="100%"
                              [center]="center"
                              [zoom]="zoom">
                    <map-marker [position]="marker" #markerElem>                </map-marker>
                  </google-map>
                  <button class="btn btn-success float-right" (click)="incluirPropriedade()">{{'btn.cadastrar'|translate}} <i class="mdi mdi-map-marker"></i></button>
                  <table class="table">
                    <thead>
                      <tr>
                        <td>Endereço</td>
                        <td></td>
                      </tr>
                    </thead>
                    <tr *ngFor="let propriedade  of usuarioEdicao?.empresa?.propriedades">
                      <td>
                        {{propriedade.nome}}
                      </td>
                      <td>
                        <div class="d-flex">
                          <button class="btn btn-outline-success mr-2" title="{{'btn.visualizar'|translate}}" (click)="abrirLocationNoGoogle(propriedade)">
                            <i class="mdi mdi-google-maps"></i>
                          </button>
                          <button class="btn btn-outline-danger" title="{{'btn.remover'|translate}}" (click)="removerPropriedade(propriedade)">
                            <i class="mdi mdi-delete"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                    <tr *ngIf="usuarioEdicao?.empresa?.propriedades?.length == 0">
                      <td colspan="2" class="text-center">
                        <span>Nenhum endereço cadastrado</span>
                      </td>
                    </tr>
                  </table>

                </div>
              </div>
              <!--Fim propriedade/fazendas-->
              <!-- Periodos de chuvas -->
              <div *ngIf="formulario == 6 && usuarioEdicao.empresa != null">
                <div class="m-2">
                  <table class="table">
                    <thead>
                      <tr>
                        <td></td>
                        <td *ngFor="let periodoDeChuvaMes of usuarioEdicao.empresa.periodoChuvas">
                          {{periodoDeChuvaMes.mesDesc}}
                        </td>
                      </tr>
                    </thead>
                    <tbody>

                      <tr>
                        <td>1º Quinzena</td>
                        <td *ngFor="let periodoChuva of usuarioEdicao.empresa.periodoChuvas">
                          <input type="checkbox" name="primeiraQuinzena" [checked]="periodoChuva.primeiraQuinzena" (change)="periodoChuva.primeiraQuinzena = !periodoChuva.primeiraQuinzena">
                        </td>
                      </tr>
                      <tr>
                        <td>2º Quinzena</td>
                        <td *ngFor="let periodoChuva of usuarioEdicao.empresa.periodoChuvas">
                          <input type="checkbox" name="segundaQuinzena" [checked]="periodoChuva.segundaQuinzena" (change)="periodoChuva.segundaQuinzena = !periodoChuva.segundaQuinzena">
                        </td>
                      </tr>

                    </tbody>
                  </table>
                </div>
              </div>
              <!-- Fim Periodos de chuvas -->
              <!-- Vinculos -->
              <div *ngIf="formulario == 7 && usuarioEdicao.empresa != null">
                <div class="d-flex align-items-center flex-column">
                  <div class="card">
                    <div class="p-2 text-center">
                      <h5>{{usuarioEdicao.empresa.id}}</h5>
                    </div>
                    <div class="p-2">
                      <div class="alert alert-primary" role="alert">
                        Compartilhe o código para que outros se vinculem ao seu grupo
                        <button class="btn" (click)="compartilharCodigo()" title="Compartilhe"><i class="mdi mdi-share-variant"></i></button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex justify-content-end">
                  <div class="p-2">
                    <button class="btn btn-success" [routerLink]="['/vinculo/criar/'+ usuarioEdicao.empresa.id]">
                      {{'btn.cadastrar'|translate}} <i class="mdi mdi-account-network"></i>
                    </button>
                  </div>
                </div>
                <hr />
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <td colspan="5">
                        <strong class="d-flex justify-content-center">
                          Solicitações de vinculo(Terceiros)
                        </strong>
                      </td>
                    </tr>
                  </thead>
                  <thead>
                    <tr>
                      <td>
                        {{'vinculo.vinculo'|translate}}
                      </td>
                      <td>
                        {{'vinculo.dtSolicitacao'|translate}}
                      </td>
                      <td>
                        {{'vinculo.dtReposta'|translate}}
                      </td>
                      <td>
                        Situação
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let vinculo of usuarioEdicao.empresa.vinculados">
                      <td>{{vinculo.cnpjSolicitante}}</td>
                      <td>{{vinculo.dtSolicitacao | date:'dd/MM/yyyy HH:mm'}}</td>
                      <td>{{vinculo.dtResposta | date:'dd/MM/yyyy HH:mm' || '-'}}</td>
                      <td>{{vinculo.descSituacao}}</td>
                      <td>
                        <div class="d-flex">
                          <button class="btn btn-outline-success mr-2" title="{{'btn.aprovar'|translate}}" (click)="responderVinculo(vinculo, 1)" *ngIf="vinculo.dtResposta == null">
                            <i class="mdi mdi-thumb-up"></i>
                          </button>

                          <button class="btn btn-outline-danger" title="{{'btn.recusar'|translate}}" (click)="responderVinculo(vinculo, 2)" *ngIf="vinculo.dtResposta == null">
                            <i class="mdi mdi-thumb-down"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                    <tr *ngIf="usuarioEdicao.empresa.vinculados.length == 0">
                      <td colspan="5" class="text-center">
                        Nenhuma solicitação de vinculo
                      </td>
                    </tr>
                  </tbody>

                  <thead>
                    <tr>
                      <td colspan="5">
                        <strong class="d-flex justify-content-center">
                          Minhas Solicitações
                        </strong>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let vinculo of usuarioEdicao.empresa.solicitacoesVinculos">
                      <td>{{vinculo.razaoSocialVinculado}}</td>
                      <td>{{vinculo.dtSolicitacao | date:'dd/MM/yyyy HH:mm'}}</td>
                      <td>{{vinculo.dtResposta | date:'dd/MM/yyyy HH:mm' || '-'}}</td>
                      <td colspan="2">{{vinculo.descSituacao}}</td>
                    </tr>
                    <tr *ngIf="usuarioEdicao.empresa.solicitacoesVinculos.length == 0" class="text-center">
                      <td colspan="4">
                        Nenhuma solicitação de vinculo cadastrada
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- Fim Vinculos-->

              <div class="form-group col-6 pl-0">
                <div class=" d-flex">
                  <button class="btn btn-success mr-2" (click)="ehEdicao ? atualizarUsuario() : cadastrarUsuario()">{{'btn.salvar'|translate}} <i class="mdi mdi-content-save"></i></button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </nb-card-body>
  </nb-card>
</main>


