import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import { CustomAdapter, CustomDateParserFormatter } from '../../../datepicker.custom.adapter';
import { MoedaEnum } from '../../../enums/MoedaEnum';
import { Calculo } from '../../../model/calculo';
import { CotacaoService } from '../../../services/cotacaoService';
import { CurvaCambioService } from '../../../services/CurvaCambioService';
import { PrecificacaoService } from '../../../services/precificacaoService';
import { PremioService } from '../../../services/premioService';
import { ToastService } from '../../../services/toastService';
@Component({
  selector: 'app-editarboleta',
  templateUrl: './editarboleta.component.html',
  styleUrl: './editarboleta.component.css',
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
  ]
})
export class EditarboletaComponent implements OnInit, AfterViewInit {
  boleta: any;
  editarForm: FormGroup;
  readonly MoedaEnum = MoedaEnum;
  loading = false;
  freteRodoData: NgbDateStruct;
  freteFerroData: NgbDateStruct;
  dataHedgeProduto: NgbDateStruct;
  cotacaoDoDia: any;
  premios: any = [];
  permiteCalcularPremio = false;
  enviandoDados = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private precificacaoService: PrecificacaoService,
    private fb: FormBuilder,
    private toastService: ToastService,
    private curvaCambioService: CurvaCambioService,
    private cotacaoService: CotacaoService
  ) { }
   

  ngOnInit(): void {
    const negocioId = this.route.snapshot.paramMap.get('negocioId');
    this.editarForm = new FormGroup({
      negocioId: this.fb.control(''),
      valorNegocio: this.fb.control(''),

      premioValor: this.fb.control(''),

      quantidade: this.fb.control(''),
      dataHedgeProduto: this.fb.control(''),
      produtoValor: this.fb.control(''),
      produtoTipoMoeda: this.fb.control(''),
      unidadeNegociada: this.fb.control(''),
      cbotCode: this.fb.control(''),
      cbotValor: this.fb.control(''),
      hedgeProduto: this.fb.control(''),

      freteHedgeRodo: this.fb.control(''),
      freteRodoData: this.fb.control(''),
      freteRodoValor: this.fb.control(''),

      freteHedgeFerro: this.fb.control(''),
      freteFerroData: this.fb.control(''),
      freteFerroValor: this.fb.control(''),

      componentes: this.fb.array([]),
    });
    this.obterDolarDoDia();
    this.obterBoleta(negocioId);
   
  }

  ngAfterViewInit(): void {
    for (const campo in this.editarForm.controls) {

      if (campo != "premioValor") {
        this.editarForm.controls[campo].valueChanges.subscribe(data => {
          this.calcularPremio(data);
        });
      }
    }
  }


  obterDolarDoDia() {
    this.cotacaoService.obterCotacaoDolarDeHoje().subscribe(res => {
      var retApi = res.USDBRL;
      if (retApi != null) {
        this.cotacaoDoDia = { cotacaoCompra: Number(retApi.bid), cotacaoVenda: Number(retApi.ask), dataHoraCotacao: retApi.create_date };
      }
    });
  }

  obterBoleta(negocioId) {
    this.carregando();
    this.precificacaoService.obterBoleta(negocioId).subscribe(res => {
      this.carregou();
      if (res.result) {
        this.boleta = res.result;

        this.editarForm.patchValue(this.boleta);

        this.freteRodoData = this.formatarData(this.boleta.freteRodoData);

        this.freteFerroData = this.formatarData(this.boleta.freteFerroData);

        this.dataHedgeProduto = this.formatarData(this.boleta.dataHedgeProduto);

        let componentes = this.editarForm.get('componentes') as FormArray;
        this.boleta.componentes?.forEach(element => {
          var dtAux: NgbDateStruct;
          if (element.dataHedge == null) {
            dtAux = null;
          }
          else {
            dtAux = this.formatarData(element.dataHedge);
          }

          var dt: NgbDateStruct = dtAux;

          componentes.push(this.fb.group({
            id: [element.id],
            nome: [element.nome],
            unidadeNome: [element.unidadeNome],
            unidadeSigla: [element.unidadeSigla],
            valor: [element.valor],
            calculo: [element.calculo],
            moedaSigla: [element.moedaSigla],
            moedaId: [element.moedaId],
            dataHedge: [dt],
            valorHedge: [element.valorHedge],
            possuiEdge: [element.possuiEdge]
          }));
          
         
        });
        this.permiteCalcularPremio = true;
      }
    });
  }

  calcularPremio(data) {
   
    if (this.permiteCalcularPremio) {
      const formJson = this.editarForm.getRawValue();
      var vlrX = (this.totalResumeEmDolares / formJson.quantidade) / this.boleta.produtoFator;
      this.editarForm.get('premioValor').setValue(vlrX - formJson.cbotValor);
    }
  };

  convertDialogDateParaData(dataDialog: any) {

    if (typeof (dataDialog) === 'string') {
      return moment(dataDialog, "DD/MM/YYYY").format("YYYY-MM-DD");
    }

    return moment([dataDialog.year, dataDialog.month - 1, dataDialog.day]).format("YYYY-MM-DD");
  }


  salvarDados() {

    const formJson = this.editarForm.getRawValue();
    var obj = formJson;
    var dataFreteRodo = this.convertDialogDateParaData(this.freteRodoData);
    if (!moment(dataFreteRodo).isValid()) {
      this.toastService.show("Data de frete rodoviário incorreta", { classname: 'bg-danger text-light', delay: 5000 })
      return
    }

    var dataFreteFerro = this.convertDialogDateParaData(this.freteFerroData);
    if (!moment(dataFreteFerro).isValid()) {
      this.toastService.show("Data de frete ferroviário incorreta", { classname: 'bg-danger text-light', delay: 5000 })
      return
    }

    this.enviandoDados = true;

    obj.freteRodoData = dataFreteRodo;
    obj.freteFerroData = dataFreteFerro;

    if (this.boleta.dataHedgeProduto) {
      obj.dataHedgeProduto = this.convertDialogDateParaData(this.dataHedgeProduto);
    }

    obj.componentes = formJson.componentes.map((c: any) => ({
      "id": c.id,
      "valor": c.valor,
      "dataHedge": c.dataHedge == null ? null : this.convertDialogDateParaData(c.dataHedge),
      "valorHedge": c.valorHedge
    }));
    this.carregando();
    this.precificacaoService.salvarBoleta(formJson).subscribe(response => {
      this.carregou();
      this.enviandoDados = false;
      if (response.codigo == 200) {
        this.toastService.show('Boleta salva com sucesso', { classname: 'bg-success text-light', delay: 5000 });
        this.redirecionarParaConcluidos();
      }
      else
        this.toastService.show(response.mensagem, { classname: 'bg-danger text-light', delay: 5000 });

    }, onerror => {
      this.carregou();
      this.enviandoDados = false;
      this.toastService.show("Erro ao salvar boleta, tente novamente", { classname: 'bg-danger text-light', delay: 5000 });
    });
  }



  private redirecionarParaConcluidos() {
    this.router.navigate([`/NegocioConcluido`])
  }

  get quantidade(): number {
    return this.editarForm.controls.quantidade.value;
  }

  produtoValor(tipoRetornoMoeda: MoedaEnum): number {
    var valor = this.editarForm.controls.produtoValor.value;
    var hedgeProduto = this.editarForm.controls.hedgeProduto.value;


    if (this.produtoTipoMoeda == tipoRetornoMoeda) {
      return valor / 0.06;
    }

    if (this.produtoTipoMoeda == MoedaEnum.DOLAR && tipoRetornoMoeda == MoedaEnum.REAL)
      return hedgeProduto > 0 ? valor / hedgeProduto : 0;

    return valor * hedgeProduto;
  }

  get produtoTipoMoeda() {
    return this.editarForm.controls.produtoTipoMoeda.value;
  }

  formatarDuasCasasDecimais(valor: number) {
    return parseFloat(valor.toFixed(2));
  }

  valorReal(valor: number): number {
    const quantidade = this.quantidade;
    return valor * quantidade;
  }

  valorDolar(valor: number, hedge): number {

    const total = this.valorReal(valor) / hedge;
    return this.formatarDuasCasasDecimais(total);
  }

  get ehcompra() {
    return this.boleta.ordem == "Compra";
  }



  obterQuintoDiaUtilRodo(data) {
    var self = this;
    if (!moment(data, "DD/MM/YYYY", true).isValid()) {
      this.toastService.show("Selecione uma data válida", { classname: 'bg-danger text-light', delay: 5000 });
      return
    };

    var dataFormatada = moment(data, "DD/MM/YYYY", true).format('YYYY-MM-DD');
    self.editarForm.get('freteRodoData').setValue(dataFormatada);
    this.obterQuintoDiaUtil(dataFormatada, function (cotacao) {
      var dolarFuturo = self.ehcompra ? cotacao.dolarCompra : cotacao.dolarVenda;
      self.editarForm.get('freteHedgeRodo').setValue(dolarFuturo);
    });
  }

  obterQuintoDiaUtilFerro(data) {
    var self = this;
    if (!moment(data, "DD/MM/YYYY", true).isValid()) return;

    var dataFormatada = moment(data, "DD/MM/YYYY", true).format('YYYY-MM-DD');
    self.editarForm.get('freteFerroData').setValue(dataFormatada);
    this.obterQuintoDiaUtil(dataFormatada, function (cotacao) {
      var dolarFuturo = self.ehcompra ? cotacao.dolarCompra : cotacao.dolarVenda;
      self.editarForm.get('freteHedgeFerro').setValue(dolarFuturo);
    });
  }

  obterQuintoDiaUtilMercado(data) {
    this.editarForm.get('dataHedgeProduto').setValue(moment(data, "DD/MM/YYYY").format('YYYY-MM-DD'));
    if (this.boleta.produtoTipoMoeda == MoedaEnum.REAL)
      this.calcularQuintoDiaUtilDolar(data, 'dataHedgeProduto', 'hedgeProduto');
  }

  calcularQuintoDiaUtilDolar(data, nomeCampoData, nomeCampoHedge) {
    var self = this;
    if (!moment(data, "DD/MM/YYYY", true).isValid()) return;

    var dataFormatada = moment(data, "DD/MM/YYYY", true).format('YYYY-MM-DD');

    self.editarForm.get(nomeCampoData).setValue(dataFormatada);
    this.obterQuintoDiaUtil(dataFormatada, function (cotacao) {
      var dolarFuturo = self.ehcompra ? cotacao.dolarCompra : cotacao.dolarVenda;
      self.editarForm.get(nomeCampoHedge).setValue(dolarFuturo);
    });
  }


  obterQuintoDiaUtilComponente(componente: FormGroup) {
    var form = componente;
    var self = this;
    var data = form.controls.dataHedge.value;
    if (!moment(data, "DD/MM/YYYY", true).isValid()) {
      this.toastService.show("Selecione uma data válida", { classname: 'bg-danger text-light', delay: 5000 });
      return;
    }

    var dataFormatada = moment(data, "DD/MM/YYYY", true).format('YYYY-MM-DD');
    this.obterQuintoDiaUtil(dataFormatada, function (cotacao) {
      var dolarFuturo = self.ehcompra ? cotacao.dolarCompra : cotacao.dolarVenda;
      form.controls.valorHedge.setValue(dolarFuturo);
    });
  }


  obterQuintoDiaUtil(data, atribuirNovoCambio: Function) {
    this.curvaCambioService.obterQuintoDiaUtil(data, false).subscribe(response => {
      if (response.codigo == 200) {
        var dataResult = response.result;
        this.obterDolarFuturo(dataResult, atribuirNovoCambio);
      }
      else
        this.toastService.show(response.mensagem, { classname: 'bg-danger text-light', delay: 5000 })
    });
  }

  obterDolarFuturo(dataFim, atribuirNovoCambio: Function) {
    if (dataFim == null) return;

    if (!moment(dataFim, 'YYYY-MM-DD', true).isValid()) {
      this.toastService.show("Data para obter dólar futuro está no formato incorreto", { classname: 'bg-danger text-light', delay: 5000 })
      return;
    }
    let dataFimFormatada: string = dataFim;


    var dtoDolar = {
      dolarCompra: this.cotacaoDoDia.cotacaoCompra,
      dolarVenda: this.cotacaoDoDia.cotacaoVenda,
      dataInicio: new Date(),
      dataFim: dataFimFormatada
    }

    this.curvaCambioService.obterDolarFuturo(dtoDolar).subscribe(response => {
      if (response.codigo === 200) {
        atribuirNovoCambio(response.result);
      }
      else {
        this.toastService.show(response.mensagem, { classname: 'bg-danger text-light', delay: 5000 })
      }
    })
  }


  mostrarSeForMoeda(moeda: MoedaEnum) {
    return this.mostrarSeMoedaForDolar(moeda) || this.mostrarSeMoedaForReal(moeda);
  }

  mostrarSeMoedaForDolar(moeda: MoedaEnum) {
    return MoedaEnum.DOLAR == moeda;
  }

  mostrarSeMoedaForReal(moeda: MoedaEnum) {
    return MoedaEnum.REAL == moeda;
  }

  porcentagem(moeda: MoedaEnum) {
    return MoedaEnum.PORCENTAGEM == moeda;
  }

  get calcularvalorProdutoDolar() {
    if (this.produtoTipoMoeda == MoedaEnum.REAL) {
      var hedgeProduto = this.editarForm.controls.hedgeProduto.value;
      return parseFloat((this.calcularvalorProdutoReal / hedgeProduto).toFixed(2));
    }
    return parseFloat((this.produtoValor(MoedaEnum.DOLAR) * this.quantidade).toFixed(2));
  }

  get calcularvalorProdutoReal() {
    var produtoValor = this.produtoValor(MoedaEnum.REAL);

    return produtoValor * this.quantidade;
  }

  get calcularElecacaoQuePossuiHedgeEmReal() {
    const data = this.editarForm.getRawValue();
    var componentes = data.componentes;
    return componentes.filter((c: any) => c.moedaId == MoedaEnum.REAL && c.valorHedge != null).map((c: any) => this.calcularReal(c.valor, c.valorHedge, c.moedaId)).reduce((acc, curr) => acc + curr, 0);
  }

  get totalResumeEmReais() {
    return this.totalRodoMaisFerroEmReal + this.calcularElecacaoQuePossuiHedgeEmReal + this.calcularvalorProdutoReal;
  }

  get totalResumeEmDolares() {
    return this.totalRodoMaisFerroEmDolar + this.totalComponentesEmDolares + this.totalComponentesSemEdgeEmDolares + this.calcularvalorProdutoDolar;
  }

  calcularReal(valorEntrada: number, hedge: number, moedaEntrada: MoedaEnum) {
    var valor = valorEntrada;
    if (moedaEntrada == MoedaEnum.REAL)
      return this.valorReal(valor);

    valor = this.calcularMoeda(valorEntrada, hedge, moedaEntrada, MoedaEnum.REAL);
    return this.valorReal(valor);
  }

  calcularDolar(valorEntrada: number, hedge: number, moedaEntrada: MoedaEnum) {
    if (hedge == null) {
      return valorEntrada * this.quantidade;
    }

    const valor = this.calcularMoeda(valorEntrada, hedge, moedaEntrada, MoedaEnum.REAL);

    return this.valorDolar(valor, hedge);
  }

  freteEmReal(valorEntrada: number) {
    return this.valorReal(valorEntrada);
  }

  freteEmDolar(valorEntrada: number, hedge) {

    return this.valorDolar(valorEntrada, hedge);
  }

  totalComponenteSemCalculoDolar() {
    const data = this.editarForm.getRawValue();
    var total = 0;
    data.componentes.filter((x: any) => x.calculo == null && x.moedaId == MoedaEnum.DOLAR).forEach((componente, index) => {
      total += componente.valor;
    });
    return parseFloat(Math.round(total).toFixed(4));
  }

  totalComponenteSemCalculoEmRealConvertidoEmDolar() {
    const data = this.editarForm.getRawValue();
    var total = 0;
    data.componentes.filter((x: any) => x.calculo == null && x.moedaId == MoedaEnum.REAL).forEach((componente, index) => {
      if (componente.valorHedge != null) {
        total += parseFloat(Math.round(componente.moedaId == MoedaEnum.REAL ? (componente.valor / componente.valorHedge) : componente.valor).toFixed(4));
      }
    });
    return parseFloat(Math.round(total).toFixed(4));
  }

  somatorioComponentesSemRetencoes() {
    return this.totalComponenteSemCalculoDolar() + this.totalComponenteSemCalculoEmRealConvertidoEmDolar();
  }


  fobOrigem() {
    const formJson = this.editarForm.getRawValue();
    return Math.abs(Calculo.calcularFobOrigem(formJson.cbotValor, formJson.premioValor, formJson.freteFerroValor, formJson.freteHedgeFerro, formJson.freteRodoValor, formJson.freteHedgeRodo, this.boleta.produtoFator) - this.somatorioComponentesSemRetencoes());
  }

  transbordo() {
    const formJson = this.editarForm.getRawValue();
    return Math.abs(Calculo.calcularTransbordo(formJson.cbotValor, formJson.premioValor, formJson.freteFerroValor, formJson.freteHedgeFerro, this.boleta.produtoFator) - this.somatorioComponentesSemRetencoes());
  }

  pool() {
    const formJson = this.editarForm.getRawValue();
    return Math.abs(Calculo.calcularPool(formJson.cbotValor, formJson.premioValor, this.boleta.produtoFator) - this.somatorioComponentesSemRetencoes());
  }



  get totalComponentesEmReais() {
    const data = this.editarForm.getRawValue();
    var total = 0
    data.componentes.forEach((element, index) => {
      total += this.calcularReal(element.valor, element.valorHedge, element.moedaId);
    });
    return total;
  }

  get totalComponentesEmDolares() {
    const data = this.editarForm.getRawValue();
    var total = 0
    data.componentes.filter((c: any) => c.valorHedge != null).forEach((componente, index) => {
      total += this.calcularDolar(componente.valor, componente.valorHedge, componente.moedaId);
    });
    return total;
  }

  get totalComponentesSemEdgeEmDolares() {
    const data = this.editarForm.getRawValue();
    var total = 0
    data.componentes.filter((c: any) => c.valorHedge == null).forEach((componente, index) => {
      total += this.calcularDolar(componente.valor, componente.valorHedge, componente.moedaId);
    });
    return total;
  }


  get totalSomaComponentesEmReais() {
    const data = this.editarForm.getRawValue();
    return data.componentes.filter((c: any) => c.moedaId == MoedaEnum.REAL).map((c: any) => c.valor).reduce((acc, curr) => acc + curr, 0);
  }

  get totalSomaComponentesEmDolares() {
    const data = this.editarForm.getRawValue();

    return data.componentes.filter((c: any) => c.moedaId != MoedaEnum.REAL).map((c: any) => c.valor).reduce((acc, curr) => acc + curr, 0);
  }

  get totalRodoMaisFerroEmReal() {
    const formJson = this.editarForm.getRawValue();
    const valorRodo = formJson.freteRodoValor;
    const valorFerro = formJson.freteFerroValor;
    return this.freteEmReal(valorRodo) + this.freteEmReal(valorFerro);
  }

  get totalRodoMaisFerroEmDolar() {
    const formJson = this.editarForm.getRawValue();
    const valorRodo = formJson.freteRodoValor;
    const freteHedgeRodo = formJson.freteHedgeRodo;
    const valorFerro = formJson.freteFerroValor;
    const freteHedgeFerro = formJson.freteHedgeFerro;
    return this.freteEmDolar(valorRodo, freteHedgeRodo) + this.freteEmDolar(valorFerro, freteHedgeFerro);
  }



  calcularMoeda(valorEntrada: number, hedge: number, moedaEntrada: MoedaEnum, moedaRetorno) {
    if (moedaEntrada != MoedaEnum.DOLAR && moedaEntrada != MoedaEnum.REAL)
      return 0;

    if (moedaEntrada == MoedaEnum.DOLAR && moedaRetorno == MoedaEnum.REAL)
      return valorEntrada * hedge;

    if (moedaEntrada == MoedaEnum.REAL && moedaRetorno == MoedaEnum.DOLAR)
      return valorEntrada / hedge;

    return valorEntrada;
  }

  formatarData(data: string) {
    var dataRodo = moment(data, "YYYY-MM-DD");
    var mes = dataRodo.format('M');
    var dia = dataRodo.format('D');
    var ano = dataRodo.format('YYYY');
    return { day: +dia, month: +mes, year: +ano };
  }


  get componentes(): FormControl[] {
    return (this.editarForm.get('componentes') as FormArray).controls as FormControl[];
  }

  carregando() {
    this.loading = true;
  }

  carregou() {
    this.loading = false;
  }

}
