<main>
  <nb-card [nbSpinner]="loading">
    <nb-card-header>
      <div class="row">
        <h3>{{'Menus.Frete'|translate}}</h3>
       
      </div>
    </nb-card-header>
    <nb-card-body>
      <div class="d-md-flex d-sm-block justify-content-between align-items-center">
        <div class="d-flex align-items-end">
          <div class="form-group">
            <label>{{'praca.nomePraca'|translate}}</label>
            <input type="text" class="form-control" [(ngModel)]="filtro.nomePraca">
          </div>

          <button class="btn btn-success mb-3 ml-3" (click)="obterListaPraca(1)">{{'btn.filtrar'|translate}} <i class="mdi mdi-filter"></i></button>
        </div>
        <button class="btn btn-success" (click)="open(novaPraca)">{{'btn.cadastrar'|translate}} <i class="mdi mdi-truck"></i></button>
      </div>
      <div class="table-gp">
        <div class="form-group float-right">
          <label>{{'filtro.tamanhoPagina'|translate}}</label>
          <select class="form-control" [(ngModel)]="filtro.tamanhoPagina" name="tamanhoPagina" (change)="obterListaPraca(1)">
            <option *ngFor="let tamanhoPagina of tamanhosPaginas" [ngValue]="tamanhoPagina">
              {{tamanhoPagina}}
            </option>
          </select>
        </div>
        <table class="table">
          <thead class="thead-dark">
            <tr>
              <th>{{'praca.nomePraca'|translate}}</th>
              <th>{{'acao'|translate}}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let praca of listaPraca">
              <td>{{praca.nomePraca}}</td>
              <td>
                <div class="d-flex">
                  <button [routerLink]="['/fretes/'+praca.pracaId]" class="btn btn-outline-warning">
                    <i class="mdi mdi-pencil"></i>
                  </button>
                  <button class="btn btn-outline-danger ml-2" (click)="open(mymodal);selecionarFrete(praca.pracaId)">
                    <i class="mdi mdi-delete"></i>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="d-flex justify-content-end">
        <ngb-pagination [collectionSize]="filtro.total" [pageSize]="filtro.tamanhoPagina" [(page)]="filtro.numeroPagina"
                        [maxSize]="10" [rotate]="true" [boundaryLinks]="true" (pageChange)="obterListaPraca($event)">
        </ngb-pagination>
      </div>

    </nb-card-body>
  </nb-card>
</main>
<ng-template #mymodal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">{{'praca.inativarPraca'|translate}}</h5>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{'praca.pergunta.inativarPraca'|translate}}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="modal.dismiss('Cross click')"
            data-dismiss="modal">
      {{'fechar'|translate}}
    </button>
    <button type="button" class="btn btn-danger" (click)="inativarPraca()">{{'sim'|translate}}</button>
  </div>
</ng-template>
<ng-template #novaPraca let-modal>
  <div class="modal-header">
    <h5 class="modal-title">{{'praca.novaPraca'|translate}}</h5>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group col-5 pl-0">
      <label>{{'praca.nomePraca'|translate}}</label>
      <input type="text" class="form-control" [(ngModel)]="novaPraca.NomePraca" onkeyup="
           var start = this.selectionStart;
           var end = this.selectionEnd;
           this.value = this.value.toUpperCase();
           this.setSelectionRange(start, end);">
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="modal.dismiss('Cross click')"
            data-dismiss="modal">
      {{'fechar'|translate}}
    </button>
    <button type="button" class="btn btn-success" (click)="cadastrarPraca(novaPraca.NomePraca)">{{'cadastrar'|translate}}</button>
  </div>
</ng-template>
