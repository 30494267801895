import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aviso-aguardar-aprocao',
  templateUrl: './aviso-aguardar-aprocao.component.html',
  styleUrls: ['./aviso-aguardar-aprocao.component.css']
})
export class AvisoAguardarAprocaoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
