import { Component, TemplateRef } from '@angular/core';

import { ToastService } from '../../services/toastService';


@Component({
  selector: 'app-toasts',
  template: `
   @for (toast of toastService.toasts; track toast) {
   <ngb-toast style="z-index:999999"
      [class]="toast.classname"
      [autohide]="true"
      [delay]="toast.delay || 5000"
      (hidden)="toastService.remove(toast)"
    >
      <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
        <ng-template [ngTemplateOutlet]="toast.textOrTpl"></ng-template>
      </ng-template>

      <ng-template #text>{{ toast.textOrTpl }}</ng-template>
    </ngb-toast>
    }
  `,
  host: { '[class.ngb-toasts]': 'true', style: "position: fixed;  top: 0!important;  right: auto!important; left: 0!important; margin: 0.5em; z-index: 1200; " }
})
export class ToastsContainer {
    constructor(public toastService: ToastService) {
    }

    isTemplate(toast) { return toast.textOrTpl instanceof TemplateRef; }
}
