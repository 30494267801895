<div class="container h-100">
  <div class="row vh-100 justify-content-center align-items-center">
    <div class="card">
      <div class="card-body">
        <form class="col-12" #login novalidate>
          <h3 class="h3 text-center">Insira sua nova senha.</h3>
          <div class="form-group">
            <label>Senha</label>
            <input type="password" class="form-control" [(ngModel)]="dados.senha" [class.is-invalid]="senha.invalid && senha.touched" name="senha" #senha minlength="6" required>
            <small class="text-danger" [class.d-none]="senha.valid || senha.untouched">Senha precisa conter no mínimo 6 caracteres</small>
          </div>
          <div class="form-group">
            <label>Confirmação de Senha</label>
            <input type="password" class="form-control" [(ngModel)]="dados.confirmacaoSenha" [class.is-invalid]="senha.invalid && senha.touched" name="senha" #senha minlength="6" required>
            <small class="text-danger" [class.d-none]="senha.valid || senha.untouched">Senha precisa conter no mínimo 6 caracteres</small>
          </div>
          <div class="form-group">
            <button class="btn btn-success l-0" (click)="alterarSenha()" [disabled]="login.form.invalid">
              <span *ngIf="alterandoSenha" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              Alterar Senha
            </button>
          </div>
        </form>
      </div>
      <div class="card-footer text-center text-xs">
        <a href="#" class="text-center" [routerLink]='["/sign-in"]' style="font-size:14px">Voltar</a>
      </div>
    </div>
  </div>
</div>
