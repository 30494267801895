import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ComponenteService {
  constructor(private http: HttpClient) { }

  baseUrl = "api/Componente/"

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8'
    })
  }

  public cadastrarComponente(dto) {
    return this.http.post<any>(this.baseUrl + "CadastrarComponente", JSON.stringify(dto), this.httpOptions);
  }

  public inativarComponente(componenteId) {
    return this.http.get<any>(this.baseUrl + "InativarComponente?componenteId=" + componenteId, this.httpOptions);
  }

  public obterComponentePorId(componenteId, idGrao) {
    return this.http.get<any>(this.baseUrl + "ObterComponentePorId?componenteId=" + componenteId + "&idGrao=" + idGrao, this.httpOptions);
  }

  public obterListaComponente(filtro) {
    return this.http.post<any>(this.baseUrl + "ObterListaComponente", JSON.stringify(filtro),this.httpOptions);
  }

  public editarComponente(dto) {
    return this.http.post<any>(this.baseUrl + "EditarComponente", JSON.stringify(dto), this.httpOptions);
  }

  public obterComponentePorPor(dolar, idGrao, dolarVenda?, data?) {

    var dV = dolarVenda == undefined ? 0 : dolarVenda == null ? 0 : dolarVenda;

    return this.http.get<any>(this.baseUrl + "ObterComponentePor?dolar=" + dolar + "&dolarVenda=" + dV + "&idGrao=" + idGrao + "&ano=" + data, this.httpOptions);
  }

  public obterComponentesPorUsuarioLogadoEVinculos(idGrao, idPorto) {
    return this.http.get<any>(this.baseUrl + `ObterComponentesPorUsuarioLogadoEVinculos?idGrao=${idGrao}&idPorto=${idPorto}`, this.httpOptions);
  }
    
}
