import { LoginService } from '../../services/loginService';
import { EstadoService } from '../../services/estadoService';
import { ToastService } from '../../services/toastService';
import { ServicoCBotService } from '../../services/servicoCBoatService';
import { TipoArmazemService } from '../../services/tipoArmazemService';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { AuthorizationService } from '../../services/authorizationService';
import { SolicitarVinculoService } from '../../services/solicitarVinculoService';
import moment from 'moment';
import { Component, ElementRef, NgZone, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MapMarker } from '@angular/google-maps';
@Component({
  selector: 'app-editarUsuario',
  templateUrl: './editarUsuario.html',

})


export class EditarUsuario {

  modalOptions: NgbModalOptions;
  idUsuario: number;
  formulario = 1;

  usuarioEdicao: Usuario;
  telefone: Telefone;
  armazem: Armazem;
  propriedade: Propriedade;
  ehEdicao: boolean;
  ehAdministrador: boolean;
  public maskCelular = ['(', /\d/, /\d/, ')',  /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  public maskFixo = ['(', /\d/, /\d/, ')',  /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  cnpjMask = [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/,];
  listaPropriedades: Array<Propriedade> = [];
  listaEstado: Array<DtoEstado>;
  listaTiposArmazem: Array<TipoArmazem>;
  listaTipoUsuario: Array<IDtoTipoUsuario>;
  listaServicosCBot: Array<DtoServicoCBot>;
  listaPerfisSelecionados: any[];
  dropdownList = [];
  dropdownSettings = {
    singleSelection: false,
    idField: 'idServicoCBot',
    textField: 'nome',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true
  };
  dropdownTipoUsuariolList = [];
  dropdownTipoUsuarioSettings = {
    singleSelection: true,
    idField: 'id',
    textField: 'descricao',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 1,
    allowSearchFilter: true
  };

  dropdownEstadosSettings = {
    singleSelection: false,
    idField: 'idEstado',
    textField: 'nomeEstado',
    selectAllText: 'Todos',
    unSelectAllText: 'Nenhum',
    itemsShowLimit: 3,
    allowSearchFilter: true
  };
  latitude: number;
  longitude: number;
  zoom: number = 15;
  address: string;
  private geoCoder;
  @ViewChild("search", { static: true })
  public searchElementRef: ElementRef<any>;
  numSolicitacaoVinculos: number;
  center: google.maps.LatLngLiteral;
  marker: google.maps.LatLngLiteral;
  markerPositions: google.maps.LatLngLiteral[] = [];
  loading = false;
  @ViewChild(MapMarker, { static: false }) markerElem: MapMarker
  constructor(private usuarioService: LoginService,
    private authorizationService: AuthorizationService,
    private route: ActivatedRoute,
    private toastService: ToastService,
    private router: Router,
    private servicoCBotService: ServicoCBotService,
    private estadoService: EstadoService,
    private tipoArmazemService: TipoArmazemService,
    private ngZone: NgZone,
    private vinculoService: SolicitarVinculoService
  ) {
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.usuarioEdicao = new Usuario;
      this.telefone = new Telefone;
      this.armazem = new Armazem;
      this.propriedade = new Propriedade();
      this.verificarPerfil();

      this.obterTodosEstados();
      this.obterServicosCBot();
      this.obterTipoUsuario();
      this.obterTodosOsTiposArmazem();
    
      this.loadMap();
      if (params.has("id")) {
        this.ehEdicao = true;
        this.idUsuario = +params.get("id");
        this.obterUsuarioPorId(this.idUsuario);
      }
      this.numSolicitacoesVinculosRequest();
    });
  }

  public loadMap() {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder;

      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.centralizarMapaNa(this.latitude, this.longitude);
          this.incluirMarker();
          this.propriedade.latitude = this.latitude;
          this.propriedade.longitude = this.longitude;
          this.propriedade.nome = place.name;

        });
      });
  }

  private incluirMarker() {
    this.marker = this.center;
  }

  private removerMarker() {
    this.markerElem.marker.setMap(null);
    this.marker = null;
  }

  private centralizarMapaNa(latitude, longitude) {
    this.center = {
      lat: this.latitude,
      lng: this.longitude
    }
  }

  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.centralizarMapaNa(this.latitude, this.longitude);
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.address = results[0].formatted_address;
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }

    });
  }

  atualizarForm(posicao) {
    this.formulario = posicao;

    if (posicao == 5) {
      this.loadMap();
    }
  }

  verificarPerfil() {
    this.ehAdministrador = this.authorizationService.ehAdministrador();

    if (!this.ehAdministrador) {
      this.formulario = 2;
    }

  }


  atualizarUsuario() {
    if (this.validarUsuario()) {

      this.usuarioEdicao.tipoUsuario = this.listaPerfisSelecionados[0];

      this.usuarioService.editarUsuario(this.usuarioEdicao).subscribe(response => {
        if (response.codigo === 200) {
          this.toastService.show('Usuário editado com sucesso!', { classname: 'bg-success text-light', delay: 5000 });
          this.router.navigate(['/usuario/listagem']);

        }
        else
          this.toastService.show(response.mensagem, { classname: 'bg-danger text-light', delay: 5000 })
      });
    }
  }

  validarUsuario(): boolean {
    if (this.usuarioEdicao.nome == "") {
      this.toastService.show("Preencha o nome do usuário", { classname: 'bg-warning text-light', delay: 5000 })
      return false;
    }
    if (this.usuarioEdicao.email == "") {
      this.toastService.show("Preencha o e-mail do usuário", { classname: 'bg-warning text-light', delay: 5000 })
      return false;
    }
    if (this.usuarioEdicao.senha == "" && !this.ehEdicao) {
      this.toastService.show("Preencha a senha do usuário", { classname: 'bg-warning text-light', delay: 5000 })
      return false;
    }

    if (this.usuarioEdicao.empresa != null) {
      const usuario = this.usuarioEdicao.empresa;
      if (usuario.grupoEmpresarial.trim() == "") {
        this.toastService.show("Por favor, preencha o Grupo Empresarial", { classname: 'bg-warning text-light', delay: 5000 })
        return false;
      }

      if (usuario.cnpj.trim() == "") {
        this.toastService.show("Por favor, preencha o CNPJ", { classname: 'bg-warning text-light', delay: 5000 })
        return false;
      }

      if (usuario.razaoSocial.trim() == "") {
        this.toastService.show("Por favor, preencha a Razão Social", { classname: 'bg-warning text-light', delay: 5000 })
        return false;
      }

      if (usuario.nomeFantasia.trim() == "") {
        this.toastService.show("Por favor, preencha o Nome Fantasia", { classname: 'bg-warning text-light', delay: 5000 })
        return false;
      }

      if (usuario.inscricaoEstadual.trim() == "") {
        this.toastService.show("Por favor, preencha a Inscrição Estadual", { classname: 'bg-warning text-light', delay: 5000 })
        return false;
      }

      if (usuario.endereco.trim() == "") {
        this.toastService.show("Por favor, preencha o Endereço", { classname: 'bg-warning text-light', delay: 5000 })
        return false;
      }
      if (usuario.telefones.length == 0) {
        this.toastService.show("Preencha um telefone", { classname: 'bg-warning text-light', delay: 5000 })
        return false;
      }
    }
    return true;
  }



  cadastrarUsuario() {

    if (this.validarUsuario()) {

      this.usuarioEdicao.tipoUsuario = this.listaPerfisSelecionados[0];

      this.usuarioService.cadastrarUsuario(this.usuarioEdicao).subscribe(response => {
        if (response.codigo === 200) {
          this.toastService.show('Usuário cadastrado com sucesso!', { classname: 'bg-success text-light', delay: 5000 })
          this.limparForumario();
          this.router.navigate(['/usuario/listagem']);
        }
        else {
          this.toastService.show(response.mensagem, { classname: 'bg-danger text-light', delay: 5000 })
        }
      });
    }
  }

  limparForumario() {
    this.usuarioEdicao.nome = '';
    this.usuarioEdicao.email = '';
    this.usuarioEdicao.ativo = false;
    this.usuarioEdicao.senha = '';
  }

  obterUsuarioPorId(id: number) {
    this.loading = true;
    this.usuarioService.obterUsuarioPorId(id).subscribe(response => {
      this.loading = false;
      this.usuarioEdicao = response;

      this.listaPerfisSelecionados = [];
      this.listaPerfisSelecionados.push(response.tipoUsuario);
    });
  }

  obterTodosEstados() {
    this.estadoService.obterTodos().subscribe(response => {
      this.listaEstado = response;
    })
  }

  obterTodosOsTiposArmazem() {
    this.tipoArmazemService.obterTodos().subscribe(response => {
      this.listaTiposArmazem = response;
    });
  }

  obterTipoUsuario() {
    this.servicoCBotService.getAllTipoUsuario().subscribe(response => {
      this.listaTipoUsuario = response;
      this.dropdownTipoUsuariolList = this.listaTipoUsuario;
     
    })
  }

  obterServicosCBot() {
    this.servicoCBotService.obterTodos().subscribe(response => {
      this.listaServicosCBot = response.result
      this.dropdownList = this.listaServicosCBot;
     
    });

  }


  onItemSelect(item: any) {
    console.log(item);
  }
  onSelectAll(items: any) {
    console.log(items);
  }


  validarTelefone(): boolean {
    if (this.telefone.tipoTelefone == null) {
      this.toastService.show("Preencha o tipo de telefone", { classname: 'bg-warning text-light', delay: 5000 });
      return false;
    }

    if (this.telefone.numero == null || this.telefone.numero.trim() == "" ||
      (this.telefone.tipoTelefone == 0 && this.telefone.numero.length < 13) ||
      (this.telefone.tipoTelefone == 1 && this.telefone.numero.length < 14)) {
      this.toastService.show("Preencha o número do telefone", { classname: 'bg-warning text-light', delay: 5000 });
      return false;
    }


    return true;
  }

  adicionarTelefone() {

    if (!this.validarTelefone()) return;
    var novoTelefone = new Telefone();
    novoTelefone.tipoTelefone = this.telefone.tipoTelefone;
    novoTelefone.numero = this.telefone.numero;
    this.usuarioEdicao.empresa.telefones.push(novoTelefone);
    this.telefone = new Telefone();
  }

  removerTelefone(telefone) {

    const index = this.usuarioEdicao.empresa.telefones.indexOf(telefone);

    if (index !== -1)
      this.usuarioEdicao.empresa.telefones.splice(index, 1);
  }

  validarArmazem(): boolean {
    if (this.armazem.numCDA == null) {
      this.toastService.show("Preencha o número do CDA", { classname: 'bg-warning text-light', delay: 5000 });
      return false;
    }

    if (this.armazem.idTipoArmazem == null) {
      this.toastService.show("Preencha o tipo do armazem", { classname: 'bg-warning text-light', delay: 5000 });
      return false;
    }

    return true;
  }


  adicionarArmazem() {
    if (!this.validarArmazem()) return;

    const index = this.usuarioEdicao.empresa.armazens.indexOf(this.armazem);

    var tipoArmazem = this.listaTiposArmazem.filter(r => r.idTipoArmazem == this.armazem.idTipoArmazem);

    if (tipoArmazem != null)
      this.armazem.tipo = tipoArmazem[0].tipo;

    if (index == -1)
      this.usuarioEdicao.empresa.armazens.push(this.armazem);
    this.armazem = new Armazem;
  }

  editarArmazem(armazem: Armazem) {
    this.armazem = armazem;
  }

  removerArmazem(armazem: Armazem) {
    const index = this.usuarioEdicao.empresa.armazens.indexOf(armazem);
    if (index !== -1)
      this.usuarioEdicao.empresa.armazens.splice(index, 1);
  }


  abrirLocationNoGoogle(propriedade: Propriedade) {
    let navigator: any;
    navigator = window.navigator;
    var ua = navigator.userAgent;
    var isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua);
    if (navigator.share && isMobile) {
      navigator.share({
        title: propriedade.nome,
        url: 'http://maps.google.com/?q=' + propriedade.latitude + ',' + propriedade.longitude
      })
        .then(() => console.log('Share complete'))
        .catch((error) => console.log('Error sharing', error));
    }
    else
      window.open("//" + "maps.google.com/?q=" + propriedade.latitude + "," + propriedade.longitude, '_blank');
  }

  removerPropriedade(propriedade: Propriedade) {
    const index = this.usuarioEdicao.empresa.propriedades.indexOf(propriedade);
    if (index !== -1)
      this.usuarioEdicao.empresa.propriedades.splice(index, 1);
  }


  validarEndereco(): boolean {
    if (this.propriedade.nome == null || this.propriedade.nome.trim() == "") {
      this.toastService.show("Preencha o nome do endereço", { classname: 'bg-warning text-light', delay: 5000 })
      return false;
    }

    return true;
  }


  incluirPropriedade() {
    if (!this.validarEndereco()) return;

    this.usuarioEdicao.empresa.propriedades.push(this.propriedade);
    this.propriedade = new Propriedade;
    this.searchElementRef.nativeElement.value = "";
    this.removerMarker();
  }

  responderVinculo(solicitacao, situacao) {
    var data = moment().format('YYYY-MM-DDTHH:mm');
    this.vinculoService.responderVinculo(new DtoResponderVinculo(solicitacao.idSolicitarVinculo, data, situacao)).subscribe(res => {
      if (res.codigo != 200) { this.toastService.show(res.mensagem, { classname: 'bg-warning text-light', delay: 5000 }); return; }
      solicitacao.descSituacao = res.descSituacao;
      solicitacao.situacao = res.situacao;
      solicitacao.dtResposta = res.dtResposta;
      this.toastService.show('Solicitação alterada com sucesso', { classname: 'bg-success text-light', delay: 5000 });
      this.numSolicitacoesVinculosRequest();
    });
  }

  numSolicitacoesVinculosRequest() {
   
    this.vinculoService.obterQtdSolicitacoes(this.idUsuario).subscribe(res => {
      this.numSolicitacaoVinculos = res;
    });
  }

  compartilharCodigo() {
    let navigator: any;
    navigator = window.navigator;
    var ua = navigator.userAgent;
    if (navigator.share) {
      navigator.share({
        title: "O código do meu grupo",
        text: "Acesse o Agrobric em perfil va em vinculo e solicite vínculo ao meu grupo, código " + this.usuarioEdicao.empresa.id
      })
        .then(() => console.log('Share complete'))
        .catch((error) => console.log('Error sharing', error));
    }
  }

}

class Usuario {
  nome: string;
  ativo: boolean;
  email: string;
  senha: string;
  estadosDeAcesso: Array<DtoEstado>;
  listaServicosCBot: [];
  listaPerfil: [];
  linguagem: number;
  tipoUsuario: any[];
  empresa: Empresa;
}

class Telefone {
  numero: string;
  tipoTelefone: number = 0;
}

class Empresa {
  id: number;
  nomeFantasia: string;
  inscricaoEstadual: string;
  cnpj: string;
  razaoSocial: string;
  endereco: string;
  usuarioId: number;
  grupoEmpresarial: string;
  telefones: Telefone[] = [];
  armazens: Armazem[] = [];
  propriedades: Propriedade[] = [];
  periodoChuvas: PeriodoChuva[] = [];
}


class Armazem {
  idArmazem: number;
  proprio: boolean;
  numCDA: string;
  recebimentoTonHora: number;
  expedicaoTonHora: number;
  tulhaCoberta: boolean;
  metragemBalanca: number;
  idTipoArmazem: number;
  tipo: string;
}

class TipoArmazem {
  idTipoArmazem: number;
  tipo: string;
}

class Propriedade {
  idPropriedade: number;
  latitude: number;
  longitude: number;
  nome: string;
}

class DtoServicoCBot {
  idServicoCBot: number;
  nome: string;
}

interface IDtoTipoUsuario {
  id: number;
  descricao: string;
}

class PeriodoChuva {
  idPeriodoChuva: number;
  mesDesc: string;
  primeiraQuinzena: boolean;
  segundaQuinzena: boolean;
}

class DtoResponderVinculo {
  idSolicitarVinculo: number;
  dtResposta: any;
  situacao: number

  constructor(idSolicitarVinculo, dtResposta, situacao) {
    this.idSolicitarVinculo = idSolicitarVinculo;
    this.dtResposta = dtResposta;
    this.situacao = situacao;
  }
}
