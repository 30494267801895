import { BehaviorSubject,  } from 'rxjs';
import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class Globals {
  logado: boolean = false;
  cotacaoDolar: any = {};
  nomeUsuarioAutenticado: string = "";
  estadosDeAcesso: Array<DtoEstado>;
  ambienteSelecionado: DtoEstado;
 
  public customVariable = new BehaviorSubject<any>({});
  constructor() {
    this.logado = false;
    
  }

}
