import { AlterarSenha } from "./alterarSenha/alterarSenha";
import { AuthGuard } from "./auth/auth.guard";
import { AvisoAguardarAprocaoComponent } from "./aviso-aguardar-aprocao/aviso-aguardar-aprocao.component";
import { TabelaCBoat } from "./cBoat/TabelaCBoat/tabelaCBoat";
import { ListagemComponente } from "./componente/listagemComponente/listagemComponente"
import { Componentes } from "./componente/componentes/componentes";
import { ListagemPorto } from "./componente/listagemPorto/listagemPorto";
import { Configuracao } from "./configuracao/configuracao";
import { CadastrarCurvaCambio } from "./curvaCambio/cadastrarCurvaCambio/CadastrarCurvaCambio";
import { ListagemCurvaCambio } from "./curvaCambio/listagemCurvaCambio/listagemCurvaCambio";
import { PerfilEnum } from "./enums/PerfilEnum";
import { ListagemEstado } from "./estado/listagemEstado/listagemEstado";
import { Fretes } from "./frete/fretes/fretes";
import { ListagemPraca } from "./frete/listagemPraca/listagemPraca";
import { ListagemIncoterm } from "./incoterm/listagemIncoterm/listagemIncoterm";
import { Ambientes } from "./login/ambientes";
import { Login } from "./login/login";
import { BookOfertas } from "./precificacao/bookOfertas/bookOfertas";
import { PrecificacaoMilho } from "./precificacao/milho/precificacao-milho";
import { Precificacao } from "./precificacao/soja/cadastroPrecificacao/precificacao";
import { ListagemPrecificacao } from "./precificacao/soja/listagemPrecificacao/listagemPrecificacao";
import { ListagemPremio } from "./premio/listagemPremio/listagemPremio";
import { RecuperarSenha } from "./recuperarSenha/recuperarSenha";
import { SignUpComponent } from "./signup/signUp.component";
import { EditarUsuario } from "./usuario/editar/editarUsuario";
import { ListagemUsuario } from "./usuario/listagemUsuario/listagemUsuario";
import { CriarVinculo } from "./vinculo/criar/criar";
import { Routes } from "@angular/router";
import { EditarboletaComponent } from "./precificacao/boleta/editarboleta/editarboleta.component";
type PathMatch = 'full' | 'prefix' | undefined;
export const routes : Routes = 
  [
    //CURVA DE CÂMBIO
    { path: '', component: ListagemCurvaCambio, pathMatch: 'full' as PathMatch, canActivate: [AuthGuard], data: { roles: [PerfilEnum.PRECIFICAÇÃO, PerfilEnum.OPERADOR, PerfilEnum.BOOKDEOFERTAS, PerfilEnum.CURVACAMBIO, PerfilEnum.FRETE,] } },
    { path: 'cadastrarCurvaCambio', component: CadastrarCurvaCambio, canActivate: [AuthGuard], data: { roles: [PerfilEnum.CURVACAMBIO] } },
    { path: 'editarCurvaCambio/:id', component: CadastrarCurvaCambio, canActivate: [AuthGuard], data: { roles: [PerfilEnum.CURVACAMBIO] } },

    //FRETES
    { path: 'fretes/:id', component: Fretes, canActivate: [AuthGuard], data: { roles: [PerfilEnum.FRETE] } },
    { path: 'listagemPraca', component: ListagemPraca, canActivate: [AuthGuard], data: { roles: [PerfilEnum.FRETE] } },

    //PRÊMIO
    { path: 'listagemPremio', component: ListagemPremio, canActivate: [AuthGuard], data: { roles: [PerfilEnum.PREMIO] } },

    //PRECIFICACAO
    { path: 'cBot', component: TabelaCBoat, canActivate: [AuthGuard], data: { roles: [PerfilEnum.CBOT] } },
    { path: 'precificacao/milho', component: PrecificacaoMilho, canActivate: [AuthGuard], data: { roles: [PerfilEnum.PRECIFICAÇÃO] } },
    { path: 'precificacao', component: Precificacao, canActivate: [AuthGuard], data: { roles: [PerfilEnum.PRECIFICAÇÃO] } },

    //PORTOS
    { path: 'listagemPorto', component: ListagemPorto, canActivate: [AuthGuard], data: { roles: [PerfilEnum.PORTOS] } },

    //COMPONENTE
    { path: 'componentes', component: ListagemComponente, canActivate: [AuthGuard], data: { roles: [PerfilEnum.COMPONENTE] } },
    { path: 'componenteItem/:id', component: Componentes, canActivate: [AuthGuard], data: { roles: [PerfilEnum.COMPONENTE] } },

    //OPERADOR
    { path: 'usuario/listagem', component: ListagemUsuario, canActivate: [AuthGuard], data: { roles: [PerfilEnum.OPERADOR] } },
    { path: 'usuario/editar/:id', component: EditarUsuario, canActivate: [AuthGuard], data: { roles: [PerfilEnum.PRECIFICAÇÃO, PerfilEnum.OPERADOR, PerfilEnum.BOOKDEOFERTAS, PerfilEnum.CURVACAMBIO, PerfilEnum.FRETE,] } },
    { path: 'usuario/criar', component: EditarUsuario, canActivate: [AuthGuard], data: { roles: [PerfilEnum.OPERADOR] } },
    { path: 'listagemEstado', component: ListagemEstado, canActivate: [AuthGuard], data: { roles: [PerfilEnum.OPERADOR] } },
    { path: 'config', component: Configuracao, canActivate: [AuthGuard], data: { roles: [PerfilEnum.OPERADOR] } },

    //BOOK DE OFERTAS
    { path: 'NegocioConcluido', component: ListagemPrecificacao, canActivate: [AuthGuard], data: { roles: [PerfilEnum.BOOKDEOFERTAS] } },
    { path: 'bookOfertas', component: BookOfertas, canActivate: [AuthGuard], data: { roles: [PerfilEnum.BOOKDEOFERTAS] } },
    { path: 'bookOfertas/:expandir', component: BookOfertas, canActivate: [AuthGuard], data: { roles: [PerfilEnum.BOOKDEOFERTAS] } },

    //VINCULO
    { path: 'vinculo/criar/:id', component: CriarVinculo, canActivate: [AuthGuard], data: { roles: [PerfilEnum.BOOKDEOFERTAS] } },

    //OUTROS
    { path: 'sign-in', component: Login },
    { path: 'sign-up', component: SignUpComponent },
    { path: 'ambientes', component: Ambientes },
    { path: 'incoterm', component: ListagemIncoterm, canActivate: [AuthGuard] },
    { path: 'recuperarSenha', component: RecuperarSenha },
    { path: 'alterarSenha/:token', component: AlterarSenha },

    { path: 'aguardandoAprovacao', component: AvisoAguardarAprocaoComponent },

    { path: 'editarBoleta/:negocioId', component: EditarboletaComponent }

  ]
