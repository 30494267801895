import { Component } from '@angular/core';
import { CurvaCambioService } from '../../services/CurvaCambioService';
import { CotacaoService } from '../../services/cotacaoService';
import { ToastService } from '../../services/toastService';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-cadastrarCurvaCambio',
  templateUrl: './cadastrarCurvaCambio.html',
})
export class CadastrarCurvaCambio {

  constructor(private curvaCambioService: CurvaCambioService, private cotacaoService: CotacaoService,
    private toastService: ToastService, private route: ActivatedRoute,
    private router: Router) { }

  cotacao: any = {};
  curvaCambio: any = {
    valorCompra: 0,
    valorVenda: 0,
    dataCurva: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()).toISOString().split('T')[0]
  };

  listaCurva: object[] = [];
  id: number = 0;
  editando: boolean = false;

  cadastrarCurvaCambio() {
    if (this.editando === false)
      this.curvaCambioService.cadastrarCambioService(this.curvaCambio).subscribe(response => {
        if (response.codigo === 200) {
          this.toastService.show('Curva de Cambio cadastrado com sucesso!', { classname: 'bg-success text-light', delay: 5000 })
          let temp = { DataCurva: this.curvaCambio.dataCurva, ValorVenda: this.curvaCambio.margemValorVenda + this.curvaCambio.valorVendaHoje, ValorCompra: this.curvaCambio.margemValorCompra + this.curvaCambio.valorCompraHoje };
          this.listaCurva.push(temp);
        }
        else {
          this.toastService.show(response.mensagem, { classname: 'bg-danger text-light', delay: 5000 })
        }

      });
    else {
      this.curvaCambio.valorCompra = this.curvaCambio.valorCompraHoje;
      this.curvaCambio.valorVenda = this.curvaCambio.valorVendaHoje;
      this.curvaCambioService.editarCurvaCambio(this.curvaCambio).toPromise().then(response => {
        if (response.codigo === 200) {
          this.toastService.show('Curva de Cambio editado com sucesso!', { classname: 'bg-success text-light', delay: 5000 })
          this.router.navigate(['/']);
        }
        else {
          this.toastService.show(response.mensagem, { classname: 'bg-danger text-light', delay: 5000 })
        }
      });
    }

  }

  async ngOnInit() {
    await this.cotacaoService.obterCotacaoDolarDeHoje().subscribe(res => {
      var retApi = res.USDBRL;
      if (retApi != null) {
        this.cotacao = { cotacaoCompra: Number(retApi.bid), cotacaoVenda: Number(retApi.ask), dataHoraCotacao: retApi.create_date };
        this.curvaCambio.dataHoraCotacaoHoje = new Date(Date.parse(this.cotacao.dataHoraCotacao)).toISOString().split(' ')[0];
        this.curvaCambio.valorCompraHoje = this.cotacao.cotacaoCompra;
        this.curvaCambio.valorVendaHoje = this.cotacao.cotacaoVenda;
      }
    });
    this.route.paramMap.subscribe(params => {
      this.id = +params.get("id");
    });

    if (this.id > 0) {
      this.editando = true;
      this.curvaCambioService.obterCurvaCambioPorId(this.id).subscribe(response => {
        this.curvaCambio = response.result;

        this.curvaCambio.valorCompraHoje = this.cotacao.cotacaoCompra;
        this.curvaCambio.valorVendaHoje = this.cotacao.cotacaoVenda;
        this.curvaCambio.dataHoraCotacaoHoje = new Date(Date.parse(this.cotacao.dataHoraCotacao)).toISOString().split('T')[0];

        let date = new Date(Date.parse(this.curvaCambio.dataCurva));
        this.curvaCambio.dataHoraCotacao = new Date(date.getFullYear(), date.getMonth(), date.getDate()).toISOString().split('T')[0]

        let dateAtt = new Date(Date.parse(this.curvaCambio.dataAtualizacao));
        this.curvaCambio.dataAtualizacao = new Date(dateAtt.getFullYear(), dateAtt.getMonth(), dateAtt.getDate()).toISOString().split('T')[0]

        let dataCurva = new Date(Date.parse(this.curvaCambio.dataCurva));
        this.curvaCambio.dataCurva = new Date(dataCurva.getFullYear(), dataCurva.getMonth(), dataCurva.getDate()).toISOString().split('T')[0]

      });
    }
  }
}
