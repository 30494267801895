
<main>
  <nb-card>
    <nb-card-header>
      <div class="row">
        <h3>CBOT</h3>
        <div class="col-8">
          <p>
            CME Group is the world's leading derivatives marketplace. The company is. comprised of four Designated Contract Markets (DCMs).
          </p>
          <p>
            Further information on each exchange's rules and product listings can be found by clicking on the links to CME, CBOT, NYMEX and COMEX. (<a target="_blank" href="https://www.cmegroup.com">CMEGROUP</a>)
          </p>
        </div>
      </div>
    
      
    </nb-card-header>
    <nb-card-body>
        <ul class="nav nav-tabs">
          <li class="nav-item" *ngFor="let servico of servicosDoUsuarioLogado">
            <span class="nav-link {{graoSelecionado == servico.idProduto ? 'active' : ''}}" (click)="selecionarGrao(servico.idProduto)">{{servico.produtoNome}}</span>
          </li>
        </ul>
        <div class="fixTableHead table-gp">
          <header>
            <span>{{'ultimaAtualizacao'| translate}}: {{ultimaAtualizacao | date:'dd/MM/yyyy HH:mm:ss:SSS'}}</span>
          </header>
          <table class="table table-striped">
            <thead class="thead-dark">
              <tr>
                <th>{{'tabela.cbot.mes'|translate}}</th>
                <th>{{'tabela.cbot.ultimo'|translate}}</th>
                <th>{{'tabela.cbot.mudanca'|translate}}</th>
                <th>{{'tabela.cbot.liquidacaoAnterior'|translate}}</th>
                <th>{{'tabela.cbot.aberto'|translate}}</th>
                <th>{{'tabela.cbot.alta'|translate}}</th>
                <th>{{'tabela.cbot.baixa'|translate}}</th>
                <th>{{'tabela.cbot.volume'|translate}}</th>
                <th>{{'tabela.cbot.limiteAltaBaixa'|translate}}</th>
                <th>{{'tabela.cbot.atualizacao'|translate}}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let mes of cBoat.quotes">
                <td>{{mes.expirationMonth}}</td>
                <td>{{mes.last}}</td>
                <td>{{mes.change}}</td>
                <td>{{mes.priorSettle}}</td>
                <td>{{mes.open}}</td>
                <td>{{mes.high}}</td>
                <td>{{mes.low}}</td>
                <td>{{mes.volume}}</td>
                <td>{{mes.highLowLimits}}</td>
                <td>{{mes.updated.split('<')[0]}}<br /> {{mes.updated.split('>')[1]}}</td>
              </tr>
            </tbody>
          </table>
        </div>

    </nb-card-body>
  </nb-card>
</main>
