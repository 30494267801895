import { Component } from '@angular/core';
import { ModalDismissReasons, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { IncotermService } from '../../services/incotermService';
import { ToastService } from '../../services/toastService';


@Component({
    selector: 'app-listagemIncoterm',
    templateUrl: './listagemIncoterm.html',
    styleUrls:['./listagemIncoterm.css']
})
export class ListagemIncoterm {
    modalOptions: NgbModalOptions;
    title = 'ng-bootstrap-modal-demo';
    closeResult: string;
    resposta: object = {};
    listaIncoterm: any[] = [];
    incotermSelecionado: any = {};
    novoIncoterm: any = {};
    filtro: any = {
        tamanhoPagina: 10,
        numeroPagina: 1,
    };

    constructor(private incotermService: IncotermService,
        private toastService: ToastService, private modalService: NgbModal) {
        this.modalOptions = {
            size: 'sm'
        }
    }

    cadastrarIncoterm(nome, desagio) {
        var valido = true;
        if (nome == '' || nome == undefined) {
            this.toastService.show("Nome Incoterm obrigatório", { classname: 'bg-danger text-light', delay: 5000 })
            valido = false;
        }
        if (desagio == '' || desagio == undefined) {
            this.toastService.show("Desagio obrigatória", { classname: 'bg-danger text-light', delay: 5000 })
            valido = false;
        }
        if (valido) {
            var dto = {
                nome: nome,
                desagio: desagio,
            }
            this.incotermService.cadastrarIncoterm(dto).subscribe(response => {
                if (response.codigo === 200) {
                    this.toastService.show('Incoterm cadastrado com sucesso!', { classname: 'bg-success text-light', delay: 5000 })
                    this.listaIncoterm.push(response.result);
                }
                else {
                    this.toastService.show(response.mensagem, { classname: 'bg-danger text-light', delay: 5000 })
                }
                this.modalService.dismissAll();

            })
        }

    }

    obterListaIncoterm(pagina) {
        this.filtro.pagina = pagina;
        this.incotermService.obterListaIncoterm(this.filtro).subscribe(response => {
            if (response.codigo === 200) {
                this.listaIncoterm = response.result.retorno;
                this.filtro.total = response.result.qtdTotal
            }
            else
                this.toastService.show(response.mensagem, { classname: 'bg-danger text-light', delay: 5000 })
        })
    }

    selecionarIncoterm(id) {
        this.incotermSelecionado = this.listaIncoterm.find(incoterm => {
            return incoterm.incotermId === id;
        })
    }

    editarIncoterm(incoterm) {
        this.incotermService.editarIncoterm(incoterm).subscribe(response => {
            if(response.codigo === 200)
                this.toastService.show('Incoterm editado com sucesso!', { classname: 'bg-success text-light', delay: 5000 })
            else {
                this.toastService.show(response.mensagem, { classname: 'bg-danger text-light', delay: 5000 })
            }
        })
    }

    inativarIncoterm() {
        this.incotermService.inativarIncoterm(this.incotermSelecionado).subscribe(response => {
            if (response.codigo === 200) {
                this.toastService.show('Incoterm inativado com sucesso!', { classname: 'bg-success text-light', delay: 5000 })
                this.listaIncoterm = this.listaIncoterm.filter(incoterm => {
                    if (incoterm.incotermId !== this.incotermSelecionado.incotermId)
                        return incoterm;
                })
            }
            else {
                this.toastService.show(response.mensagem, { classname: 'bg-danger text-light', delay: 5000 })
            }
            this.modalService.dismissAll();

        })
    }



    open(content) {
        this.modalService.open(content).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }


    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    ngOnInit() {
        this.obterListaIncoterm(1);
    }
}
