<main>
  <div class="breadcrumb">
    <h3>{{'Menus.Componente'|translate}}</h3>
  </div>
  <div class="card">
    <div class="d-flex justify-content-center mt-3">
      <h3>{{edicaoComponente.nome}} {{edicaoComponente.portoNome}}</h3>
    </div>
    <div class="card-body">
      <form #componenteForm="ngForm" novalidate>
        <div class="d-flex flex-wrap justify-content-center align-items-center">
          <div class="form-group col-2 pl-0">
            <label>{{'componente.grao'|translate}}</label>
            <select class="form-control" [(ngModel)]="filtro.grao" name="grao" (change)="obterComponenteItemPorGrao()">
              <option *ngFor="let grao of listaGraos" [ngValue]="grao.idProduto">
                {{grao.nomeProduto}}
              </option>
            </select>
          </div>
          <div class="form-group col-2 pl-0">
            <label>{{'componente.ano'|translate}}</label>
            <select class="form-control" name="ano" [(ngModel)]="novoComponente.ano">
              <option *ngFor="let ano of anos" [ngValue]="ano">
                {{ano}}
              </option>
            </select>
          </div>
          <div class="form-group mr-md-3 mr-sm-2">
            <label>{{'Menus.Componente'|translate}}</label>
            <input type="text" class="form-control" [(ngModel)]="novoComponente.nome" [ngbTypeahead]="search" [class.is-invalid]="nome.invalid && nome.touched" name="nome" #nome placeholder="Digite para pesquisar" required>
          </div>
          <div class="form-group mr-md-2 mr-sm-2">
            <label>{{'componente.custo'|translate}}</label>
            <input type="number" class="form-control" [(ngModel)]="novoComponente.custo" [class.is-invalid]="custo.invalid && custo.touched" name="custo" #custo required>
          </div>
          <div class="form-group mr-md-3 mr-sm-2">
            <label>{{'componente.unidade'|translate}}</label>
            <select class="form-control" [(ngModel)]="novoComponente.moeda" [class.is-invalid]="moeda.invalid && moeda.touched" name="moeda" #moeda required>
              <option *ngFor="let moeda of listaMoeda" value="{{moeda.moedaId}}">{{moeda.nome}}</option>
            </select>
          </div>
          <div class="form-group mr-md-3 mr-sm-2">
            <label>{{'componente.unidadeMedida'|translate}}</label>
            <select class="form-control" [(ngModel)]="novoComponente.unidade" [class.is-invalid]="unidade.invalid && unidade.touched" name="unidade" #unidade required>
              <option *ngFor="let unidade of listaUnidade" value="{{unidade.unidadeId}}">{{unidade.nome}}</option>
            </select>
          </div>
          <div class="form-group mr-md-3 mr-sm-2">
            <label>{{'componente.calculoRetencao'|translate}}</label>
            <select class="form-control" [(ngModel)]="novoComponente.calculo" name="calculo" #calculo title="{{ ehAdministrador ? mostrarDescComponenteSelecionado(novoComponente.calculo): ''}}">
              <option *ngFor="let calc of listaCalculos" value="{{calc.id}}">
                {{calc.calculo}}
              </option>
            </select>

          </div>
          <div class="d-flex align-self-end mb-3">
            <button class="btn btn-success" [disabled]="componenteForm.form.invalid" (click)="cadastrarComponente(novoComponente.ano,novoComponente.nome,novoComponente.custo,novoComponente.moeda,novoComponente.unidade,novoComponente.calculo)">{{'btn.cadastrar'|translate}}</button>
          </div>
        </div>
      </form>
      <hr />
      <div class="text-center" *ngIf="edicaoComponente.edicaoComponente != null && edicaoComponente.componentesItens.length == 0">Nenhum componente cadastrado</div>
      <div class="accordion" id="accordionComponentes">
        <ng-container *ngFor="let listaComponente of edicaoComponente.componentesItens">
          <div class="card">
            <div class="card-header" id="{{listaComponente.ano}}">
              <h2 class="mb-0">
                <button class="btn btn-link" type="button" data-toggle="collapse" attr.data-target="#accordion{{listaComponente.ano}}" aria-expanded="true" aria-controls="collapseOne">
                  {{listaComponente.ano}}
                </button>
              </h2>
            </div>

            <div id="accordion{{listaComponente.ano}}" class="collapse" attr.aria-labelledby="{{listaComponente.ano}}" data-parent="#accordionComponentes">
              <div class="card-body">
                <table class="table">
                  <thead class="thead-dark">
                    <tr>
                      <th>{{'Menus.Componente'|translate}}</th>
                      <th>{{'componente.ano'|translate}}</th>
                      <th>{{'componente.custo'|translate}}</th>
                      <th>{{'componente.unidade'|translate}}</th>
                      <th>{{'componente.unidadeMedida'|translate}}</th>
                      <th>{{'componente.calculoRetencao'|translate}}</th>
                      <th>{{'acao'|translate}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngIf="listaComponente.componentesItens.length ==0">
                      <td colspan="6" class="text-center">{{'tabela.naoEcontrado' |translate}}</td>
                    </tr>
                    <tr *ngFor="let componente of listaComponente.componentesItens">
                      <td>{{componente.nome}}</td>
                      <td>
                        <select class="form-control" name="ano" [(ngModel)]="componente.ano">
                          <option *ngFor="let ano of anos" [ngValue]="ano">
                            {{ano}}
                          </option>
                        </select>
                      </td>
                      <td><input type="number" class="form-control" [(ngModel)]="componente.custo" /></td>
                      <td>
                        <select class="form-control" [(ngModel)]="componente.moeda">
                          <option *ngFor="let moeda of listaMoeda" value="{{moeda.moedaId}}">{{moeda.nome}}</option>
                        </select>
                      </td>
                      <td>
                        <select class="form-control" [(ngModel)]="componente.unidade">
                          <option *ngFor="let unidade of listaUnidade" value="{{unidade.unidadeId}}">{{unidade.nome}}</option>
                        </select>
                      </td>
                      <td>
                        <select class="form-control" [(ngModel)]="componente.calculo" title="{{ ehAdministrador ? mostrarDescComponenteSelecionado(componente.calculo): ''}}">
                          <option *ngFor="let calc of listaCalculos" value="{{calc.id}}">{{calc.calculo}}</option>
                        </select>
                      </td>
                      <td>
                        <button class="btn btn-success mr-2" (click)="atualizarComponente(componente)">Atualizar</button>
                        <button class="btn btn-danger" (click)="inativarComponente(componente)"><i class="mdi mdi-delete"></i></button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

          </div>
        </ng-container>
      </div>
    </div>
  </div>
</main>
