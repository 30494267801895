import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { PerfilEnum } from '../enums/PerfilEnum';
import { LoginService } from '../services/loginService';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {


  constructor(private router: Router, private loginService: LoginService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {

    if (this.loginService.currentUserValue == null) {
      this.router.navigate(['/sign-in']);
      return false;
    }
    

    if (next.data.roles != null) {
      const roles = this.loginService.currentUserValue.listaPerfil.map(b => { return b.idPerfil });
      for (var i = 0; i < next.data.roles.length; i++) {
        if (roles.indexOf(next.data.roles[i]) > -1) {
          return true;
        }
      }

      if (roles[0] == PerfilEnum.BOOKDEOFERTAS)
        this.router.navigate(['/bookOfertas']);

    }
      return false;
  }
}
