import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class RecuperarSenhaService {
    constructor(private http: HttpClient) { }

  baseUrl = "api/RecuperarSenha/"

    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json; charset=utf-8'
        })
    }

  public recuperarSenha(dto) {
        return this.http.post<any>(this.baseUrl + "RecuperarSenha", JSON.stringify(dto), this.httpOptions);
  }

  public alterarSenha(dto) {
    return this.http.post<any>(this.baseUrl + "AlterarSenha", JSON.stringify(dto), this.httpOptions);
  }
}
