<main>
  <div class="breadcrumb">
    <h3>Incoterm</h3>
  </div>
  <section>
    <div class="d-flex">
      <button class="btn btn-success" (click)="open(novoIncoterm)">Novo Incoterm</button>
    </div>
    <div class="table-gp">
      <table class="table">
        <thead>
          <tr>
            <th>Incoterm</th>
            <th>Desagio(%)</th>
            <th>Ação</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let incoterm of listaIncoterm">
            <td><input type="text" class="form-control" [(ngModel)]="incoterm.nome" readonly /></td>
            <td><input type="number" class="form-control" [(ngModel)]="incoterm.desagio" /></td>
            <td>
              <div class="d-flex">
                <button (click)="editarIncoterm(incoterm)" class="btn btn-outline-success mr-2">
                  Atualizar
                </button>
                <button class="btn btn-outline-danger" (click)="open(mymodal);selecionarIncoterm(incoterm.incotermId)">
                  <i class="mdi mdi-delete"></i>
                </button>
              </div>
            </td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="d-flex justify-content-end">
      <ngb-pagination [collectionSize]="filtro.total" [pageSize]="filtro.tamanhoPagina" [(page)]="filtro.numeroPagina"
      [maxSize]="10" [rotate]="true" [boundaryLinks]="true" (pageChange)="obterListaIncoterm($event)"></ngb-pagination>
    </div>
    
  </section>
</main>

<!-- Modals -->
<ng-template #mymodal let-modal>
    <div class="modal-header">
      <h5 class="modal-title">Inativar incoterm</h5>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>Deseja inativar este incoterm?</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="modal.dismiss('Cross click')" data-dismiss="modal">Fechar</button>
      <button type="button" class="btn btn-danger" (click)="inativarIncoterm()">Sim</button>
    </div>
  </ng-template>
    <ng-template #novoIncoterm let-modal>
      <div class="modal-header">
        <h5 class="modal-title">Novo Prêmio</h5>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body d-flex">
        <div class="form-group col-6 pl-0">
          <label>Incoterm</label>
          <input type="text" class="form-control" [(ngModel)]="nome" />
        </div>
        <div class="form-group col-6 pl-0">
          <label>Desagio(%)</label>
          <input type="number" class="form-control" [(ngModel)]="desagio" />
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="modal.dismiss('Cross click')" data-dismiss="modal">Cancelar</button>
        <button type="button" class="btn btn-success" (click)="cadastrarIncoterm(nome,desagio)">Cadastrar</button>
      </div>
    </ng-template>
