import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class ConfiguracaoService {
    constructor(private http: HttpClient) { }

    baseUrl = "api/Configuracao/"

    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json; charset=utf-8'
        })
    }


    public cadastrarConfiguracao(dto) {
        return this.http.post<any>(this.baseUrl + "CadastrarConfiguracao", JSON.stringify(dto), this.httpOptions);
    }

    public obterListaConfiguracao(dto) {
        return this.http.post<any>(this.baseUrl + "ObterListaConfiguracao", JSON.stringify(dto), this.httpOptions);
    }

    public editarConfiguracao(dto) {
        return this.http.post<any>(this.baseUrl + "EditarConfiguracao", JSON.stringify(dto), this.httpOptions);
    }

    public inativarConfiguracao(dto) {
        return this.http.post<any>(this.baseUrl + "InativarConfiguracao", JSON.stringify(dto), this.httpOptions);
    }

}
