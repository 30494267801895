import { Component } from '@angular/core';
import { LoginService } from '../../services/loginService';
import { ToastService } from '../../services/toastService';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { SituacaoCadastralEnum } from '../../enums/SituacaoCadastralEnum';


@Component({
  selector: 'app-listagemUsuario',
  templateUrl: './listagemUsuario.html',
  styleUrls: ['./listagemUsuario.styled.css']
})
export class ListagemUsuario {
  modalOptions: NgbModalOptions;
  title = 'ng-bootstrap-modal-demo';
  closeResult: string;
  listaUsuario: any[] = [];
  usuarioSelecionado: any = {};
  novoUsuario: any = {};
  idUsuarioInativar: number;
  
  situacaoCadastralEnum = SituacaoCadastralEnum;

  filtro: any = {
    tamanhoPagina: 10,
    numeroPagina: 1,
    email: "",
    nome: "",
    situacaoCadastral: null,
    status: null
  };

  loading = false;

  constructor(private usuarioService: LoginService,
    private toastService: ToastService, private modalService: NgbModal) {
    this.modalOptions = {
      backdrop: 'static',
      backdropClass: 'customBackdrop'
    }
  }

  ngOnInit() {
    this.obterListaUsuario(1);
  }

  obterUsuarioValidarCad(usuario) {
    this.usuarioSelecionado = usuario;
  }


  obterListaUsuario(pagina) {
    this.loading = true;
    this.filtro.numeroPagina = pagina;
    this.usuarioService.obterListaUsuario(this.filtro).subscribe(response => {
      this.loading = false;
      if (response.codigo === 200) {
        this.listaUsuario = response.result.retorno;
        this.filtro.total = response.result.qtdTotal;
      }
      else
        this.toastService.show(response.mensagem, { classname: 'bg-danger text-light', delay: 5000 })
    })
  }

  aprovar() {
    this.usuarioService.aprovarCadastro(this.usuarioSelecionado.usuarioId).subscribe(response => {
      if (response.retorno) {
        this.obterListaUsuario(this.filtro.numeroPagina);
        this.toastService.show('Aprovação realizada com sucesso!', { classname: 'bg-success text-light', delay: 5000 })
      }
      else
        this.toastService.show(response.mensagem, { classname: 'bg-danger text-light', delay: 5000 })
    });
    this.modalService.dismissAll();
  }

  recusar() {
    this.usuarioService.recusarCadastro(this.usuarioSelecionado.usuarioId).subscribe(response => {
      if (response.retorno) {
        this.obterListaUsuario(this.filtro.numeroPagina);
        this.toastService.show('Recusa realizada com sucesso!', { classname: 'bg-success text-light', delay: 5000 })
      }
      else
        this.toastService.show(response.mensagem, { classname: 'bg-danger text-light', delay: 5000 })
    });
    this.modalService.dismissAll();
  }

  selecionarFrete(id) {
    this.usuarioSelecionado = this.listaUsuario.find((usuario) => {
      return usuario.usuarioId === id;
    })
  }

  selecionarUsuario(id: number) {
    this.idUsuarioInativar = id;
  }

  atualizarUsuario(usuario) {
    if (usuario.senha != undefined && usuario.senha != "" && usuario.senha.length < 6) {
      this.toastService.show("senha inválida!", { classname: 'bg-danger text-light', delay: 5000 })
      return;
    }
    this.usuarioService.editarUsuario(usuario).subscribe(response => {
      if (response.codigo === 200)
        this.toastService.show('Usuário editado com sucesso!', { classname: 'bg-success text-light', delay: 5000 })
      else
        this.toastService.show(response.mensagem, { classname: 'bg-danger text-light', delay: 5000 })
    });
  }

  inativarUsuario() {
    this.usuarioService.inativarUsuario(this.idUsuarioInativar).subscribe(response => {
      if (response.codigo === 200) {
        this.toastService.show('Usuário inativado com sucesso!', { classname: 'bg-success text-light', delay: 5000 })
        this.obterListaUsuario(1);
      }
      else {
        this.toastService.show(response.mensagem, { classname: 'bg-danger text-light', delay: 5000 })
      }
      this.modalService.dismissAll();
    });
  }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', backdrop: false }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }


  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

 
}
