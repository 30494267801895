import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PracaService {
  constructor(private http: HttpClient) { }

  baseUrl = "api/Praca/"

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8'
    })
  }

  public obterListaPraca(dto) {
    return this.http.post<any>(this.baseUrl + "ObterListaPraca", JSON.stringify(dto), this.httpOptions);
  }

  public obterListaPracaPrecificacao(dto) {
    return this.http.post<any>(this.baseUrl + "ObterListaPracaPrecificacao", JSON.stringify(dto), this.httpOptions);
  }

  public cadastrarPraca(dto) {
    return this.http.post<any>(this.baseUrl + "CadastrarPraca", JSON.stringify(dto), this.httpOptions);
  }

  public inativarPraca(idPraca) {
    return this.http.get<any>(this.baseUrl + "InativarPraca?idPraca=" + idPraca, this.httpOptions);
  }

  public obterPracaPorId(id) {
    return this.http.get<any>(this.baseUrl + "ObterPracaPorId?pracaId=" + id, this.httpOptions);
  }
}
