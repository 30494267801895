import { Component } from '@angular/core';
import { PortoService } from '../../services/portoService';
import { EstadoService } from '../../services/estadoService';
import { ToastService } from '../../services/toastService';
import { NgbModal, ModalDismissReasons, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-listagemPorto',
  templateUrl: './listagemPorto.html',
  styleUrls: ['./listagemPortos.css']
})
export class ListagemPorto {
  modalOptions: NgbModalOptions;
  title = 'ng-bootstrap-modal-demo';
  closeResult: string;
  resposta: object = {};
  listaPorto: any[] = [];
  listaEstados: Array<DtoEstado>;
  portoSelecionado: any = {};
  novoPorto: Porto = new Porto();
  edPorto: any = {};
  dropdownSettings = {};

  filtro: any = {
    tamanhoPagina: 10,
    numeroPagina: 1
  };
  loading = false;
  constructor(private portoService: PortoService, private toastService: ToastService, private modalService: NgbModal, private estadoService: EstadoService) {

    this.modalOptions = {
      backdrop: 'static',
      backdropClass: 'customBackdrop'
    }
  }


  ngOnInit() {
    this.obterListaPorto(1);
    this.ObterTodosEstados();


  }

  obterListaPorto(pagina) {
    this.loading = true;
    this.filtro.numeroPagina = pagina;
    this.portoService.obterListaPorto(this.filtro).subscribe(response => {
      this.loading = false;
      if (response.codigo === 200) {
        this.listaPorto = response.result.retorno;
        this.filtro.total = response.result.qtdTotal;
      }
      else
        this.toastService.show(response.mensagem, { classname: 'bg-danger text-light', delay: 5000 })
    })
  }

  selecionarPorto(id: number, ehEdicao: boolean) {
    this.portoSelecionado = this.listaPorto.find((porto) => {
      return porto.portoId === id;
    })
    if (ehEdicao) {
      this.edPorto.portoId = this.portoSelecionado.portoId;
      this.edPorto.nome = this.portoSelecionado.nome;
      this.edPorto.sigla = this.portoSelecionado.sigla;
      this.edPorto.estados = this.portoSelecionado.estados;
    }
  }

  cadastrarPorto(nome, sigla, estados) {

    this.novoPorto.nome = nome;
    this.novoPorto.sigla = sigla;
    this.novoPorto.estados = estados;

    if (this.validarPorto(false)) {
      this.portoService.cadastrarPorto(this.novoPorto).subscribe(response => {
        if (response.codigo === 200) {
          this.toastService.show('Porto cadastrada com sucesso!', { classname: 'bg-success text-light', delay: 5000 })
          this.obterListaPorto(1);
        }
        else {
          this.toastService.show(response.mensagem, { classname: 'bg-danger text-light', delay: 5000 })
        }
        this.modalService.dismissAll();
      });
    }
  }

  validarPorto(ehEdicao: boolean): boolean {

    if (ehEdicao) {

      if (this.edPorto.nome == "" || this.edPorto.nome == undefined) {
        this.toastService.show("Preencha o nome do Porto", { classname: 'bg-warning text-light', delay: 5000 })
        return false;
      }

      if (this.edPorto.sigla == "" || this.edPorto.sigla == undefined) {
        this.toastService.show("Preencha a sigla do Porto", { classname: 'bg-warning text-light', delay: 5000 })
        return false;
      }

      if (this.edPorto.estados == null || this.edPorto.estados.length == 0) {
        this.toastService.show("Selecione um Estado", { classname: 'bg-warning text-light', delay: 5000 })
        return false;
      }

      return true;
    }

    if (!ehEdicao) {

      if (this.novoPorto.nome == "" || this.novoPorto.nome == undefined) {
        this.toastService.show("Preencha o nome do Porto", { classname: 'bg-warning text-light', delay: 5000 })
        return false;
      }

      if (this.novoPorto.sigla == "" || this.novoPorto.sigla == undefined) {
        this.toastService.show("Preencha a sigla do Porto", { classname: 'bg-warning text-light', delay: 5000 })
        return false;
      }

      if (this.novoPorto.estados == null || this.novoPorto.estados.length == 0) {
        this.toastService.show("Selecione um Estado", { classname: 'bg-warning text-light', delay: 5000 })
        return false;
      }

      return true;
    }
  }

  inativarPorto() {
    this.portoService.inativarPorto(this.portoSelecionado.portoId).subscribe(response => {
      if (response.codigo === 200) {
        this.toastService.show('Porto inativado com sucesso!', { classname: 'bg-success text-light', delay: 5000 })
        this.listaPorto = this.listaPorto.filter((porto) => {
          return porto.portoId !== this.portoSelecionado.portoId;
        })
      }
      else {
        this.toastService.show(response.mensagem, { classname: 'bg-danger text-light', delay: 5000 })
      }
      this.modalService.dismissAll();
    });
  }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title',  backdrop: false }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  editarPorto() {
    if (this.validarPorto(true)) {

      this.edPorto.estados = this.edPorto.estados;

      this.portoService.editarPorto(this.edPorto).subscribe(response => {
        if (response.codigo === 200) {
          this.toastService.show('Porto editado com sucesso!', { classname: 'bg-success text-light', delay: 5000 })
          this.obterListaPorto(1);
        }
        else {
          this.toastService.show(response.mensagem, { classname: 'bg-danger text-light', delay: 5000 })
        }
        this.modalService.dismissAll();
      })
    };
  }

  ObterTodosEstados() {
    this.estadoService.obterTodos().subscribe(response => {
      this.listaEstados = response;
      this.dropdownSettings = {
        idField: 'idEstado',
        textField: 'nomeEstado',
        selectAllText: 'Selecionar Todos',
        unSelectAllText: 'Desmarcar Todos',
        allowSearchFilter: true
      };
    })
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

}

export class Porto {
  portoId: number;
  nome: string;
  sigla: string;
  estados: any[];

  constructor() {

  }

}
