<div>
  <div class="d-flex justify-content-center">
    <h3>{{editando === true? "Edição" : "Cadastro"}} de Curva Cambio</h3>
  </div>
  <div class="card">
    <div class="card-body">
      <div>
        <p>Cotação Anterior</p>
        <div class="d-flex" *ngIf="editando === true">
          <div class="form-group col-md-4">
            <label>Preço Compra</label>
            <input type="text" class="form-control" [(ngModel)]="curvaCambio.valorCompra" readonly>
          </div>
          <div class="form-group col-md-4">
            <label>Preço Venda</label>
            <input type="text" class="form-control" [(ngModel)]="curvaCambio.valorVenda" readonly>
          </div>
          <div class="form-group col-md-4">
            <label>Data Cotação</label>
            <input type="text" class="form-control" [(ngModel)]="curvaCambio.dataAtualizacao" readonly>
          </div>
        </div>
      </div>

      <hr />

      <div class="d-flex">
        <div class="form-group col-md-4">
          <label>Preço Compra</label>
          <input type="text" class="form-control" [(ngModel)]="curvaCambio.valorCompraHoje" readonly>
        </div>
        <div class="form-group col-md-4">
          <label>Preço Venda</label>
          <input type="text" class="form-control" [(ngModel)]="curvaCambio.valorVendaHoje" readonly>
        </div>
        <div class="form-group col-md-4">
          <label>Data Cotação</label>
          <input type="text" class="form-control" [(ngModel)]="curvaCambio.dataHoraCotacaoHoje" readonly>
        </div>
      </div>

      <form #curvaForm="ngForm" novalidate>
        <div class="d-flex">
          <div class="form-group col-md-4">
            <label>Margem Valor de Compra</label>
            <input type="number" class="form-control" [class.is-invalid]="ValorCompra.invalid && ValorCompra.touched" [(ngModel)]="curvaCambio.margemValorCompra" name="ValorCompra" step="0.0001" min="0.0001" #ValorCompra required>
            <small class="text-danger" [class.d-none]="ValorCompra.valid || ValorCompra.untouched">Valor de Compra invalido!</small>
          </div>
          <div class="form-group col-md-4">
            <label>Margem Valor de Venda</label>
            <input type="number" class="form-control" [class.is-invalid]="ValorVenda.invalid && ValorVenda.touched" [(ngModel)]="curvaCambio.margemValorVenda" name="ValorVenda" step="0.0001" min="0.0001" #ValorVenda required>
            <small class="text-danger" [class.d-none]="ValorVenda.valid || ValorVenda.untouched">Valor de Venda invalido!</small>
          </div>
          <div class="form-group col-md-4">
            <label>Data da Curva</label>
            <input type="date" class="form-control" [class.is-invalid]="DataCurva.invalid && DataCurva.touched" [(ngModel)]="curvaCambio.dataCurva" name="DataCurva" #DataCurva required>
            <small class="text-danger" [class.d-none]="DataCurva.valid || DataCurva.untouched">Data invalida!</small>
          </div>

        </div>
      </form>
      <div class="d-flex">
        <div class="form-group col-md-4">
          <label>Total Valor de Compra</label>
          <input type="number" class="form-control" value="{{curvaCambio.margemValorCompra + curvaCambio.valorCompraHoje | number: '1.4-4'}}" step="0.0001" min="0.0001" disabled required>
        </div>
        <div class="form-group col-md-4">
          <label>Total Valor de Venda</label>
          <input type="number" class="form-control" value="{{curvaCambio.margemValorVenda + curvaCambio.valorVendaHoje | number: '1.4-4'}}" step="0.0001" min="0.0001" disabled required>
        </div>
        <div class="col-md-1 d-flex align-self-end mb-3">
          <button type="button" [disabled]="curvaForm.form.invalid" class="btn btn-success" (click)="cadastrarCurvaCambio()">{{editando === true ? "Editar" : "Cadastrar"}}</button>
        </div>

      </div>
    </div>
  </div>
  <div class="card" *ngIf="listaCurva.length > 0">
    <div class="card-body">
      <table class="table">
        <thead>
          <tr>
            <td>Data da Curva</td>
            <td>Valor de Compra</td>
            <td>Valor de Venda</td>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let curva of listaCurva">
            <td>{{curva.DataCurva | date: 'dd/MM/yyyy'}}</td>
            <td>{{curva.ValorCompra | number: '1.4-4'}}</td>
            <td>{{curva.ValorVenda | number: '1.4-4'}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

  
