<main>
  <nb-card [nbSpinner]="loading">
    <nb-card-header>
      <h3>{{'Menus.Concluidos'|translate}}</h3>
      <div class="form-group col-12 pl-0">
        <div class="row align-items-end">
          <div class="col-6">
            <label>Grão</label>
            <select class="form-control col-sm-6 col-xl-4" [(ngModel)]="filtro.idProduto" name="grao" (change)="obterListaPrecificacao(1)">
              <option *ngFor="let grao of listaGraos" [ngValue]="grao.idProduto">
                {{grao.nomeProduto}}
              </option>
            </select>
          </div>
        </div>
      </div>
    </nb-card-header>
    <nb-card-body>
      <div class="fixTableHead">
        <div class="form-group float-right">
          <label>{{'filtro.tamanhoPagina'|translate}}</label>
          <select class="form-control" [(ngModel)]="filtro.tamanhoPagina" name="tamanhoPagina" (change)="obterListaPrecificacao(1)">
            <option *ngFor="let tamanhoPagina of tamanhosPaginas" [ngValue]="tamanhoPagina">
              {{tamanhoPagina}}
            </option>
          </select>
        </div>
        <div class="text-left">
          <table class="table table-bordered table-striped table-sm" mat-table matSort [dataSource]="dataSource">

            <tr style="background: #85888b; " mat-header-row *matHeaderRowDef="['nome' ,'porto', 'premioStr' , 'tipoNegocioString', 'ofertaDolarEReal', 'bidDolarEREal', 'premioValor', 'valorCbot' , 'volumeNegociado','nomeContraparte','opcoes'];sticky: true"></tr>



            <ng-container matColumnDef="nome">
              <th mat-header-cell *matHeaderCellDef>
                <div style="display: flex; align-items: center;">
                  <span>{{'tabela.nome'|translate}}</span>

                  <i class="mdi mdi-filter-variant mdi-24px" [matMenuTriggerFor]="menu"
                     [ngStyle]="jaFiltrado('nome') ? { color: '#28a745' } : ''"
                     (menuOpened)="onMenuOpened('nome')"
                     [matMenuTriggerData]="{columnName: 'nome'}"> </i>
                </div>
              </th>
              <td mat-cell *matCellDef="let element"> {{element.nome.trim()}} </td>
            </ng-container>

            <ng-container matColumnDef="porto">
              <th mat-header-cell *matHeaderCellDef>
                <div style="display: flex; align-items: center;">
                  <span>{{'tabela.porto'|translate}}</span>

                  <i class="mdi mdi-filter-variant mdi-24px" [matMenuTriggerFor]="menu"
                     [ngStyle]="jaFiltrado('porto') ? { color: '#28a745' } : ''"
                     [matMenuTriggerData]="{columnName: 'porto'}"
                     (menuOpened)="onMenuOpened('porto')"> </i>
                </div>
              </th>
              <td mat-cell *matCellDef="let element"> {{element.porto}} </td>
            </ng-container>

            <ng-container matColumnDef="premioStr">
              <th mat-header-cell *matHeaderCellDef>
                <div style="display: flex; align-items: center;">
                  <span>{{'tabela.exec'|translate}}</span>

                  <i class="mdi mdi-filter-variant mdi-24px" [matMenuTriggerFor]="menu"
                     [ngStyle]="jaFiltrado('premioStr') ? { color: '#28a745' } : ''"
                     (menuOpened)="onMenuOpened('premioStr')"
                     [matMenuTriggerData]="{columnName: 'premioStr'}"> </i>
                </div>
              </th>
              <td mat-cell *matCellDef="let element"> {{element.premioStr}} </td>
            </ng-container>

            <ng-container matColumnDef="tipoNegocioString">
              <th class="d-none d-lg-table-cell" mat-header-cell *matHeaderCellDef>
                <div style="display: flex; align-items: center;">
                  <span>{{'tabela.concluidos.tipo'|translate}}</span>

                  <i class="mdi mdi-filter-variant mdi-24px" [matMenuTriggerFor]="menu"
                     [ngStyle]="jaFiltrado('tipoNegocioString') ? { color: '#28a745' } : ''"
                     (menuOpened)="onMenuOpened('tipoNegocioString')"
                     [matMenuTriggerData]="{columnName: 'tipoNegocioString'}"> </i>
                </div>
              </th>
              <td class="d-none d-lg-table-cell" mat-cell *matCellDef="let element"> {{element.tipoNegocioString}} </td>
            </ng-container>

            <ng-container matColumnDef="ofertaDolarEReal">
              <th mat-header-cell *matHeaderCellDef>
                <div style="display: flex; align-items: center;">
                  <span>{{'tabela.concluidos.oferta'|translate}}</span>

                  <i class="mdi mdi-filter-variant mdi-24px" [matMenuTriggerFor]="menu"
                     [ngStyle]="jaFiltrado('ofertaDolarEReal') ? { color: '#28a745' } : ''"
                     (menuOpened)="onMenuOpened('ofertaDolarEReal')"
                     [matMenuTriggerData]="{columnName: 'ofertaDolarEReal'}"> </i>
                </div>
              </th>
              <td mat-cell *matCellDef="let precificacao">
                <span class="{{marcarComoSelecionado(TipoTransaoEnum.OFFER,MoedaEnum.DOLAR,precificacao)}}">
                  {{formatarPrecoDolar(precificacao.valor)}}
                  <i *ngIf="mostrarPrecificacaoSelecionado(TipoTransaoEnum.OFFER,MoedaEnum.DOLAR,precificacao)" class="mdi mdi-check mdi-12px"></i>
                </span> |
                <span class="{{marcarComoSelecionado(TipoTransaoEnum.OFFER,MoedaEnum.REAL,precificacao)}}">
                  {{precificacao.valorRs | currency:'BRL'}}
                  <i *ngIf="mostrarPrecificacaoSelecionado(TipoTransaoEnum.OFFER,MoedaEnum.REAL,precificacao)" class="mdi mdi-check mdi-12px"></i>
                </span>

              </td>
            </ng-container>

            <ng-container matColumnDef="bidDolarEREal">
              <th mat-header-cell *matHeaderCellDef>
                <div style="display: flex; align-items: center;">
                  <span>{{'tabela.concluidos.bid'|translate}}</span>

                  <i class="mdi mdi-filter-variant mdi-24px" [matMenuTriggerFor]="menu"
                     [ngStyle]="jaFiltrado('bidDolarEREal') ? { color: '#28a745' } : ''"
                     (menuOpened)="onMenuOpened('bidDolarEREal')"
                     [matMenuTriggerData]="{columnName: 'bidDolarEREal'}"> </i>
                </div>
              </th>
              <td mat-cell *matCellDef="let precificacao">
                <span class="{{marcarComoSelecionado(TipoTransaoEnum.BID,MoedaEnum.DOLAR,precificacao)}}">
                  {{formatarPrecoDolar(precificacao.valorBid)}}
                  <i *ngIf="mostrarPrecificacaoSelecionado(TipoTransaoEnum.BID,MoedaEnum.DOLAR,precificacao)" class="mdi mdi-check mdi-12px"></i>
                </span> |
                <span class="{{marcarComoSelecionado(TipoTransaoEnum.BID,MoedaEnum.REAL,precificacao)}}">
                  {{precificacao.valorBidRs | currency:'BRL'}}
                  <i *ngIf="mostrarPrecificacaoSelecionado(TipoTransaoEnum.BID,MoedaEnum.REAL,precificacao)" class="mdi mdi-check mdi-12px"></i>
                </span>

              </td>
            </ng-container>

            <ng-container matColumnDef="premioValor">
              <th mat-header-cell *matHeaderCellDef>
                <div style="display: flex; align-items: center;">
                  <span>{{'tabela.concluidos.premio'|translate}}</span>

                  <i class="mdi mdi-filter-variant mdi-24px" [matMenuTriggerFor]="menu"
                     [ngStyle]="jaFiltrado('premioValor') ? { color: '#28a745' } : ''"
                     (menuOpened)="onMenuOpened('premioValor')"
                     [matMenuTriggerData]="{columnName: 'premioValor'}"> </i>
                </div>
              </th>
              <td mat-cell *matCellDef="let precificacao">
                {{formatarPrecoDolar(precificacao.premioValor)}}
              </td>
            </ng-container>


            <ng-container matColumnDef="valorCbot">
              <th mat-header-cell *matHeaderCellDef>
                <div style="display: flex; align-items: center;">
                  <span>CBOT</span>

                  <i class="mdi mdi-filter-variant mdi-24px" [matMenuTriggerFor]="menu"
                     [ngStyle]="jaFiltrado('valorCbot') ? { color: '#28a745' } : ''"
                     (menuOpened)="onMenuOpened('valorCbot')"
                     [matMenuTriggerData]="{columnName: 'valorCbot'}"> </i>
                </div>
              </th>
              <td mat-cell *matCellDef="let precificacao">
                {{formatarPrecoDolar(precificacao.valorCbot)}}
              </td>
            </ng-container>

            <ng-container matColumnDef="volumeNegociado">
              <th mat-header-cell *matHeaderCellDef>
                <div style="display: flex; align-items: center;">
                  <span>{{'tabela.concluidos.volume'|translate}}</span>

                  <i class="mdi mdi-filter-variant mdi-24px" [matMenuTriggerFor]="menu"
                     [ngStyle]="jaFiltrado('volumeNegociado') ? { color: '#28a745' } : ''"
                     (menuOpened)="onMenuOpened('volumeNegociado')"
                     [matMenuTriggerData]="{columnName: 'volumeNegociado'}"> </i>
                </div>
              </th>
              <td mat-cell *matCellDef="let precificacao">
                {{precificacao.volumeNegociado}}
              </td>
            </ng-container>



            <ng-container matColumnDef="nomeContraparte">
              <th class="d-none d-lg-table-cell" mat-header-cell *matHeaderCellDef>
                <div style="display: flex; align-items: center;">
                  <span>{{'tabela.concluidos.contraparte'|translate}}</span>

                  <i class="mdi mdi-filter-variant mdi-24px" [matMenuTriggerFor]="menu"
                     [ngStyle]="jaFiltrado('nomeContraparte') ? { color: '#28a745' } : ''"
                     (menuOpened)="onMenuOpened('nomeContraparte')"
                     [matMenuTriggerData]="{columnName: 'nomeContraparte'}"> </i>
                </div>
              </th>
              <td class="d-none d-lg-table-cell" mat-cell *matCellDef="let precificacao">{{precificacao.nomeContraparte}}</td>
            </ng-container>



            <ng-container matColumnDef="opcoes">
              <th mat-header-cell *matHeaderCellDef> Ações </th>
              <td mat-cell *matCellDef="let precificacao">
                <button class="btn btn-sm btn-outline-success mr-2" data-toggle="tooltip" data-placement="top" title="Boleta" routerLink="/editarBoleta/{{precificacao.negocioId}}">
                  <i class="mdi mdi-clipboard-outline"></i>

                </button>

                <button class="btn  btn-sm btn-outline-info mr-2" data-toggle="tooltip" data-placement="top" title="{{'tabela.informacoes'|translate}}" (click)="open(mymodal);selecionarPrecificacao(precificacao)">
                  <i class="mdi mdi-magnify"></i>
                </button>

              </td>
            </ng-container>

            <tr mat-row *matRowDef="let row; columns: [ 'nome','porto', 'premioStr', 'tipoNegocioString', 'ofertaDolarEReal', 'bidDolarEREal',  'premioValor', 'valorCbot','volumeNegociado', 'nomeContraparte','opcoes']"></tr>

          </table>
        </div>
        <div class="d-flex justify-content-end">
          <ngb-pagination [collectionSize]="filtro.total"
                          [pageSize]="filtro.tamanhoPagina"
                          [(page)]="filtro.numeroPagina"
                          [maxSize]="10"
                          [rotate]="true"
                          [boundaryLinks]="true"
                          (pageChange)="obterListaPrecificacao($event)"></ngb-pagination>
        </div>
      </div>
    </nb-card-body>
  </nb-card>

  <mat-menu #menu="matMenu" class="matMenu">
    <ng-template matMenuContent let-dataColumnName="columnName">
      <nb-card class="flex-column" (click)="$event.stopPropagation();">
        <nb-card-header>Filtro</nb-card-header>
        <nb-card-body>
          <div class="mb-3">

            <select class="form-control col-sm-12 col-xl-12" #selectedOperationFilter [(ngModel)]="selectedOption">
              <option value="contains" select>Contém</option>
              <option value="equals">Igual</option>
              <option value="greaterThan">Maior que</option>
              <option value="lessThan">Menor que</option>
              <option value="endWith">Termina com</option>
              <option value="startWith">Começa com</option>
            </select>
          </div>

          <div class="mb-3">
            <label>Escreva</label>
            <input class="form-control" #searchValue type="text" [(ngModel)]="filtrarPorTexto" placeholder="Escreva um valor">
          </div>

          <div>
            <button class="btn btn-secondary mr-3"
                    (click)="clearFilter(dataColumnName)">
              <i class="mdi mdi-close">Limpar</i>
            </button>

            <button [disabled]="!searchValue.value"
                    class="btn btn-success"
                    (click)="applyFilter(dataColumnName,
                 selectedOperationFilter.value,  searchValue.value)">
              <i class="mdi mdi-filter"> Filtrar</i>
            </button>


          </div>
        </nb-card-body>
      </nb-card>
    </ng-template>
  </mat-menu>
</main>



<ng-template #mymodal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Visualizar {{precificacaoSelecionada.nome}}</h5>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body d-flex">
    <div class="col-6">
      <div>
        {{'Menus.Precificacao'|translate}}
        <input class="form-control" [(ngModel)]="precificacaoSelecionada.nome" readonly />
      </div>
      <div>
        {{'tabela.bookOfertas.tipoNegocio'|translate}}
        <input class="form-control" [(ngModel)]="precificacaoSelecionada.tipoNegocioString" readonly />
      </div>
      <div>
        {{'modals.bookOfertas.porto'|translate}}
        <input class="form-control" [(ngModel)]="precificacaoSelecionada.porto" readonly />
      </div>
      <div>
        {{'modals.bookOfertas.premio'|translate}}
        <input class="form-control" value="{{precificacaoSelecionada.premioStr}}" readonly />
      </div>
      <div>
        {{'modals.bookOfertas.cbot'|translate}}
        <input class="form-control" [(ngModel)]="precificacaoSelecionada.cbot" readonly />
      </div>
      <div>
        {{'modals.bookOfertas.rodo'|translate}}
        <input class="form-control" [(ngModel)]="precificacaoSelecionada.frete" readonly />
      </div>
      <div>
        {{'modals.bookOfertas.dataRodo'|translate}}
        <input class="form-control" [(ngModel)]="precificacaoSelecionada.dataRodoString" readonly />
      </div>
      <div>
        {{'modals.bookOfertas.ferro'|translate}}
        <input class="form-control" [(ngModel)]="precificacaoSelecionada.freteFerro" readonly />
      </div>
      <div>
        {{'modals.bookOfertas.dataFerro'|translate}}
        <input class="form-control" [(ngModel)]="precificacaoSelecionada.dataFerroString" readonly />
      </div>
      <div>
        {{'modals.bookOfertas.bid'|translate}}/usd
        <input class="form-control {{indicarCampoSelecionadoModal(TipoTransaoEnum.BID,MoedaEnum.DOLAR,precificacaoSelecionada)}}" value="{{precificacaoSelecionada.valorBid | currency:'USD'}}" readonly />
      </div>
      <div>
        {{'modals.bookOfertas.dataMercado'|translate}}
        <input class="form-control" [(ngModel)]="precificacaoSelecionada.dataMercString" readonly />
      </div>
      <div>
        {{'modals.bookOfertas.bid'|translate}}/R$
        <input class="form-control {{indicarCampoSelecionadoModal(TipoTransaoEnum.BID,MoedaEnum.REAL,precificacaoSelecionada)}}" value="{{precificacaoSelecionada.valorBidRs | currency:'BRL'}}" readonly />
      </div>
      <div>
        {{'modals.bookOfertas.volume'|translate}}
        <input class="form-control" [(ngModel)]="precificacaoSelecionada.volumeNegociado" readonly />
      </div>

    </div>
    <div class="col-6">
      <div>
        {{'modals.bookOfertas.dataCadastro'|translate}}
        <input class="form-control" [(ngModel)]="precificacaoSelecionada.dataAtualizacao" readonly />
      </div>
      <div>
        {{'tabela.bookOfertas.produto'|translate}}
        <input class="form-control" value="{{precificacaoSelecionada.produto}}" readonly />
      </div>

      <div>
        {{'modals.bookOfertas.totalComponentes'|translate}}
        <input class="form-control" value="{{precificacaoSelecionada.total | number:'1.2-4'}}" readonly />
      </div>
      <div>
        {{'modals.bookOfertas.premioValor'|translate}}
        <input class="form-control" value="{{precificacaoSelecionada.premioValor | number:'1.2-4'}}" readonly />
      </div>
      <div>
        {{'modals.bookOfertas.cbot'|translate}}
        <input class="form-control" [(ngModel)]="precificacaoSelecionada.cbotValor" readonly />
      </div>

      <div>
        {{'modals.bookOfertas.valorRodo'|translate}}
        <input class="form-control" value="{{precificacaoSelecionada.valorFrete | number:'1.2-4'}}" readonly />
      </div>
      <div>
        {{'modals.bookOfertas.hedgeRodo'|translate}}
        <input class="form-control" value="{{precificacaoSelecionada.hedgeRodo  | number:'1.2-4'}}" readonly />
      </div>
      <div>
        {{'modals.bookOfertas.valorFerro'|translate}}
        <input class="form-control" [(ngModel)]="precificacaoSelecionada.valorFreteFerro" readonly />
      </div>
      <div>
        {{'modals.bookOfertas.hedgeFerro'|translate}}
        <input class="form-control" value="{{precificacaoSelecionada.hedgeFerro  | number:'1.2-4'}}" readonly />
      </div>
      <div>
        {{'modals.bookOfertas.oferta'|translate}}/usd
        <input class="form-control {{indicarCampoSelecionadoModal(TipoTransaoEnum.OFFER,MoedaEnum.DOLAR,precificacaoSelecionada)}}" value="{{precificacaoSelecionada.valor | currency:'USD'}}" readonly />
      </div>

      <div>
        {{'modals.bookOfertas.hedgeMerc'|translate}}
        <input class="form-control" value="{{precificacaoSelecionada.hedgeMercado | number:'1.2-4'}}" readonly />
      </div>

      <div>
        {{'modals.bookOfertas.oferta'|translate}}/R$
        <input class="form-control {{indicarCampoSelecionadoModal(TipoTransaoEnum.OFFER,MoedaEnum.REAL,precificacaoSelecionada)}}" value="{{precificacaoSelecionada.valorRs | number:'1.2-4'}}" readonly />
      </div>
      <div>
        {{'modals.bookOfertas.contraparte'|translate}}
        <input class="form-control" [(ngModel)]="precificacaoSelecionada.nomeContraparte" readonly />
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="modal.dismiss('Cross click')" data-dismiss="modal">   {{'btn.fechar'|translate}}</button>
  </div>
</ng-template>
