import { Component, OnInit } from '@angular/core';
import { ConfiguracaoService } from '../services/configuracaoService';
import { ToastService } from '../services/toastService';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TermoService } from '../services/termoService';

@Component({
  selector: 'app-configuracao',
  templateUrl: './configuracao.html',
  styleUrls: ['./configuracao.styled.css']
})
export class Configuracao implements OnInit {

  constructor(private configuracaoService: ConfiguracaoService, private toastService: ToastService, private modalService: NgbModal, private fb: FormBuilder, private termoService: TermoService) { }

  modalOptions: NgbModalOptions;
  title = 'ng-bootstrap-modal-demo';
  closeResult: string;
  listaConfiguracao: any[] = [];
  filtro: any = {
    tamanhoPagina: 10,
    numeroPagina: 1,
  };
  novaConfig: any = {};
  configuracaoSelecionada: any = {};
  loading = false;
  fileForm: FormGroup;
  dataAtualizacao: any;
  carregandoArquivo = false;
  ngOnInit() {
    this.obterListaConfiguracao(1);
    this.obterUltimaAtualizacaoContrato();
    this.fileForm = this.fb.group({
      fileInput: [''],
    });
  }

  onImagePicked(event: Event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.fileForm.patchValue({ fileInput: file });
  }

  obterUltimaAtualizacaoContrato() {
    this.termoService.ultimaAtualizacao().subscribe((res) => {
      this.dataAtualizacao = res.data;
    });
  }

  atualizarTermo() {
    const fileToUpload = this.fileForm.get('fileInput').value;
    this.carregandoArquivo = true;
    this.termoService.upload(fileToUpload).subscribe(() => {
      this.carregandoArquivo = false;
      this.toastService.show('Termo atualizado com sucesso', { classname: 'bg-success text-light', delay: 1000 });
      this.obterUltimaAtualizacaoContrato();
      this.fileForm.reset();
    },
      (error) => {
        this.carregandoArquivo = false;
        this.toastService.show("Erro ao atualizar contrato", { classname: 'bg-danger text-light', delay: 5000 })
      });
  }



  cadastrarConfig(nome, tipo, valor) {
    this.novaConfig.nome = nome;
    this.novaConfig.valor = valor;
    this.novaConfig.tipoColuna = tipo;
    this.configuracaoService.cadastrarConfiguracao(this.novaConfig).subscribe(response => {
      if (response.codigo === 200) {
        this.toastService.show('Configuração cadastrada com sucesso!', { classname: 'bg-success text-light', delay: 5000 })

        this.listaConfiguracao.push(response.result);
      }
      else {
        this.toastService.show(response.mensagem, { classname: 'bg-danger text-light', delay: 5000 })
      }

    });
  }



  obterListaConfiguracao(pagina) {
    this.loading = true;
    this.filtro.pagina = pagina;
    this.configuracaoService.obterListaConfiguracao(this.filtro).subscribe(response => {
      this.loading = false;
      if (response.codigo === 200) {
        this.listaConfiguracao = response.result.retorno;
        this.filtro.total = response.result.qtdTotal;

      }
      else
        this.toastService.show(response.mensagem, { classname: 'bg-danger text-light', delay: 5000 })

    })
  }

  selecionarConfiguracao(id) {
    this.configuracaoSelecionada = this.listaConfiguracao.find(config => {
      return config.configuracaoId == id;
    })
  }

  editarConfig(config) {
    this.configuracaoService.editarConfiguracao(config).subscribe(response => {
      if (response.codigo === 200) {
        this.toastService.show('Configuração editada com sucesso!', { classname: 'bg-success text-light', delay: 5000 })
      }
      else
        this.toastService.show(response.mensagem, { classname: 'bg-danger text-light', delay: 5000 })
    })
  }

  inativarConfiguracao() {
    this.configuracaoService.inativarConfiguracao(this.configuracaoSelecionada).subscribe(response => {
      if (response.codigo === 200) {
        this.toastService.show('Configuração inativada com sucesso!', { classname: 'bg-success text-light', delay: 5000 })
        this.listaConfiguracao = this.listaConfiguracao.filter(configuracao => {
          return this.configuracaoSelecionada.configuracaoId != configuracao.configuracaoId;
        });
        this.modalService.dismissAll();
      }
      else
        this.toastService.show(response.mensagem, { classname: 'bg-danger text-light', delay: 5000 })
    });
  }

  open(content) {
    this.modalService.open(content, { backdrop: false }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


}
