<main>
  <div class="breadcrumb">
    <h3>{{'Menus.Vinculo' |translate}}</h3>
  </div>
  <form #usuarioForm="ngForm" novalidate class="p-3">
    <div class="form-group mb-3">
      <label>Buscar grupo</label>
      <div class="input-group mb-3">
        <input type="text" class="form-control" placeholder="Codigo do grupo" [(ngModel)]="codigoConsulta" name="codigoConsulta" aria-label="Codigo do grupo">
        <button class="btn btn-outline-success" type="button" (click)="consultarCodigo()"> <i class="mdi mdi-file-find"></i>Pesquisar</button>
        <button class="btn btn-outline-warning" type="button" (click)="limparConsulta()"> <i class="mdi mdi-eraser"></i>Limpar</button>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-6">
        <label> Cnpj</label>
        <input class="form-control" type="text" name="cnpj" [(ngModel)]="cnpj" disabled />
      </div>
      <div class="form-group col-6">
        <label> Razão social</label>
        <input class="form-control" type="text" name="razaoSocial" [(ngModel)]="razaoSocial" disabled />
      </div>
    </div>
    <div class="row">
      <div class="form-group col-12">
        <label>Endereço</label>
        <input class="form-control" type="text" name="endereco" [(ngModel)]="endereco" disabled />
      </div>
    </div>
    <div class="form-group col-6 pl-0">
      <div class=" d-flex">
        <button class="btn btn-success" (click)="efetuarSolicitacao()">{{'btn.salvar'|translate}}</button>
      </div>
    </div>
  </form>
</main>
