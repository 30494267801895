import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';


@Injectable({
  providedIn: 'root'
})
export class LoaderService {
    isLoading = new Subject<boolean>();
    constructor(public spinner: NgxSpinnerService) { }
    show(teste) {
        if (teste === true) {
            this.spinner.show();
            this.isLoading.next(true);
        }
        else {
            this.spinner.hide();

            this.isLoading.next(false);
        }
        
    }

    hide() {
        
    }
}
