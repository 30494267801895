import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EstadoService {
  constructor(private http: HttpClient) { }

  baseUrl = "api/Estado/"

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8'
    })
  }

  public obterPorId(id) {
    return this.http.get<any>(this.baseUrl + "ObterEstadoPorId/" + id, this.httpOptions);
  }

  public ativar(id) {
    return this.http.get<any>(this.baseUrl + "Ativar/" + id, this.httpOptions);
  }

  public inativar(id) {
    return this.http.get<any>(this.baseUrl + "Inativar/" + id, this.httpOptions);
  }

  public obterTodos() {
    return this.http.get<any>(this.baseUrl + "ObterTodos", this.httpOptions);
  }

  public obterTodosAtivos() {
    return this.http.get<any>(this.baseUrl + "ObterTodosAtivos", this.httpOptions);
  }
}
