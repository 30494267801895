import { Component, OnInit } from "@angular/core";
import { ToastService } from '../services/toastService';
import { RecuperarSenhaService } from '../services/recuperarSenhaService';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './recuperarSenha.html',
})

export class RecuperarSenha implements OnInit { 

  constructor(private toastService: ToastService, private recuperarSenhaService: RecuperarSenhaService, private router: Router) {}

  dados: any = {};
  loadingRecuperarSenha = false;

  ngOnInit() {
    this.loadingRecuperarSenha = false;
    
  }
  public recuperarSenha() {
    this.loadingRecuperarSenha = true;
    this.recuperarSenhaService.recuperarSenha(this.dados).subscribe(response => {
      this.loadingRecuperarSenha = false;
      if (response.retorno) {
        this.toastService.show(response.mensagem, { classname: 'bg-success text-light', delay: 5000 })
        this.router.navigate(['/sign-in']);
      }
      else
        this.toastService.show(response.mensagem, { classname: 'bg-danger text-light', delay: 5000 })
    })
  }
}
