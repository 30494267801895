<main>
  <nb-card [nbSpinner]="loading">
    <nb-card-header class="breadcrumb">
      <h3>{{'Menus.Frete'|translate}} <strong>{{praca.nomePraca}}</strong></h3>
    </nb-card-header>
    <nb-card-body>
      <div class="d-flex justify-content-md-between flex-wrap mb-5">
        <a class="btn btn-outline-secondary mr-sm-3" (click)="voltar();$event.preventDefault()"><i class="mdi mdi-keyboard-backspace"></i> {{'btn.voltar'|translate}}</a>
        <button class="btn btn-outline-dark" (click)="exportexcel()"><i class="mdi mdi-file-excel"></i> {{'btn.exportarPlanilha'|translate}}</button>
      </div>
      <h5 class="md-2">{{'titulo.estadoVisualizacao'|translate}}</h5>
      <div class="d-flex">
        <div class="col-3 pl-0">
          <ng-multiselect-dropdown [data]="listaEstado"
                                   [(ngModel)]="filtro.estadosSelecionados"
                                   [ngModelOptions]="{standalone: true}"
                                   [settings]="dropdownSettings">
          </ng-multiselect-dropdown>

        </div>
        <div class="col-2">
          <button class="btn btn-success" (click)="obterFretes()">{{'btn.pesquisar'|translate}} <i class="mdi mdi-magnify"></i></button>
        </div>
      </div>
      <br />
      <h5 class="md-2">{{'titulo.selecioneUmPorto'|translate}}:</h5>
      <nb-tabset (changeTab)="SelecionarPorto($event)">
        <nb-tab *ngFor="let porto of portosList"  tabId="{{porto.portoId}}" tabTitle="{{porto.nome}}" active="{{abaAtivaPorto(porto.portoId)}}">
            <br />
            <h5 class="md-2">{{'titulo.selecioneTipoFrete'|translate}}:</h5>
            <nb-tabset (changeTab)="SelecionarTipoFrete($event)">
              <nb-tab tabTitle="Rodoviário" tabId="1">
                  <div class="m-3">
                    <h4>
                      {{'titulo.fretes'|translate}}
                    </h4>
                    <form #rodoviaForm="ngForm" novalidate>
                      <div class="d-flex justify-content-md-start justify-content-sm-between align-items-center flex-wrap">
                        <div class="form-group mr-md-3 mr-sm-0">
                          <label>{{'frete.execucao'|translate}}</label>
                          <input [(ngModel)]="freteRodovia.dataRodo"
                                 [class.is-invalid]="dataRodo.invalid && dataRodo.touched" name="dataRodo" #dataRodo
                                 required autocomplete="off" class="form-control" bsDatepicker [bsConfig]="{dateInputFormat: 'MM/YYYY'}" (onShown)="aoAbrirCalendario($event)">
                        </div>
                        <div class="form-group mr-md-3 mr-sm-0">
                          <label>{{'frete.valor'|translate}}</label>
                          <input type="number" class="form-control" [(ngModel)]="freteRodovia.valor"
                                 [class.is-invalid]="rodoValor.invalid && rodoValor.touched" name="rodoValor"
                                 #rodoValor required>
                        </div>
                        <div class="d-flex align-self-end mb-3 mr-md-3 mr-sm-0">
                          <button class="btn btn-success" [disabled]="rodoviaForm.form.invalid"
                                  (click)="cadastrarFrete(1,freteRodovia.dataRodo,freteRodovia.valor)">
                            {{'btn.cadastrar'|translate}} <i class="mdi mdi mdi-truck"></i>
                          </button>
                        </div>
                      </div>
                    </form>
                    <div class="table-gp">
                      <table class="table">
                        <thead class="thead-dark">
                          <tr>
                            <th>
                              {{'tabela.data'|translate}}
                              <select [(ngModel)]="filtroRodovia.ano" name="anoFerrovia" (change)="obterFreteRodoviario()" *ngIf="anosRodo.length > 0">
                                <option [ngValue]="null">Selecione</option>
                                <option *ngFor="let ano of anosRodo" [ngValue]="ano">{{ano}}</option>
                              </select>
                            </th>
                            <th>
                              {{'tabela.valor'|translate}}
                            </th>
                            <th>
                              {{'tabela.acao'|translate}}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngIf="carregando">
                            <td colspan="3" align="center" style="text-align:center">   {{'tabela.carregando'|translate}}</td>
                          </tr>
                          <tr *ngIf="listaRodovia.length == 0 && !carregando">
                            <td colspan="3" align="center" style="text-align:center">
                              {{'tabela.naoEcontrado'|translate}}
                            </td>
                          </tr>
                          <tr *ngFor="let frete of listaRodovia">
                            <td>{{frete.dataFrete}}</td>
                            <td><input type="number" class="form-control" [(ngModel)]="frete.valor" /></td>
                            <td class="d-flex">
                              <button class="btn btn-success" (click)="atualizarFrete(frete)" title="{{'btn.atualizar'|translate}}"><i class="mdi mdi-lead-pencil"></i></button>
                              <button class="btn btn-danger ml-2" (click)="inativarFrete(frete)">
                                <i class="mdi mdi-delete"></i>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="d-flex justify-content-end">
                      <ngb-pagination [collectionSize]="filtroRodovia.total" [pageSize]="filtroRodovia.tamanhoPagina"
                                      [(page)]="filtroRodovia.numeroPagina" [maxSize]="10" [rotate]="true" [boundaryLinks]="true"
                                      (pageChange)="obterFretePorPraca($event,1)"></ngb-pagination>
                    </div>
                  </div>
              </nb-tab>
              <nb-tab tabTitle="Modal" tabId="2">
                  <div class="m-3">
                    <h4>
                      {{'titulo.fretes'|translate}}
                    </h4>
                    <form #ferroviaForm="ngForm">
                      <div class="d-flex justify-content-md-start justify-content-sm-between align-items-center flex-wrap">
                        <div class="form-group mr-md-3 mr-sm-0">
                          <label>{{'frete.execucao'|translate}}</label>
                          <input [(ngModel)]="freteFerrovia.dataFerro"
                                 [class.is-invalid]="dataFerro.invalid && dataFerro.touched" name="dataFerro" #dataFerro
                                 required autocomplete="off" class="form-control" bsDatepicker [bsConfig]="{dateInputFormat: 'MM/YYYY'}" (onShown)="aoAbrirCalendario($event)">
                        </div>
                        <div class="form-group mr-md-3 mr-sm-0">
                          <label>{{'frete.valor'|translate}}</label>
                          <input type="number" class="form-control" [(ngModel)]="freteFerrovia.valor"
                                 [class.is-invalid]="ferroValor.invalid && ferroValor.touched" name="ferroValor"
                                 #ferroValor required>
                        </div>
                        <div class="d-flex align-self-end mb-3 mr-md-3 mr-sm-0">
                          <button class="btn btn-success" [disabled]="ferroviaForm.form.invalid"
                                  (click)="cadastrarFrete(2,freteFerrovia.dataFerro,freteFerrovia.valor)">
                            {{'btn.cadastrar'|translate}}
                          </button>
                        </div>
                      </div>
                    </form>
                    <div class="table-gp">
                      <table class="table">
                        <thead class="thead-dark">
                          <tr>
                            <th>
                              {{'tabela.data'|translate}}
                              <select [(ngModel)]="filtroFerrovia.ano" name="anoFerrovia" (change)="obterFreteFerroviario()" *ngIf="anosFerro.length > 0">
                                <option [ngValue]="null">Selecione</option>
                                <option *ngFor="let ano of anosFerro" [ngValue]="ano">{{ano}}</option>
                              </select>
                            </th>
                            <th>
                              {{'tabela.valor'|translate}}
                            </th>
                            <th>
                              {{'tabela.acao'|translate}}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngIf="carregando">
                            <td colspan="3" align="center" style="text-align:center">{{'tabela.carregando'|translate}}</td>
                          </tr>
                          <tr *ngIf="listaFerrovia.length == 0 && !carregando">
                            <td colspan="3" align="center"> {{'tabela.naoEcontrado'|translate}}</td>
                          </tr>
                          <tr *ngFor="let frete of listaFerrovia">
                            <td>{{frete.dataFrete}}</td>
                            <td><input type="number" class="form-control" [(ngModel)]="frete.valor" /></td>
                            <td class="d-flex">
                              <button class="btn btn-success" (click)="atualizarFrete(frete)" title="{{'btn.atualizar'|translate}}"> <i class="mdi mdi-pencil"></i></button>
                              <button class="btn btn-danger ml-2" (click)="inativarFrete(frete)">
                                <i class="mdi mdi-delete"></i>
                              </button>
                            </td>
                          </tr>

                        </tbody>
                      </table>
                      <div class="d-flex justify-content-end">
                        <ngb-pagination [collectionSize]="filtroFerrovia.total" [pageSize]="filtroFerrovia.tamanhoPagina"
                                        [(page)]="filtroFerrovia.numeroPagina" [maxSize]="10" [rotate]="true" [boundaryLinks]="true"
                                        (pageChange)="obterFretePorPraca($event,2)"></ngb-pagination>
                      </div>
                    </div>
                  </div>
              </nb-tab>
              <nb-tab tabTitle="Total">
                  <div class="m-3">
                    <h4>Total</h4>
                    <table class="table" id="tbTotais">
                      <thead>
                        <tr>
                          <td>{{'mês'|translate}}</td>
                          <td>Rodoviário</td>
                          <td>Modal</td>
                          <td>Total</td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngIf="carregando">
                          <td colspan="4" align="center" style="text-align:center">{{'tabela.carregando'|translate}}</td>
                        </tr>
                        <tr *ngIf="listaTotais.length == 0 && !carregando">
                          <td colspan="4" align="center">{{'tabela.naoEcontrado'|translate}}</td>
                        </tr>
                        <tr *ngFor="let c of listaTotais">
                          <td>{{c.mes | date: ' MM/yyyy'}}</td>
                          <td>{{c.rodovia}}</td>
                          <td>{{c.ferrovia}}</td>
                          <td>{{c.total}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
              </nb-tab>
            </nb-tabset>
        </nb-tab>
      </nb-tabset>

      <!--TABELAS EXPORTADAS PARA EXCEL-->
      <div style="display:none">
        <table class="table" id="tbRodovias">
          <thead>
            <tr>
              <td colspan="2">
                {{praca.nomePraca}}
                {{nomePorto}}
              </td>
            </tr>
            <tr>
              <td>{{'tabela.data'|translate}}</td>
              <td>{{'tabela.valor'|translate}}</td>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let frete of listaRodovia">
              <td>{{frete.dataFreteString}}</td>
              <td>{{frete.valor | currency:'R$ '}}</td>
            </tr>
          </tbody>
        </table>
        <table class="table" id="tbFerrovias">
          <thead>
            <tr>
              <td>{{'tabela.data'|translate}}</td>
              <td>{{'tabela.valor'|translate}}</td>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let frete of listaFerrovia">
              <td>{{frete.dataFreteString}}</td>
              <td>{{frete.valor| currency:'R$ '}}</td>
            </tr>
          </tbody>
        </table>
        <table class="table" id="tbTotais">
          <thead>
            <tr>
              <td>{{'mês'|translate}}</td>
              <td>Rodoviário</td>
              <td>Modal</td>
              <td>Total</td>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let c of listaTotais">
              <td>{{c.mes | date: ' MM/yyyy'}}</td>
              <td>{{c.rodovia}}</td>
              <td>{{c.ferrovia}}</td>
              <td>{{c.total}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </nb-card-body>
  </nb-card>
</main>
