import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CotacaoService {
  constructor(private http: HttpClient) { }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  resposta: object = {};

  public obterDolarDoDia(data: Date) {

    return this.http.get<any>(`https://olinda.bcb.gov.br/olinda/servico/PTAX/versao/v1/odata/CotacaoDolarDia(dataCotacao=@dataCotacao)?%40dataCotacao=%27${data.getMonth() + 1}-${data.getDate()}-${data.getFullYear()}%27&%24format=json`);
  }

  public obterDolarPorPeriodo(dataInicial: Date, dataFinal: Date) {

    return this.http.get<any>(`https://olinda.bcb.gov.br/olinda/servico/PTAX/versao/v1/odata/CotacaoDolarPeriodo(dataInicial=@dataInicial,dataFinalCotacao=@dataFinalCotacao)?%40dataInicial=%27${dataInicial.getMonth() + 1}-${dataInicial.getDate()}-${dataInicial.getFullYear()}%27&%40dataFinalCotacao=%27${dataFinal.getMonth() + 1}-${dataFinal.getDate()}-${dataFinal.getFullYear()}%27&%24format=json`);
  }

  public obterCotacaoDolarDeHoje() {
    return this.http.get<any>("https://economia.awesomeapi.com.br/json/last/USD-BRL");
  };
}
