<main>
  <nb-card [nbSpinner]="loading">
    <nb-card-header>
      <h3>{{'Menus.Config' |translate}}</h3>
    </nb-card-header>
    <nb-card-body>
      <div class="d-flex">
        <div class="col-3 d-flex align-self-end mb-3">
          <button class="btn btn-success" (click)="open(novaConfig)">{{'btn.cadastrar' |translate}} <i class="mdi mdi-cog"></i></button>
        </div>
      </div>
      <table class="table">
        <thead class="table-dark">
          <tr>
            <td>{{'configuracoes.nome' |translate}}</td>
            <td>{{'configuracoes.tipoColuna' |translate}}</td>
            <td>{{'configuracoes.valor' |translate}}</td>
            <td>{{'configuracoes.acao' |translate}}</td>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let config of listaConfiguracao">
            <td>
              <input class="form-control" type="text" [(ngModel)]="config.nome" readonly />
            </td>
            <td>
              <select class="form-control" [(ngModel)]="config.tipoColuna">
                <option value="" selected disabled hidden>{{'configuracoes.select.selecione' |translate}}...</option>
                <option [ngValue]="1">{{'configuracoes.select.decimal' |translate}}</option>
                <option [ngValue]="2">{{'configuracoes.select.texto' |translate}}</option>
                <option [ngValue]="3">{{'configuracoes.select.inteiro' |translate}}</option>
                <option [ngValue]="4">{{'configuracoes.select.data' |translate}}</option>
              </select>
            </td>
            <td>
              <input class="form-control" type="{{config.tipoColuna == 1 || config.tipoColuna == 3 ? 'number' : config.tipoColuna == 2 ? 'text' : 'date'}}" [(ngModel)]="config.valor" />
            </td>
            <td>
              <div class="d-flex">
                <button (click)="editarConfig(config)" class="btn btn-outline-success" title="{{'btn.atualizar' |translate}}">
                  <i class="mdi mdi-pencil"></i>
                </button>
                <button class="btn btn-outline-danger ml-1" (click)="open(mymodal);selecionarConfiguracao(config.configuracaoId)">
                  <i class="mdi mdi-delete"></i>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <ngb-pagination [collectionSize]="filtro.total"
                      [pageSize]="filtro.tamanhoPagina"
                      [(page)]="filtro.numeroPagina"
                      [maxSize]="10"
                      [rotate]="true"
                      [boundaryLinks]="true"
                      (pageChange)="obterListaConfiguracao($event)"></ngb-pagination>
    </nb-card-body>
  </nb-card>
  <nb-card [nbSpinner]="carregandoArquivo">
    <nb-card-header>
      {{'configuracoes.termoDeUso' | translate}}
      <span class="float-right" *ngIf="dataAtualizacao != null">{{'configuracoes.utlimaAtualizacao' | translate}}: {{dataAtualizacao | date: ' dd/MM/yyyy HH:mm'}}</span>
    </nb-card-header>
    <nb-card-body>
      <form [formGroup]="fileForm" (ngSubmit)="atualizarTermo()">
        <div class="form-row">
          <div class="form-group col-6 pl-0">
            <label>{{'configuracoes.selecioneUmArquivo' | translate}}</label>
            <input type="file" class="form-control" formControlName="fileInput" nbInput (change)="onImagePicked($event)" accept="application/pdf" />
          </div>
        </div>

        <button class="btn btn-success mr-2" type="submit"><i class="mdi mdi-content-save"></i> {{'btn.salvar'|translate}}</button>
      </form>
    </nb-card-body>
  </nb-card>
</main>

<!-- Modals -->

<ng-template #mymodal let-modal>
  <div class="modal-header">
    <h5 class="modal-title"> {{'configuracoes.inativar' |translate}}</h5>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>    {{'configuracoes.inativar' |translate}}?</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="modal.dismiss('Cross click')" data-dismiss="modal"> {{'btn.fechar' |translate}}</button>
    <button type="button" class="btn btn-danger" (click)="inativarConfiguracao()">{{'sim' |translate}}</button>
  </div>
</ng-template>
<ng-template #novaConfig let-modal>
  <div class="modal-header">
    <h5 class="modal-title">{{'configuracoes.novaConfiguracao' |translate}}</h5>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label>{{'configuracoes.nome' |translate}}</label>
      <input type="text" class="form-control" [(ngModel)]="novaConfig.nome">
    </div>
    <div class="form-group ">
      <label>{{'configuracoes.tipoColuna' |translate}}</label>
      <select class="form-control" [(ngModel)]="novaConfig.tipoColuna">
        <option value="" selected disabled hidden>{{'configuracoes.select.selecione' |translate}}...</option>
        <option [ngValue]="1">{{'configuracoes.select.decimal' |translate}}</option>
        <option [ngValue]="2">{{'configuracoes.select.texto' |translate}}</option>
        <option [ngValue]="3">{{'configuracoes.select.inteiro' |translate}}</option>
        <option [ngValue]="4">{{'configuracoes.select.data' |translate}}</option>
      </select>
    </div>
    <div class="form-group">
      <label>{{'configuracoes.valor' |translate}}</label>
      <input type="{{novaConfig.tipoColuna == 1 || novaConfig.tipoColuna == 3 ? 'number' : novaConfig.tipoColuna == 2 ? 'text' : 'date'}}" [(ngModel)]="novaConfig.valor" class="form-control">
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="modal.dismiss('Cross click')" data-dismiss="modal">{{'btn.fechar' |translate}}</button>
    <button type="button" class="btn btn-success" (click)="cadastrarConfig(novaConfig.nome,novaConfig.tipoColuna,novaConfig.valor)">{{'btn.cadastrar' |translate}}</button>
  </div>
</ng-template>
