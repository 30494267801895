import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ServicoCBotService {
  constructor(private http: HttpClient) { }

  baseUrl = "api/ServicoCBot/"

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8'
    })
  };

  public obterTodos() {
    return this.http.get<any>(this.baseUrl, this.httpOptions);
  };

  public getAllTipoUsuario() {
    return this.http.get<any>('api/perfil/GetAllTiposUsuario');
  };

  public ObterDoUsuarioLogado() {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        'SemOverlay': 'true'
      })
    }
    return this.http.get<any>(this.baseUrl + "ObterDoUsuarioLogado", this.httpOptions);
  };

}
