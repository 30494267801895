<main>

  <nb-card [nbSpinner]="carregandoPortos" nbSpinnerStatus="basic">
    <nb-card-header>
        <h3>{{'Menus.Premio'|translate}}</h3>
    </nb-card-header>
    <nb-card-body>
      <nb-tabset (changeTab)="carregarPremiosPorPorto($event)" >
        <nb-tab *ngFor="let porto of portosList" tabId="{{porto.portoId}}" tabTitle="{{porto.nome}}" active="{{abaAtiva(porto.portoId)}}">
          <br />
          <div class="d-flex">
            <div class=" align-self-end mb-3">
              <button class="btn btn-success" (click)="open(novoPremio)">{{'btn.novo'|translate}} <i class="mdi mdi-plus"></i></button>
            </div>
          </div>
          <div class="form-group col-2 pl-0">
            <label>{{'premio.grao' |translate}}</label>
            <select class="form-control" [(ngModel)]="filtro.grao" (change)="obterListaPremio(1)">
              <option *ngFor="let grao of listaGraos" [ngValue]="grao.idProduto">
                {{grao.nomeProduto}}
              </option>
            </select>
          </div>
          <div class="table-gp">
            <table class="table">
              <thead class="thead-dark">
                <tr>
                  <th>{{'tabela.grao'|translate}}</th>
                  <th>{{'frete.execucao'|translate}}</th>
                  <th>{{'tabela.valor'|translate}}</th>
                  <th>{{'tabela.acao'|translate}}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let premio of listaPremio">
                  <td>{{premio.graoString}}</td>
                  <td><input type="month" class="form-control" [(ngModel)]="premio.dataPremio" readonly /></td>
                  <td>
                    <input type="text" class="form-control" [(ngModel)]="premio.valor" id="inputValorPremio"
                           (click)="focar($event)" currencyMask
                           [options]="{ prefix: '$ ', thousands: '.', decimal: ',',precision:4 }" />
                  </td>
                  <td>
                    <div class="d-flex">
                      <button (click)="editarPremio(premio)" class="btn btn-outline-success mr-2" title="{{'btn.atualizar'|translate}}">
                        <i class="mdi mdi-pencil"></i>
                      </button>
                      <button class="btn btn-outline-danger" (click)="open(mymodal);selecionarPremio(premio.premioId)">
                        <i class="mdi mdi-delete"></i>
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="d-flex justify-content-end">
            <ngb-pagination [collectionSize]="filtro.total" [pageSize]="filtro.tamanhoPagina"
                            [(page)]="filtro.numeroPagina" [maxSize]="10" [rotate]="true" [boundaryLinks]="true"
                            (pageChange)="obterListaPremio($event)"></ngb-pagination>
          </div>

        </nb-tab>
      </nb-tabset>
    </nb-card-body>
  </nb-card>
</main>

<ng-template #mymodal let-modal>
  <nb-card class="dialog-card">
    <nb-card-header>
      <h5 class="modal-title">{{'premio.inativarPremio'|translate}}</h5>

    </nb-card-header>
    <nb-card-body>
      <p>{{'premio.inativarPremio'|translate}}?</p>
    </nb-card-body>
    <nb-card-footer>
      <button type="button" class="btn btn-secondary" (click)="close()"
              data-dismiss="modal">
        {{'btn.fechar'|translate}}
      </button>
      <button type="button" class="btn btn-danger m-1" (click)="inativarPremio()">  {{'sim'|translate}}</button>
    </nb-card-footer>
  </nb-card>
</ng-template>

<ng-template #novoPremio>
  <nb-card class="dialog-card">
    <nb-card-header>
      <h5 class="modal-title"> {{'premio.novoPremio'|translate}}</h5>
    </nb-card-header>
    <nb-card-body>
      <div class="row">
        <div class="form-group p-1">
          <label> {{'premio.grao'|translate}}</label>
          <select class="form-control" [(ngModel)]="grao">
            <option *ngFor="let grao of listaGraos" [ngValue]="grao.idProduto">
              {{grao.nomeProduto}}
            </option>
          </select>
        </div>
        <div class="form-group p-1">
          <label> {{'tabela.data'|translate}}</label>
          <input [(ngModel)]="dataPremio" autocomplete="off" class="form-control" name="date" bsDatepicker [bsConfig]="{dateInputFormat: 'MM/YYYY'}" (onShown)="aoAbrirCalendario($event)">
        </div>

        <div class="form-group p-1">
          <label>{{'tabela.valor'|translate}}</label>
          <input type="text" class="form-control" [(ngModel)]="valor" currencyMask [options]="{ prefix: '$ ', thousands: '.', decimal: ',',precision:4 }" />
        </div>
      </div>

    </nb-card-body>
    <nb-card-footer>
      <button type="button" class="btn btn-secondary" (click)="close()"
              data-dismiss="modal">
        {{'btn.fechar'|translate}}
      </button>
      <button type="button" class="btn btn-success m-1" (click)="cadastrarPremio(grao,dataPremio,valor)">{{'btn.cadastrar'|translate}}</button>
    </nb-card-footer>
  </nb-card>
</ng-template>
