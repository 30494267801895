import { Component, OnInit } from '@angular/core';
import { ToastService } from '../services/toastService';
import { LoginService } from '../services/loginService';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.html',
})
export class Login implements OnInit {

  constructor(private toastService: ToastService, private loginService: LoginService,
    private router: Router, private translate: TranslateService,
    private component: AppComponent) { }

  logando = false;
  enviandoContato = false;
  dados: any = {};
  contato: any = {};
  foneaux: any = "";
  copyright: string;
  ngOnInit(): void {
    this.logando = false;
    this.enviandoContato = false;
    this.copyright = new Date().getFullYear().toString();
  }


  public logar() {
    this.logando = true;
    this.loginService.logIn(this.dados).subscribe(usuario => {
      this.logando = false;
      switch (usuario.codigo) {
        case 200:
          localStorage.setItem('linguagem', usuario.usuarioAutenticado.linguagem);
          this.translate.setDefaultLang(usuario.usuarioAutenticado.linguagem);
          localStorage.setItem('currentUser', JSON.stringify(usuario.usuarioAutenticado));
          this.loginService.currentUserSubject.next(usuario.usuarioAutenticado);
          this.router.navigate(['']);
          this.toastService.show('Login efetuado com sucesso!', { classname: 'bg-success text-light', delay: 5000 });
          this.component.abrirModalAviso();
          break;

        case 98:
          this.router.navigate(['/aguardandoAprovacao']);
          break;

        default:

          this.toastService.show(usuario.mensagem, { classname: 'bg-danger text-light', delay: 5000 });
          break;
      }
        
    })
  }

  enviar() {

    if (!this.contato.email) {
      this.toastService.show("E-mail é obrigatório.", { classname: 'bg-warn text-light', delay: 5000 });
      return;
    }

    if (!this.contato.telefone) {
      this.toastService.show("Telefone é obrigatório.", { classname: 'bg-warn text-light', delay: 5000 });
      return;
    }

    if (!this.contato.nome) {
      this.toastService.show("Nome é obrigatório.", { classname: 'bg-warn text-light', delay: 5000 });
      return;
    }
    this.enviandoContato = true;
    this.loginService.entrarEmContato(this.contato).subscribe(response => {
      this.enviandoContato = false;
      if (response) {
        this.toastService.show("Obrigado pela preferência, em breve entraremos em contato.", { classname: 'bg-success text-light', delay: 5000 })
        this.contato = {};
      }
    });
  }

  maskPhone(evento) {

    if (isNaN(evento.key)) {
      let ultCaractere = this.contato.telefone.substring(this.contato.telefone.length - 1, this.contato.telefone.length);
      if (this.foneaux !== "" && isNaN(ultCaractere)) {
        this.contato.telefone = this.foneaux;
      }
      this.foneaux = this.contato.telefone;
      return;
    } else if (this.foneaux != "" && this.contato.telefone.length > this.foneaux.length) {
      this.contato.telefone = this.foneaux;
    }

    if (this.contato.telefone) {
      if (this.contato.telefone.length == 2) {
        this.contato.telefone = "(" + this.contato.telefone + ") ";
      }
      if (this.contato.telefone.length == 10) {
        this.contato.telefone = this.contato.telefone + "-";
      }
      if (this.contato.telefone.length >= 15) {
        this.contato.telefone = this.contato.telefone.substring(0, 14);
      }
      this.foneaux = "";
      return {};
    }
  }

  transform(rawNum) {
    rawNum = rawNum.charAt(0) != 0 ? "0" + rawNum : "" + rawNum;

    let newStr = "";
    let i = 0;

    for (; i < Math.floor(rawNum.length / 2) - 1; i++) {
      newStr = newStr + rawNum.substr(i * 2, 2) + "-";
    }

    let contato1 = newStr + rawNum.substr(i * 2);
    this.contato.telefone = contato1;
    return contato1;
  }
}
