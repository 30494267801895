import { Component, AfterViewInit, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from './services/loginService';
import { MatDialog } from '@angular/material/dialog';
import { AuthorizationService } from './services/authorizationService';
import { SharedService } from './services/sharedService';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements AfterViewInit, OnInit {
  @ViewChild("updateDatas", { static: true }) private contentRef: TemplateRef<Object>;
  menuVisible: boolean = true;
  modalIsOpen = false;
  modalRef: NgbModalRef;
  constructor(
    private translate: TranslateService,
    private loginService: LoginService,
    private authorizationService: AuthorizationService,
    public dialog: MatDialog,
    private ss: SharedService,
    private modalService: NgbModal,
    private router: Router) {
    this.ss = ss;
    if (this.loginService.currentUserValue != null) {
      this.translate.setDefaultLang(localStorage.getItem('linguagem'));
    } else {
      this.translate.setDefaultLang('ptbr');
    }
  }
 

  ngOnInit() {
    this.ss.getEmittedValue()
      .subscribe(item => this.menuVisible = item);

    this.abrirModalAviso();
  }

  abrirModalAviso() {

    if (this.loginService.currentUserValue != null) {
      this.loginService.precisaAtualizarDados(this.loginService.currentUserValue.usuarioId).subscribe(res => {
        if (res && !this.modalIsOpen) {

          this.open(this.contentRef);
        }

      });
    }
  };

  estaLogado(): boolean {
    return this.authorizationService.estaLogado();
  }

  atualizarDepois() {
    this.loginService.atualizarDepois(this.loginService.currentUserValue.usuarioId).subscribe(res => {
      this.close();
    });
  }

  irParaPerfil() {
    this.router.navigate(['/usuario/editar/' + this.loginService.currentUserValue.usuarioId]);
    this.close();
  }

  close() {
    this.modalIsOpen = false;
    this.modalRef.close();
  }

  open(content) {
    this.modalIsOpen = true;
    this.modalRef = this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
  }



  ngAfterViewInit() {
  }
}
