import { Component } from '@angular/core';
import { EstadoService } from '../../services/estadoService';
import { ToastService } from '../../services/toastService';


@Component({
  selector: 'app-listagemEstado',
  templateUrl: './listagemEstado.html',
  styleUrls: ['./listagemEstado.css'],
})
export class ListagemEstado {
  title = 'ng-bootstrap-modal-demo';
  closeResult: string;
  resposta: object = {};
  listaEstado: any[] = [];
  listaEstadoOriginal: any[] = [];
  pracaSelecionado: any = {};
  filtro: any = {
    tamanhoPagina: 10,
    numeroPagina: 1,
    nomeEstado: ""
  };
  novoEstado: DtoEstado;
  loading = false;
  constructor(private estadoService: EstadoService,
    private toastService: ToastService) {
  }

  ngOnInit() {
    this.obterListaEstado();
  }

  filtrar() {
    if (!this.filtro.nomeEstado) {
      this.limpar();
    }
    this.listaEstado = this.listaEstadoOriginal.filter(
      estado => estado.nomeEstado.toLowerCase().indexOf(this.filtro.nomeEstado.toLowerCase()) !== -1);
  }

  limpar() {
    this.listaEstado = this.listaEstadoOriginal;
    this.filtro.nomeEstado = "";
  }

  ativarInativar(estado) {
    if (estado.ativo == false) {
      this.estadoService.ativar(estado.idEstado).subscribe(response => {
        if (response) {
          estado.ativo = true;
          this.toastService.show("Estado Ativado com sucesso", { classname: 'bg-success text-light', delay: 5000 });
        }
      });
    } else {
      this.estadoService.inativar(estado.idEstado).subscribe(response => {
        if (response) {
          estado.ativo = false;
          this.toastService.show("Estado inativado com sucesso", { classname: 'bg-success text-light', delay: 5000 });
        }
      });
    }
  }

  obterListaEstado() {
    this.loading = true;
    this.estadoService.obterTodos().subscribe(response => {
      this.loading = false;
      if (response) {
        this.listaEstado = response;
        this.listaEstadoOriginal = response;
      }
      else
        this.toastService.show(response.mensagem, { classname: 'bg-danger text-light', delay: 5000 })
    })
  }

  

}
