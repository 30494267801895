export class Calculo {

  public static calcularFobOrigem(
    valueCbot: number,
    valuePremio: number,
    valorFreteFerro: number,
    valorHedgFerroCompra: number,
    valorFreteRodo: number,
    hedgeRodo: number,
    fatorProduto: number
  ): number {
    const a = this.calcularBaseRetencaoGrao(valueCbot, valuePremio, fatorProduto);
    const b = this.calcularFreteRodoFerro(valorFreteFerro, valorHedgFerroCompra, valorFreteRodo, hedgeRodo);

    return parseFloat((Math.round((a - b) * 10000) / 10000).toFixed(4));
  }

  public static calcularTransbordo(
    valueCbot: number,
    valuePremio: number,
    valorFreteFerro: number,
    valorHedgFerroCompra: number,
    fatorProduto: number) {
    var a = this.calcularBaseRetencaoGrao(valueCbot, valuePremio, fatorProduto);
    var b = this.calcularFreteFerro(valorFreteFerro, valorHedgFerroCompra);
    return parseFloat(Math.round(a - b).toFixed(4));

  }

  public static calcularPool(
    valueCbot: number,
    valuePremio: number,
    fatoProduto: number) {
    return parseFloat(Math.round(this.calcularBaseRetencaoGrao(valueCbot, valuePremio, fatoProduto)).toFixed(4));

  }

  public static calcularFreteFerro(
    valorFreteFerro: number,
    valorHedgFerroCompra: number) {
    return parseFloat(Math.round(valorFreteFerro / valorHedgFerroCompra).toFixed(4));
  }

  public static calcularFreteRodoFerro(
    valorFreteFerro: number,
    valorHedgFerroCompra: number,
    valorFreteRodo: number,
    valorHedgRodoCompra: number) {
    return parseFloat(Math.round((valorFreteFerro / valorHedgFerroCompra) + (valorFreteRodo / valorHedgRodoCompra)).toFixed(4));
  }

  public static calcularBaseRetencaoGrao(
    valueCbot: number,
    valuePremio: number,
    fatorProduto: number) {
    return parseFloat(Math.round((valueCbot + valuePremio) * fatorProduto).toFixed(4));
  }
}
