<div class="login-wrapper">

  <div class="login-view d-none d-lg-block">
    <span class="wrapper-hero">
      <h1>Projeto BRIC</h1>
      <p>Auxiliando você a encontrar a precificação de Agrícola.</p>
    </span>
  </div>
  <div class="login-form border">

    <div class="card w-100 border">
      <div class="card-body">
        <form class="col-12" #login="ngForm" novalidate>
          <h4 class="text-center text-dark mb-3">Preencha as informaçoes para se cadastrar</h4>
          <div class="row">

            <div class="col-6">
              <button class="btn btn-link btn-block btn-sm text-info" (click)="passo1=true">1 - Informações da conta</button>
            </div>
            <div class="col-6">
              <button class="btn btn-link btn-block btn-sm text-info" (click)="passo1=false">2 - Informações adicionais</button>
            </div>
          </div>
          <div *ngIf="passo1">
            <h5 class="text-center text-dark mb-3">1- Informações da conta</h5>
            <div class="form-group">
              <label>Qual é o seu perfil?</label>

              <nb-select fullWidth placeholder="Selecione um perfil" [(selected)]="usuario.tipoUsuario">
                <nb-option [value]="tipo.id" *ngFor="let tipo of listaTipoUsuario">{{tipo.descricao}}</nb-option>
              </nb-select>

            </div>
            <div class="form-group">
              <label>Nome</label>
              <input type="text" class="form-control"
                     [class.is-invalid]="Nome.invalid && Nome.touched && Nome.errors.nome" [(ngModel)]="usuario.nome"
                     name="Nome" #Nome required nome>
            </div>
            <div class="form-group">
              <label>Email</label>
              <input type="text" class="form-control"
                     [class.is-invalid]="Email.invalid && Email.touched && Email.errors.email" [(ngModel)]="usuario.email"
                     name="Email" #Email required email>
            </div>
            <div class="form-group">
              <label>Senha</label>
              <input type="password" class="form-control" [class.is-invalid]="Senha.invalid && Senha.touched"
                     [(ngModel)]="usuario.password" name="Senha" #Senha required>
            </div>
            <div class="form-group">
              <nb-select fullWidth multiple placeholder="Selecione os estados que você esta operando" [(selected)]="usuario.estadosDeAcesso">
                <nb-option [value]="estado" *ngFor="let estado of listaEstadosAtivos">{{estado.uf}}</nb-option>
              </nb-select>

            </div>
            <div class="form-group">
              <button class="btn btn-info btn-lg w-100" (click)="passo1 = false" [disabled]="login.form.invalid">Continuar</button>
            </div>
            <div class="col-12 text-center">
              <a href="#" (click)="$event.preventDefault()" [routerLink]='["/sign-in"]'>Já possui uma conta? Acesse</a>
            </div>
          </div>
          <div class="form-group" *ngIf="!passo1">
            <h5 class="text-center text-dark mb-3">2- Informações adicionais</h5>
            <div class="form-group">
              <label>Grupo Empresarial</label>
              <input type="text" class="form-control"
                     [class.is-invalid]="grupoEmpresarial.invalid && grupoEmpresarial.touched && grupoEmpresarial.errors.grupoEmpresarial"
                     [(ngModel)]="usuario.grupoEmpresarial"
                     name="grupoEmpresarial" #grupoEmpresarial required>
            </div>
            <div class="form-group">
              <label>CNPJ</label>
              <input type="text" class="form-control" [textMask]="{mask: cnpjMask}"
                     [class.is-invalid]="cnpj.invalid && cnpj.touched && cnpj.errors.cnpj"
                     [(ngModel)]="usuario.cnpj"
                     name="cnpj" #cnpj required>
            </div>
            <div class="form-group">
              <label>Razão Social</label>
              <input type="text" class="form-control"
                     [class.is-invalid]="razaoSocial.invalid && razaoSocial.touched && razaoSocial.errors.razaoSocial"
                     [(ngModel)]="usuario.razaoSocial"
                     name="razaoSocial" #razaoSocial required>
            </div>
            <div class="form-group">
              <label>Nome Fantasia</label>
              <input type="text" class="form-control"
                     [class.is-invalid]="nomeFantasia.invalid && nomeFantasia.touched && nomeFantasia.errors.nomeFantasia"
                     [(ngModel)]="usuario.nomeFantasia"
                     name="nomeFantasia" #nomeFantasia required>
            </div>
            <div class="form-group">
              <label>Inscrição Estadual</label>
              <input type="text" class="form-control"
                     [class.is-invalid]="inscricaoEstadual.invalid && inscricaoEstadual.touched && inscricaoEstadual.errors.inscricaoEstadual"
                     [(ngModel)]="usuario.inscricaoEstadual"
                     name="inscricaoEstadual" #inscricaoEstadual required>
            </div>
            <div class="form-group">
              <label>Endereço</label>
              <input type="text" class="form-control" placeholder="Exemplo: Rua, número, cidade - estado"
                     [class.is-invalid]="endereco.invalid && endereco.touched && endereco.errors.endereco"
                     [(ngModel)]="usuario.endereco"
                     name="endereco" #endereco required>
            </div>
            <div class="form-group">
              <input type="checkbox" class="checkbox" [class.is-invalid]="termoDeUso.invalid" [(ngModel)]="usuario.aceitouTermos" [ngModelOptions]="{standalone: true}" #termoDeUso required> Li e Aceito os <a href="/Termo/termo.pdf" target="_blank">Termos de Uso</a>
            </div>
            <div class="form-group">
              <button class="btn btn-info btn-lg w-100" (click)="newAccount()" [disabled]="login.form.invalid">Criar uma conta</button>
            </div>
            <div class="col-12 text-center">
              <a href="#" (click)="$event.preventDefault()" [routerLink]='["/sign-in"]'>Já possui uma conta? Acesse</a>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
