import { Component, Injectable, Input, Output, EventEmitter} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  @Output() fire: EventEmitter<any> = new EventEmitter();

  constructor() {
    console.log('shared service started');
  }

  show() {
    console.log('show started');
    this.fire.emit(false);
  }

  hide() {
    console.log('hide started');
    this.fire.emit(true);
  }

  getEmittedValue() {
    return this.fire;
  }

} 
