import { Component, OnInit } from '@angular/core';
import { LoginService } from '../services/loginService';
import { ToastService } from '../services/toastService';
import { Router } from '@angular/router';
import { AuthorizationService } from '../services/authorizationService';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent implements OnInit {
  constructor(
    private toastService: ToastService,
    private loginService: LoginService,
    private router: Router,
    private authorizationService: AuthorizationService) { }

  listaAmbientes: Array<DtoEstado>;
  ambienteSelecionado: any = localStorage.getItem('ambienteSelecionado');;
  isExpanded = false;

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  selecionarAmbiente() {
    if (this.ambienteSelecionado != null) {
      localStorage.setItem('ambienteSelecionado', this.ambienteSelecionado);
      let idUsuario = +this.loginService.currentUserValue.usuarioId;
      let idEstado = +this.ambienteSelecionado;
      if (idUsuario == 0) {
        this.toastService.show("Usuário não encontrado.", { classname: 'bg-danger text-light', delay: 5000 })
      }
      this.loginService.atualizarCookieEstadoSelecionado(idUsuario, idEstado).subscribe(response => {
        if (response == false) {
          this.toastService.show("Houve um erro ao selecionar o ambiente.", { classname: 'bg-danger text-light', delay: 5000 })
        }
        this.recarregarUrl();
      });
    }
  }

  recarregarUrl() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

  carregarAmbientesUsuario() {
    this.listaAmbientes = this.loginService.currentUserValue.estadosDeAcesso;

    if (this.ambienteSelecionado == null && this.listaAmbientes != null && this.listaAmbientes.length > 0)
      this.ambienteSelecionado = this.listaAmbientes[0].idEstado;
    this.selecionarAmbiente();

  }

  podeVisualizarDropDownEstados(): boolean {
    return this.authorizationService.podeVisualizarDropDownEstados();
  }


  ngOnInit() {
    this.carregarAmbientesUsuario();




  }
}
