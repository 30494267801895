<div class="login-wrapper">

  <div class="login-view d-none d-lg-block">
    <span class="wrapper-hero">
      <h1>AgroBric</h1>
      <p>Conectando globalmente a cadeia Agrícola</p>
    </span>
    <div class="col-6" style="z-index: 3;">
      <div class="card w-100 m-2">
        <div class="card-body">
          <h2>Entre em contato</h2>
          <div class="form-group">
            <label>Email</label>
            <input type="text" class="form-control" [(ngModel)]="contato.email"
                   name="EmailContato" #Email [ngModelOptions]="{standalone: true}" required email />
          </div>
          <div class="form-group">
            <label>Telefone</label>
            <input type="text" class="form-control" [(ngModel)]="contato.telefone" (keypress)="maskPhone($event)" />
          </div>
          <div class="form-group">
            <label>Nome</label>
            <input type="text" class="form-control" [(ngModel)]="contato.nome" />
          </div>
          <div class="form-group">
            <button class="btn btn-success btn-lg w-100" (click)="enviar()">
              <span *ngIf="enviandoContato" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Enviar
            </button>
          </div>
        </div>
      </div>
    </div>
    <footer>
      ©LARP Desenvolvimento - Copyright {{copyright}}
    </footer>
  </div>
  <div class="login-form border">
 
    <div class="card w-100 border">
      <div class="card-body">
        <form class="col-12" #login="ngForm" novalidate>
          <span class="logo">
            <img src="../../assets/brand/BRIC_INVERSE.png" alt="Brand" class="img-fluid">
          </span>
          <h3 class="text-center font-weight-bold mb-3" style="color:#015A2C">Bem-vindo ao AgroBric</h3>
          <div class="form-group">
            <label>Email</label>
            <input type="text" class="form-control"
                   [class.is-invalid]="Email.invalid && Email.touched && Email.errors.email" [(ngModel)]="dados.email"
                   name="Email" #Email required email>
          </div>
          <div class="form-group">
            <span class="label-pass">
              <label>Senha</label>
              <a href="#" [routerLink]='["/recuperarSenha"]'>
                Esqueceu a senha?
              </a>
            </span>
            <input type="password" class="form-control" [class.is-invalid]="Senha.invalid && Senha.touched"
                   [(ngModel)]="dados.password" name="Senha" #Senha required>
          </div>
          <div class="form-group">
            <button class="btn btn-success btn-lg w-100" (click)="logar()" [disabled]="login.form.invalid">
              <span *ngIf="logando" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Logar
            </button>
          </div>
          <div class="col-12 text-center">
            <a href="#" (click)="$event.preventDefault()" [routerLink]='["/sign-up"]'>Não tem uma conta? Cadastre-se</a>
          </div>
        </form>
      </div>
    </div>

  </div>
 
</div>
