import { Component } from '@angular/core';
import { ModalDismissReasons, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { PracaService } from '../../services/pracaService';
import { ToastService } from '../../services/toastService';


@Component({
  selector: 'app-listagemPraca',
  templateUrl: './listagemPraca.html',
  styleUrls: ['./listagemPraca.css'],
})
export class ListagemPraca {
  modalOptions: NgbModalOptions;
  title = 'ng-bootstrap-modal-demo';
  closeResult: string;
  resposta: object = {};
  listaPraca: any[] = [];
  pracaSelecionado: any = {};
  public novaPraca :Praca;
  tamanhosPaginas = [25, 50, 75, 100];
  filtro: any = {
    tamanhoPagina: 100,
    numeroPagina: 1,
    tipoFrete: ""
  };

  loading = false;

  constructor(private pracaService: PracaService,
    private toastService: ToastService, private modalService: NgbModal) {
    this.modalOptions = {
      backdrop: 'static',
      backdropClass: 'customBackdrop'
    }
    this.novaPraca = new Praca();
  }

  ngOnInit() {
    this.obterListaPraca(1);
  }

  obterListaPraca(pagina) {
    this.filtro.numeroPagina = pagina;
    this.loading = true;
    this.pracaService.obterListaPraca(this.filtro).subscribe(response => {
      this.loading = false;
      if (response.codigo === 200) {
        this.listaPraca = response.result.retorno;
        this.filtro.total = response.result.qtdTotal;
      }
      else
        this.toastService.show(response.mensagem, { classname: 'bg-danger text-light', delay: 5000 })
    })
  }

  selecionarFrete(id) {
    this.pracaSelecionado = this.listaPraca.find((praca) => {
      return praca.pracaId === id;
    })
  }

  cadastrarPraca(nomePraca) {
    this.novaPraca.NomePraca = nomePraca.toUpperCase();
    this.pracaService.cadastrarPraca(this.novaPraca).subscribe(response => {
      if (response.codigo === 200) {
        this.toastService.show('Praca cadastrada com sucesso!', { classname: 'bg-success text-light', delay: 5000 });
        this.modalService.dismissAll();
        this.listaPraca.push(response.result);
      }
      else {
        this.toastService.show(response.mensagem, { classname: 'bg-danger text-light', delay: 5000 })
      }
    });
  }

  inativarPraca() {
    this.pracaService.inativarPraca(this.pracaSelecionado.pracaId).subscribe(response => {
      if (response.codigo === 200) {
        this.toastService.show('Praca inativado com sucesso!', { classname: 'bg-success text-light', delay: 5000 })
        this.listaPraca = this.listaPraca.filter((praca) => {
          return praca.pracaId !== this.pracaSelecionado.pracaId;
        })
      }
      else {
        this.toastService.show(response.mensagem, { classname: 'bg-danger text-light', delay: 5000 })
      }
      this.modalService.dismissAll();
    });
  }

  open(content) {
    this.novaPraca = new Praca();
    this.modalService.open(content, { size: 'lg', backdrop: false }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

 

}

class Praca {
  NomePraca: string
}
