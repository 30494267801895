import { Component, OnDestroy, OnInit } from '@angular/core';
import { CotacaoService } from '../services/cotacaoService';
import moment from 'moment';
import { Globals } from '../globals/globals';
@Component({
  selector: 'app-cotacao-dolar',
  templateUrl: './cotacao-dolar.component.html',
  styleUrls: ['./cotacao-dolar.component.css']
})
export class CotacaoDolarComponent implements OnInit, OnDestroy {
  resposta: any = {};
  setIntervalId: any;
  constructor(private cotacaoService: CotacaoService, private globals: Globals) { }
   

  ngOnInit() {
    this.obterDolarDoDia();
    this.setIntervalId = setInterval(() => {
      this.obterDolarDoDia();
    }, 7000);
  }

  ngOnDestroy(): void {
    clearInterval(this.setIntervalId);
  }

  obterDolarDoDia() {
    this.cotacaoService.obterCotacaoDolarDeHoje().subscribe(res => {
      var retApi = res.USDBRL; 
      this.resposta = { cotacaoCompra: retApi.bid, cotacaoVenda: retApi.ask, dataHoraCotacao: moment(retApi.create_date).toDate() };
      this.globals.customVariable.next(this.resposta);
    });
  }
}
