<div class="aviso">
  <div style="z-index: 3;">
    <div class="card p-3">
      <div class="d-flex align-items-center flex-column justify-content-center">

        <div class="p-2 text-center">
          <img src="../../assets/brand/BRIC_INVERSE.png" alt="Brand" class="img-fluid">
        </div>

        <div class="p-2">
          <h3>Agora é só aguardar!</h3>
        </div>
        <div class="p-2">
          <h5>Cadastro realizado com sucesso!</h5>
        </div>
        <div class="p-2">
          <strong>Vamos analisar os seus dados e em breve enviremos um e-mail informando que o seu acesso está liberado</strong>
        </div>
        <div>
          <button class="btn btn-success btn-lg w-100" [routerLink]='["/sign-in"]'>Voltar para a tela inicial  <i class="mdi mdi-home"></i></button>
        </div>
      </div>
    </div>
  </div>
</div>
