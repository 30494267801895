<main>
  <nb-card [nbSpinner]="carregando">
    <nb-card-header>
      <h3>{{'Menus.Componente' | translate}}</h3>
    </nb-card-header>
    <nb-card-body>
      <div class="d-flex">
       
      </div>
      <div class="d-md-flex d-sm-block justify-content-between align-items-center">
        <div class="d-flex align-items-end">
          <div class="form-group">
            <label>{{'componente.nome'|translate}}</label>
            <input type="text" class="form-control" [(ngModel)]="filtro.nomeComponente">
          </div>

          <button class="btn btn-success mb-3 ml-3" (click)="obterListaComponente(1)">{{'btn.filtrar'|translate}} <i class="mdi mdi-filter"></i></button>
        </div>
        <button class="btn btn-success" (click)="limparCampo();open(novoComponente);">{{'btn.cadastrar' | translate}} <i class="mdi mdi-rhombus-split"></i></button>
      </div>
      <div class="fixTableHead table-gp">
        <div class="form-group float-right">
          <label>{{'filtro.tamanhoPagina'|translate}}</label>
          <select class="form-control" [(ngModel)]="filtro.tamanhoPagina" name="tamanhoPagina" (change)="obterListaComponente(1)">
            <option *ngFor="let tamanhoPagina of tamanhosPaginas" [ngValue]="tamanhoPagina">
              {{tamanhoPagina}}
            </option>
          </select>
        </div>
        <table class="table">
          <thead class="thead-dark">
            <tr>
              <th>{{'tabela.nome' | translate}}</th>
              <th>{{'porto.nome' | translate}}</th>
              <th>{{'tabela.situacao' | translate}}</th>
              <th>{{'tabela.dataCadastro' | translate}}</th>
              <th>{{'tabela.acao' | translate}}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let componente of listaComponente">
              <td>{{componente.nome}}</td>
              <td>{{componente.portoNome}}</td>
              <td>{{componente.ativo ? 'Ativo' : 'Inativo'}}</td>
              <td>{{componente.dtCadastro | date:'dd/MM/yyyy HH:mm'}}</td>
              <td>
                <div class="d-flex">
                  <button class="btn btn-outline-danger mr-2" (click)="open(mymodal);selecionarComponente(componente.componenteId,false)">
                    <i class="mdi mdi-delete"></i>
                  </button>

                  <button class="btn btn-outline-warning" (click)="open(edicaoComponente);selecionarComponente(componente.componenteId,true)">
                    <i class="mdi mdi-table-edit"></i>
                  </button>

                  <button class="btn btn-outline-success ml-2" title="{{'btn.editar'|translate}}" [routerLink]="['/componenteItem/'+componente.componenteId]">
                    <i class="mdi mdi-format-list-bulleted"></i>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="d-flex justify-content-end">
        <ngb-pagination [collectionSize]="filtro.total"
                        [pageSize]="filtro.tamanhoPagina"
                        [(page)]="filtro.numeroPagina"
                        [maxSize]="10"
                        [rotate]="true"
                        [boundaryLinks]="true"
                        (pageChange)="obterListaComponente($event)"></ngb-pagination>
      </div>

    </nb-card-body>
  </nb-card>
</main>


<ng-template #mymodal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">{{'componente.inativarComponente'|translate}}</h5>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>
      {{'componente.inativarComponente'|translate}}?
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="modal.dismiss('Cross click')" data-dismiss="modal">{{'fechar'|translate}}</button>
    <button type="button" class="btn btn-danger" (click)="inativarComponente()">{{'sim'|translate}}</button>
  </div>
</ng-template>


<ng-template #novoComponente let-modal>
  <div class="modal-header">
    <h5 class="modal-title">{{'componente.novoComponente'|translate}}</h5>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body d-flex">
    <div class="form-group col-4 pl-0">
      <label>{{'titulo.selecioneUmPorto'|translate}}</label>
      <select class="form-control" [(ngModel)]="novoComponente.portoId">
        <option [ngValue]="null">Selecione</option>
        <option *ngFor="let porto of listaPortos" [ngValue]="porto.portoId">
          {{porto.nome}}
        </option>
      </select>
    </div>
    <div class="form-group col-8 pl-0">
      <label>{{'tabela.nome'|translate}}</label>
      <input type="text" class="form-control" [(ngModel)]="novoComponente.nome" name="nome">
    </div>

  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="modal.dismiss('Cross click')" data-dismiss="modal">{{'btn.fechar'|translate}}</button>
    <button type="button" class="btn btn-success" (click)="cadastrarComponente(novoComponente.nome, novoComponente.portoId)">{{'btn.cadastrar'|translate}}</button>
  </div>
</ng-template>

<ng-template #edicaoComponente let-modal>
  <div class="modal-header">
    <h5 class="modal-title">{{'componente.edicaoComponente'|translate}}</h5>

    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body d-flex">
    <div class="form-group col-4 pl-0">
      <label>{{'titulo.selecioneUmPorto'|translate}}</label>
      <select class="form-control" [(ngModel)]="edComponente.portoId">
        <option [ngValue]="null">Selecione</option>
        <option *ngFor="let porto of listaPortos" [ngValue]="porto.portoId">
          {{porto.nome}}
        </option>
      </select>
    </div>
    <div class="form-group col-8 pl-0">
      <label>{{'tabela.nome'|translate}}</label>
      <input type="text" class="form-control" [(ngModel)]="edComponente.nome" name="nomeed">
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="modal.dismiss('Cross click')" data-dismiss="modal">{{'fechar'|translate}}</button>
    <button type="button" class="btn btn-success" (click)="editarComponente()">{{'btn.atualizar'|translate}}</button>
  </div>
</ng-template>
