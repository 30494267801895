import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class ProdutoService {
    constructor(private http: HttpClient) { }

    baseUrl = "api/Produto/"

    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json; charset=utf-8'
        })
    }

    public obterListaProduto() {
      return this.http.get<any>(this.baseUrl + "ObterTodos", this.httpOptions);
    }
}
