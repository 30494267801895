
<div class="d-flex">
  <div class="col-10 d-none d-lg-block">
    <nav class="gp-nav">
      <a class="gp-navbar-link" [routerLink]="['/']">
        <img src="../../assets/brand/BRIC.png" class="gp-navbar-brand" />
        BRIC
      </a>

    </nav>
  </div>
  <div class="col-md-12 col-xl-2 col-lg-12 col-sm-12" *ngIf="listaAmbientes.length > 0">
    <div class="input-group input-group-sm">
      <select class="form-control" [(ngModel)]="ambienteSelecionado" name="ambienteSelecionado" required (change)="$event.preventDefault();selecionarAmbiente()">
        <option *ngFor="let ambiente of listaAmbientes" value="{{ambiente.idEstado}}">{{ambiente.nomeEstado}}</option>
      </select>

    </div>
  </div>

</div>
<app-cotacao-dolar class="navbar" style="display:block; top:40px"></app-cotacao-dolar>

