import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DtoUsuarioAutenticado } from '../dtos/DtoUsuarioAutenticado';

@Injectable({
  providedIn: 'root'
})
export class TipoUsuarioService {
  

  constructor(private http: HttpClient) {
    
  }

  baseUrl = "api/TipoUsuario/"

  httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET',
      'Content-Type': 'application/json',
    })
  }

  public getAll() {
    return this.http.get<any>(this.baseUrl + 'getAll');
  }

}
