import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PremioService {
  constructor(private http: HttpClient) { }

  baseUrl = "api/Premio/"

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8'
    })
  }


  public cadastrarPremio(dto) {
    return this.http.post<any>(this.baseUrl + "CadastrarPremio", JSON.stringify(dto), this.httpOptions);
  }

  public obterListaFrete(dto) {
    return this.http.post<any>(this.baseUrl + "ObterListaPremio", JSON.stringify(dto), this.httpOptions);
  }

  public editarPremio(dto) {
    return this.http.post<any>(this.baseUrl + "EditarPremio", JSON.stringify(dto), this.httpOptions);
  }

  public inativarPremio(dto) {
    return this.http.post<any>(this.baseUrl + "InativarPremio", JSON.stringify(dto), this.httpOptions);
  }

  public obterPremiosPrecificacao(idProduto, idPorto) {
    return this.http.get<any>(this.baseUrl + "ObterPremiosPrecificacao?idProduto=" + idProduto + "&idPorto=" + idPorto, this.httpOptions);
  }
}
