import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PrecificacaoService {
  constructor(private http: HttpClient) { }

  baseUrl = "api/Precificacao/"

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8'
    })
  }

  public cadastrarPrecificacao(dto) {
    return this.http.post<any>(this.baseUrl + "CadastrarPrecificacao", JSON.stringify(dto), this.httpOptions);
  }

  public cadastrarPrecificacaoMilho(dto) {
    return this.http.post<any>(this.baseUrl + "CadastrarPrecificacaoMilho", JSON.stringify(dto), this.httpOptions);
  }

  public obterListaPrecificacao(dto) {
    return this.http.post<any>(this.baseUrl + "ObterListaPrecificacao", JSON.stringify(dto), this.httpOptions);
  }

  public obterListaPrecificacaoPorUsuario(dto) {
    return this.http.post<any>(this.baseUrl + "ObterListaPrecificacaoPorUsuario", JSON.stringify(dto), this.httpOptions);
  }

  public mudarStatus(dto) {
    return this.http.post<any>(this.baseUrl + "MudarStatus", JSON.stringify(dto), this.httpOptions);
  }

  public obterListaNegocioConcluido(dto) {
    return this.http.post<any>(this.baseUrl + "ObterListaNegocioConcluido", JSON.stringify(dto), this.httpOptions);
  }

  public obterFretePorUsuario(dto) {
    return this.http.post<any>(this.baseUrl + "ObterFretePorUsuario", JSON.stringify(dto), this.httpOptions);
  }

  public atualizarValorCbot(dto) {
    return this.http.post<any>(this.baseUrl + "AtualizarValorCbot", JSON.stringify(dto), this.httpOptions);
  }

  public obterBoleta(negocioId) {
    return this.http.get<any>(this.baseUrl + `ObterBoleta/${negocioId}`, this.httpOptions);
  }

  public salvarBoleta(dto) {
    return this.http.post<any>(this.baseUrl + `SalvarBoleta/`, dto, this.httpOptions);
  }
}
