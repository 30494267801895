import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CBoatService {
  constructor(private http: HttpClient) { }

  httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET',
      'Content-Type': 'application/json',
    })
  }

  resposta: object = {};

  public obterTabelaCboat() {
    return this.http.get<any>(`/chicagoBoat/CmeWS/mvc/Quotes/Future/320/G?quoteCodes=&_=1574987125256`, this.httpOptions);
  }
}
