<main>
  <nb-card>
    <nb-card-header>
      <div class="p-2">
        <div>
          <h3>{{'Menus.BookOfertas'|translate}}</h3>
        </div>
        <div class="form-group col-12 pl-0">
          <div class="row align-items-end">
            <div class="col-6">
              <label>Grão</label>
              <select class="form-control col-sm-6 col-xl-4" [(ngModel)]="filtroPrec.idProduto" name="grao" (change)="atualizarListageCbotEBookOfertas()">
                <option *ngFor="let grao of listaGraos" [ngValue]="grao.idProduto">
                  {{grao.nomeProduto}}
                </option>
              </select>
            </div>
            <div class="col-6">

              <button class="btn btn-sm btn-outline-dark float-right" (click)="rotaAtual() === '/bookOfertas/true' ?  voltarParaBookOfertas() : expandir()" title="Expandir">
                <i class="mdi mdi-arrow-expand  mdi-24px"></i>
              </button>

              <button class="btn btn-sm btn-outline-dark float-right" (click)="abrirofertasExpandidoEmNovaTab()" title="Em outra janela">
                <i class="mdi mdi-book-open-page-variant  mdi-24px"></i>
              </button>

              <button class="btn btn-sm btn-outline-dark float-right" (click)="showCard=!showCard;mostrarTitulo=!mostrarTitulo; adicionarStiloHeader();" title="Mudar visualização">
                <i class="mdi mdi-view-module mdi-24px"></i>
              </button>
            </div>
          </div>
          <div class="pt-2" *ngIf="showCard">
            <input class="form-control" type="text" name="search" [(ngModel)]="searchText" (ngModelChange)="searchKey($event)" placeholder="Procure uma oferta">
          </div>
        </div>
      </div>

    </nb-card-header>
    <nb-card-body [nbSpinner]="carregando" nbSpinnerStatus="success">
      <div class="table-responsive text-left" *ngIf="!showCard">
        <table class="table table-bordered table-striped table-sm " mat-table matSort (matSortChange)="sortData($event)" [dataSource]="dataSource">

          <tr mat-header-row style="background: #85888b; " *matHeaderRowDef="['coluna1', 'colunasOfertas', 'colunasBids', 'colunasCbots', 'ultimaColuna'];sticky: true"></tr>
          <tr mat-header-row *matHeaderRowDef="['nomeComMoeda', 'porto', 'premioStr', 'valoresOferta', 'ofertaPremio', 'valoresBid', 'bidPremio', 'cbot', 'cbotValor','opcoes'];sticky: true"></tr>

          <ng-container matColumnDef="coluna1">
            <th mat-header-cell colspan="2" *matHeaderCellDef></th>
          </ng-container>

          <ng-container matColumnDef="colunasOfertas">
            <th mat-header-cell colspan="3" class="text-center" *matHeaderCellDef>OFERTA</th>
          </ng-container>

          <ng-container matColumnDef="colunasBids">
            <th mat-header-cell colspan="2" class="text-center" *matHeaderCellDef>BID</th>
          </ng-container>

          <ng-container matColumnDef="colunasCbots">
            <th mat-header-cell colspan="2" class="text-center" *matHeaderCellDef>CBOT</th>
          </ng-container>

          <ng-container matColumnDef="ultimaColuna">
            <th mat-header-cell *matHeaderCellDef></th>
          </ng-container>
          <ng-container matColumnDef="nomeComMoeda">
            <th mat-header-cell *matHeaderCellDef>
              <div style="display: flex; align-items: center;">
                <span>Nome</span>

                <i class="mdi mdi-filter-variant mdi-24px" [matMenuTriggerFor]="menu"
                   [ngStyle]="jaFiltrado('nomeComMoeda') ? { color: '#28a745' } : ''"
                   [matMenuTriggerData]="{columnName: 'nomeComMoeda'}"
                   (menuOpened)="onMenuOpened('nomeComMoeda')"> </i>
              </div>
            </th>
            <td mat-cell *matCellDef="let element"> {{element.nome}} </td>
          </ng-container>

          <ng-container matColumnDef="porto">
            <th mat-header-cell *matHeaderCellDef>
              <div style="display: flex; align-items: center;">
                <span>Porto</span>

                <i class="mdi mdi-filter-variant mdi-24px" [matMenuTriggerFor]="menu"
                   [ngStyle]="jaFiltrado('porto') ? { color: '#28a745' } : ''"
                   (menuOpened)="onMenuOpened('porto')"
                   [matMenuTriggerData]="{columnName: 'porto'}"> </i>
              </div>
            </th>
            <td mat-cell *matCellDef="let element"> {{element.porto.trim()}} </td>
          </ng-container>

          <ng-container matColumnDef="premioStr">
            <th *matHeaderCellDef>
              <div style="display: flex; align-items: center;">
                <span>Exec</span>

                <i class="mdi mdi-filter-variant mdi-24px" [matMenuTriggerFor]="menu"
                   [ngStyle]="jaFiltrado('premioStr') ? { color: '#28a745' } : ''"
                   (menuOpened)="onMenuOpened('premioStr')"
                   [matMenuTriggerData]="{columnName: 'premioStr'}"> </i>
              </div>
            </th>
            <td mat-cell *matCellDef="let element"> {{element.premioStr}} </td>
          </ng-container>

          <ng-container matColumnDef="valoresOferta">
            <th mat-header-cell *matHeaderCellDef>
              <div style="display: flex; align-items: center;">
                <span>Preço</span>

                <i class="mdi mdi-filter-variant mdi-24px" [matMenuTriggerFor]="menu"
                   [ngStyle]="jaFiltrado('valoresOferta') ? { color: '#28a745' } : ''"
                   (menuOpened)="onMenuOpened('valoresOferta')"
                   [matMenuTriggerData]="{columnName: 'valoresOferta'}"> </i>
              </div>
            </th>
            <td mat-cell *matCellDef="let precificacao">
              <span class="{{marcarComoSelecionado(2, MoedaEnum.DOLAR,precificacao)}}">
                {{precificacao.oferta | currency}}
              </span> |
              <span class="{{marcarComoSelecionado(2,MoedaEnum.REAL,precificacao)}}">
                {{precificacao.ofertaRs | currency:'BRL'}}
              </span>
              <span *ngIf="mostrarNegocioSelecionado(2,precificacao)">
                &emsp;<span class="badge badge-pill badge-primary tipoNegocio float-right">
                  {{mostrarNegocioSelecionadoSiglaValor("Venda", precificacao)}}
                </span>
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="ofertaPremio">
            <th mat-header-cell *matHeaderCellDef>
              <div style="display: flex; align-items: center;">
                <span>Prêmio</span>

                <i class="mdi mdi-filter-variant mdi-24px" [matMenuTriggerFor]="menu"
                   [ngStyle]="jaFiltrado('ofertaPremio') ? { color: '#28a745' } : ''"
                   (menuOpened)="onMenuOpened('ofertaPremio')"
                   [matMenuTriggerData]="{columnName: 'ofertaPremio'}"> </i>
              </div>
            </th>
            <td mat-cell *matCellDef="let precificacao"> {{ precificacao.ofertaPremio | currency}} </td>
          </ng-container>

          <ng-container matColumnDef="valoresBid">
            <th mat-header-cell *matHeaderCellDef>
              <div style="display: flex; align-items: center;">
                <span>Preço</span>

                <i class="mdi mdi-filter-variant mdi-24px" [matMenuTriggerFor]="menu"
                   [ngStyle]="jaFiltrado('valoresBid') ? { color: '#28a745' } : ''"
                   (menuOpened)="onMenuOpened('valoresBid')"
                   [matMenuTriggerData]="{columnName: 'valoresBid'}"> </i>
              </div>
            </th>
            <td mat-cell *matCellDef="let precificacao">
              <span class="{{marcarComoSelecionado(1, MoedaEnum.DOLAR,precificacao) }} ">
                {{precificacao.bid | currency}}
              </span> |
              <span class="{{marcarComoSelecionado(1,MoedaEnum.REAL,precificacao)}}">
                {{precificacao.bidRs | currency:'BRL'}}
              </span>
              <ng-compent *ngIf="mostrarNegocioSelecionado(1,precificacao)">
                &emsp;<span class="badge badge-pill badge-primary tipoNegocio float-right">
                  {{mostrarNegocioSelecionadoSiglaValor("Compra", precificacao)}}
                </span>
              </ng-compent>
            </td>
          </ng-container>

          <ng-container matColumnDef="bidPremio">
            <th mat-header-cell *matHeaderCellDef>
              <div style="display: flex; align-items: center;">
                <span>Prêmio</span>

                <i class="mdi mdi-filter-variant mdi-24px" [matMenuTriggerFor]="menu"
                   [ngStyle]="jaFiltrado('bidPremio') ? { color: '#28a745' } : ''"
                   (menuOpened)="onMenuOpened('bidPremio')"
                   [matMenuTriggerData]="{columnName: 'bidPremio'}"> </i>
              </div>
            </th>
            <td mat-cell *matCellDef="let precificacao">{{precificacao.bidPremio | currency}}</td>
          </ng-container>

          <ng-container matColumnDef="cbot">
            <th mat-header-cell *matHeaderCellDef>
              <div style="display: flex; align-items: center;">
                <span>Mês</span>

                <i class="mdi mdi-filter-variant mdi-24px" [matMenuTriggerFor]="menu"
                   [ngStyle]="jaFiltrado('cbot') ? { color: '#28a745' } : ''"
                   (menuOpened)="onMenuOpened('cbot')"
                   [matMenuTriggerData]="{columnName: 'cbot'}"> </i>
              </div>
            </th>
            <td mat-cell *matCellDef="let precificacao">{{precificacao.cbot}}</td>
          </ng-container>

          <ng-container matColumnDef="cbotValor">
            <th mat-header-cell *matHeaderCellDef>
              <div style="display: flex; align-items: center;">
                <span>Valor</span>

                <i class="mdi mdi-filter-variant mdi-24px" [matMenuTriggerFor]="menu"
                   [ngStyle]="jaFiltrado('cbotValor') ? { color: '#28a745' } : ''"
                   (menuOpened)="onMenuOpened('cbotValor')"
                   [matMenuTriggerData]="{columnName: 'cbotValor'}"> </i>
              </div>
            </th>
            <td mat-cell *matCellDef="let precificacao">{{atualizarValorCbot(precificacao) | currency}}</td>
          </ng-container>

          <ng-container matColumnDef="opcoes">
            <th *matHeaderCellDef> Ações </th>
            <td mat-cell *matCellDef="let precificacao">
              <div class="d-md-none d-md-table-cell d-lg-none">
                <button type="button" class="btn btn-sm btn-outline-secondary mr-2"
                        [ngbPopover]="popContent" [autoClose]="'outside'"
                        triggers="manual" #p1="ngbPopover" (click)="toggleWithGreeting(p1, precificacao)">
                  <i class="mdi mdi-dots-horizontal"></i>
                </button>
              </div>
              <div class="d-none d-lg-table-cell">
                <button class="btn btn-sm btn-outline-success mr-2" data-toggle="tooltip" data-placement="top" title="{{'tabela.bookOfertas.fecharNegocio'|translate}}"
                        (click)="concluir(modalConcluirNegociacao,precificacao)">
                  <i class="mdi mdi-check"></i>
                </button>
                <button class="btn btn-sm btn-outline-info mr-2" data-toggle="tooltip" data-placement="top" title="{{'tabela.bookOfertas.informacoes'|translate}}"
                        (click)="open(mymodal,precificacao);">
                  <i class="mdi mdi-magnify"></i>
                </button>
                <button class="btn btn-sm btn-outline-danger" data-toggle="tooltip" data-placement="top" title="{{'tabela.bookOfertas.cancelar'|translate}}"
                        (click)="status(precificacao,3)">
                  <i class="mdi mdi-close"></i>
                </button>
              </div>
            </td>
          </ng-container>

          <tr mat-row *matRowDef="let row; columns: ['nomeComMoeda', 'porto', 'premioStr', 'valoresOferta', 'ofertaPremio', 'valoresBid', 'bidPremio', 'cbot', 'cbotValor', 'opcoes']"></tr>

        </table>
      </div>
      <div class="container-fluid" *ngIf="showCard">
        <div class="row justify-content-center">
          <div class="" *ngFor="let precificacao of ofertasFiltradas" style="width:236px;margin:10px">

            <div class="card bg-light">
              <div class="card-header bg-dark text-white">
                <div class="row">

                  <div class="col-10">
                    {{precificacao.nome}}
                  </div>
                  <div class="col-2">
                    <div class="row">
                      <button class="btn btn-dark btn-sm" (click)="$event.preventDefault();status(precificacao,3)"><i class="mdi mdi-close"></i></button>
                    </div>
                  </div>

                </div>

              </div>
              <div class="card-body pt-0">
                <!--<h6 class="card-title"><small>Compra - SOJA</small></h6>-->
                <div class="row">
                  <table class="table table-sm mb-0" style="font-size:10px">
                    <tr>
                      <th>
                        Produto
                      </th>
                      <td class="text-right">
                        {{precificacao.produto}}
                      </td>
                      <th>
                        Tipo
                      </th>
                      <td class="text-right">
                        {{precificacao.tipoNegocioString}}
                      </td>

                    </tr>
                    <tr>
                      <th>
                        CBOT
                      </th>
                      <td class="text-right">
                        {{precificacao.cbot}}
                      </td>
                      <th>
                        Valor
                      </th>
                      <td class="text-right">
                        {{atualizarValorCbot(precificacao) | number:'1.2-2'}}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        {{mostrarNomeOposto(precificacao)}} U$
                      </th>
                      <td class="text-right" [ngClass]="{'text-success' : validarCorVerde(precificacao),'text-danger': validarCorVermelho(precificacao)}">
                        {{mostrarPrecoDolarOposto(precificacao) | number:'1.2-2'}}
                      </td>
                      <th>
                        R$
                      </th>
                      <td class="text-right" [ngClass]="{'text-success' : validarCorVerde(precificacao,true),'text-danger':  validarCorVermelho(precificacao,true)}">
                        {{mostrarPrecoRealOposto(precificacao) | number:'1.2-2'}}
                      </td>
                    </tr>

                    <tr *ngIf="precificacao.verMais">
                      <th colspan="4" class="bg-secondary text-white">
                        Prêmio
                      </th>
                    </tr>
                    <tr *ngIf="precificacao.verMais">
                      <td colspan="2">
                        {{precificacao.premioStr}}
                      </td>
                      <th>
                        Valor
                      </th>
                      <td class="text-right">
                        {{ premioOposto(precificacao) | number:'1.2-2'}}
                      </td>
                    </tr>

                    <tr *ngIf="precificacao.verMais">
                      <td colspan="2">
                        {{precificacao.porto}}
                      </td>
                      <th>
                        Valor
                      </th>
                      <td class="text-right">
                        {{precificacao.total | number:'1.2-2'}}
                      </td>
                    </tr>

                    <tr *ngIf="precificacao.verMais">
                      <th colspan="4" class="bg-secondary text-white">
                        Frete
                      </th>
                    <tr *ngIf="precificacao.verMais">
                      <th>
                        Rodo
                      </th>
                      <td class="text-right">
                        {{precificacao.frete}}
                      </td>
                      <th>
                        Valor
                      </th>
                      <td class="text-right">
                        {{precificacao.valorFreteRodo | number:'1.2-2'}}
                      </td>
                    </tr>
                    <tr *ngIf="precificacao.verMais">
                      <th>
                        Ferro
                      </th>
                      <td class="text-right">
                        {{precificacao.freteFerro}}
                      </td>
                      <th>
                        Valor
                      </th>
                      <td class="text-right">
                        {{precificacao.valorFreteFerro | number:'1.2-2'}}
                      </td>
                    </tr>
                    <tr *ngIf="precificacao.verMais">
                      <th colspan="4" class="bg-secondary text-white">
                        HEDGE
                      </th>
                    </tr>

                    <tr *ngIf="precificacao.verMais">
                      <th>
                        Mercado
                      </th>
                      <td class="text-right">
                        {{hedgeMercado(precificacao.tipoNegocioString,precificacao)  | number:'1.2-2'}}
                      </td>
                      <th>
                        Data
                      </th>
                      <td class="text-right">
                        {{precificacao.dataMercString}}
                      </td>
                    </tr>

                    <tr *ngIf="precificacao.verMais">
                      <th>
                        Ferro
                      </th>
                      <td class="text-right">
                        {{ hedgeFerro(precificacao.tipoNegocioString,precificacao)  | number:'1.2-2'}}
                      </td>
                      <th>
                        Data
                      </th>
                      <td class="text-right">
                        {{precificacao.dataFerroString}}
                      </td>
                    </tr>

                    <tr *ngIf="precificacao.verMais">
                      <th>
                        Rodo
                      </th>
                      <td class="text-right">
                        {{ hedgeRodo(precificacao.tipoNegocioString,precificacao) | number:'1.2-2'}}
                      </td>
                      <th>
                        Data
                      </th>
                      <td class="text-right">
                        {{precificacao.dataRodoString}}
                      </td>
                    </tr>

                  </table>
                  <button class="btn btn-link text-dark btn-block " (click)="precificacao.verMais =!precificacao.verMais ">
                    <span *ngIf="precificacao.verMais == false" class="font-weight-bold"> <i class="mdi mdi-chevron-down"></i> ver mais </span>

                    <span *ngIf="precificacao.verMais == true"> <i class="mdi mdi-chevron-up"></i> ver menos </span>

                  </button>

                </div>

              </div>
              <div class="card-footer text-center bg-transparent">
                <strong class="row justify-content-center">{{tipoNegocio(precificacao)}}</strong>
                <div class="row justify-content-center">
                  <span class="{{marcarComoSelecionado(precificacao.tipoNegocio, MoedaEnum.DOLAR,precificacao) }}"><strong> U$ {{valorDolarPorTipoNegocio(precificacao) | number:'1.2-2' }} </strong> </span> |
                  <span class="{{marcarComoSelecionado(precificacao.tipoNegocio, MoedaEnum.REAL,precificacao) }}"><strong> R$ {{valorRealPorTipoNegocio(precificacao) | number:'1.2-2'}} </strong> </span>
                </div>
                <small class="text-center"> CBOT {{precificacao.ofertaCbot | number:'1.2-2'}} | Prêmio {{ premioSelecionado(precificacao) | number:'1.2-2'}}</small>

                <button class="btn btn-block btn-outline-success" (click)="$event.preventDefault();concluir(modalConcluirNegociacao,precificacao)">Concluir Negócio</button>
              </div>
            </div>

          </div>

        </div>
      </div>
    </nb-card-body>
  </nb-card>



  <mat-menu #menu="matMenu" class="matMenu">
    <ng-template matMenuContent let-dataColumnName="columnName">
      <nb-card class="flex-column" (click)="$event.stopPropagation();">
        <nb-card-header>Filtro</nb-card-header>
        <nb-card-body>
          <div class="mb-3">

            <select class="form-control col-sm-12 col-xl-12" #selectedOperationFilter [(ngModel)]="selectedOption">
              <option value="contains" select>Contém</option>
              <option value="equals">Igual</option>
              <option value="greaterThan">Maior que</option>
              <option value="lessThan">Menor que</option>
              <option value="endWith">Termina com</option>
              <option value="startWith">Começa com</option>
            </select>
          </div>

          <div class="mb-3">
            <label>Escreva</label>
            <input class="form-control" #searchValue type="text" [(ngModel)]="filtrarPorTexto" placeholder="Escreva um valor">
          </div>

          <div>
            <button class="btn btn-secondary mr-3"
                    (click)="clearFilter(dataColumnName)">
              <i class="mdi mdi-close">Limpar</i>
            </button>

            <button [disabled]="!searchValue.value"
                    class="btn btn-success"
                    (click)="applyFilter(dataColumnName,
                   selectedOperationFilter.value,  searchValue.value)">
              <i class="mdi mdi-filter"> Filtrar</i>
            </button>


          </div>
        </nb-card-body>
      </nb-card>
    </ng-template>
  </mat-menu>



</main>

<ng-template #modalConcluirNegociacao let-modal>
  <div class="modal-header bg-dark">
    <h5 class="modal-title  text-white">Precificação {{form.nome.value}}</h5>
    <button type="button" class="close text-white" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <nb-card class="container" [nbSpinner]="carregandoDadosEmpresa" nbSpinnerStatus="primary">
    <nb-alert status="warning" *ngIf="cbotInformativo.mensagem != null && cbotInformativo.mensagem != ''">{{cbotInformativo.mensagem}}</nb-alert>
    <nb-card-body *ngIf="cbotInformativo.mensagem == null">
      <nb-stepper orientation="horizontal" [linear]="true">
        <nb-step [stepControl]="concluirNegocioForm" label="Negociação">
          <form [formGroup]="concluirNegocioForm" (ngSubmit)="onFirstSubmit()" class="step-container">
            <div class="step-container">

              <div class="form-group">
                <label><strong>Contraparte</strong></label>
                <div class="ng-autocomplete w-100">
                  <ng-autocomplete [data]="usuariosFiltrados"
                                   [searchKeyword]="keyword"
                                   [minQueryLength]="3"
                                   [debounceTime]="2"
                                   [isLoading]="carregandoUsuarios"
                                   placeholder="Selecione a contraparte"
                                   (selected)='selectEvent($event)'
                                   (inputChanged)='onChangeSearch($event)'
                                   (inputCleared)="removerContraparte()"
                                   [itemTemplate]="itemTemplate"
                                   [notFoundTemplate]="notFoundTemplate">
                  </ng-autocomplete>

                  <ng-template #itemTemplate let-item>
                    <a [innerHTML]="item.nome"></a>
                  </ng-template>

                  <ng-template #notFoundTemplate let-notFound>
                    <div>Não encontrado</div>
                  </ng-template>
                </div>
              </div>


              <div class="form-group">
                <label><strong>Volume</strong></label>
                <div class="input-group">
                  <input type="number"
                         nbInput fullWidth fieldSize="large"
                         placeholder="Informe em toneladas"
                         class="form-control"
                         formControlName="volume"
                         name="volume"
                         required
                         #name
                         [status]="name.invalid ? 'danger' : 'basic'">
                </div>
              </div>
            </div>
            <nb-tabset fullWidth>
              <nb-tab *ngIf="form.precificacao.value.tipoNegocioString === 'Venda'">

                <ng-template nbTabTitle>
                  <p>
                    <span>BID</span>
                    <br />
                    <small>Prêmio {{premioPorTipoNegocioNaConclusao(precificacaoSelecionada,'Venda')}}</small>
                  </p>
                </ng-template>
                <table class="table">
                  <tbody>
                    <tr>
                      <td>
                        <button class="btn btn-sm {{operacaoOfferOuBidSelecionada(TipoTransaoEnum.BID,MoedaEnum.DOLAR)}} mr-2" (click)="selecionarOperacao(1, 1, precificacaoSelecionada.oferta)">Selecionar</button>
                      </td>

                      <td>
                        {{precificacaoSelecionada.oferta | currency:'USD'}}/SACA
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <button class="btn btn-sm {{operacaoOfferOuBidSelecionada(TipoTransaoEnum.BID,MoedaEnum.REAL)}} mr-2" (click)="selecionarOperacao(1, 2, precificacaoSelecionada.ofertaRs)">Selecionar</button>
                      </td>

                      <td>
                        {{precificacaoSelecionada.ofertaRs | currency:'BRL'}}/SACA
                      </td>

                    </tr>
                  </tbody>
                </table>
              </nb-tab>
              <nb-tab *ngIf="form.precificacao.value.tipoNegocioString === 'Compra'">
                <ng-template nbTabTitle>
                  <p>
                    <span>OFFER</span>
                    <br />
                    <small>Prêmio {{premioPorTipoNegocioNaConclusao(precificacaoSelecionada,'Compra')}}</small>
                  </p>
                </ng-template>
                <table class="table">
                  <tbody>
                    <tr>
                      <td>
                        <button class="btn btn-sm {{operacaoOfferOuBidSelecionada(TipoTransaoEnum.OFFER,MoedaEnum.DOLAR)}} mr-2" (click)="selecionarOperacao(2, 1, precificacaoSelecionada.bid)">Selecionar</button>
                      </td>

                      <td>
                        {{precificacaoSelecionada.bid | currency:'USD'}}/SACA
                      </td>

                    </tr>
                    <tr>
                      <td>
                        <button class="btn btn-sm {{operacaoOfferOuBidSelecionada(TipoTransaoEnum.OFFER,MoedaEnum.REAL)}} mr-2" (click)="selecionarOperacao(2, 2, precificacaoSelecionada.bidRs)">Selecionar</button>
                      </td>

                      <td>
                        {{precificacaoSelecionada.bidRs | currency:'BRL'}}/SACA
                      </td>
                    </tr>
                  </tbody>
                </table>

              </nb-tab>
            </nb-tabset>
            <div class="row">
              <div class="col-md-12 text-right">
                <button class="next-button" nbButton nbStepperNext outline status="info"><nb-icon icon="arrow-forward-outline"></nb-icon> Continuar</button>
              </div>
            </div>
          </form>
        </nb-step>
        <nb-step label="Resumo">
          <div class="step-container">
            <div class="col-12">
              <h4> Tonelada {{form.volume.value}}</h4>
              <h4 [ngClass]="{'text-success' : valorEstaMaiorQueOPremioSelecionado,'text-danger': !valorEstaMaiorQueOPremioSelecionado}">{{tipoTransacao}} - {{valorConclusaoFormatado}}/SACA</h4>
              <h4>{{'modals.bookOfertas.premio'|translate}} - {{premioConlusaoTransacao}}</h4>
            </div>
            <div class="row">
              <div class="col-md-12 text-right">
                <button nbButton nbStepperPrevious (click)="onBackSubmit()"><nb-icon icon="arrow-back-outline"></nb-icon> Voltar</button>
                <button nbButton nbStepperNext class="ml-2" outline status="info"><nb-icon icon="arrow-forward-outline"></nb-icon> Continuar</button>
              </div>
            </div>
          </div>
        </nb-step>
        <nb-step label="Final">
          <h1>Deseja concluir esta Negociação?</h1>
          <div class="row">
            <div class="col-md-12 text-right">
              <button nbButton nbStepperPrevious><nb-icon icon="arrow-back-outline"></nb-icon> Voltar</button>
              <button nbButton nbStepperNext class="ml-2" outline status="success" (click)="concluirPrecificao(); modal.dismiss('Cross click')"><nb-icon icon="checkmark-outline"></nb-icon>Concluir</button>
            </div>
          </div>
        </nb-step>
      </nb-stepper>
    </nb-card-body>
  </nb-card>

</ng-template>

<ng-template #mymodal let-modal>
  <div class="modal-header bg-dark">
    <h5 class="modal-title  text-white">Precificação {{precificacaoSelecionada.nome}}</h5>
    <button type="button" class="close text-white" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body d-flex">


    <table class="table table-sm">
      <tbody>
        <tr class="bg-light">
          <th>
            Ordem
          </th>
          <td>
            {{precificacaoSelecionada.tipoNegocioString}}
          </td>
          <th>
            Produto
          </th>
          <td>
            {{precificacaoSelecionada.produto}}
          </td>
          <th>
            {{'modals.bookOfertas.dataCadastro'|translate}}
          </th>
          <td>
            {{precificacaoSelecionada.dataAtualizacao}}
          </td>
        </tr>
        <tr class="bg-light">
          <th>
            Porto
          </th>
          <td>
            {{precificacaoSelecionada.porto}}
          </td>
          <td></td>
          <td></td>
          <th>
            Componente
          </th>
          <td>
            {{totalComponente(precificacaoSelecionada, precificacaoSelecionada.tipoNegocioString) | number:'1.2-2'}}
          </td>
        </tr>
        <tr>
          <th colspan="3">
            <table class="table">
              <thead class="bg-dark text-white">
                <tr>
                  <th></th>
                  <th></th>
                  <th>Valor</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>
                    Prêmio
                  </th>
                  <td>
                    {{precificacaoSelecionada.premioStr}}
                  </td>
                  <td>
                    {{precificacaoSelecionada.bidPremio | number:'1.2-2'}}
                  </td>
                </tr>
                <tr>
                  <th>
                    {{'modals.bookOfertas.cbot'|translate}}
                  </th>
                  <th>
                    {{precificacaoSelecionada.cbot}}
                  </th>
                  <td>
                    {{precificacaoSelecionada.cbotValor  | number:'1.2-2'}}
                  </td>
                </tr>
                <tr>
                  <td>
                    Rodo
                  </td>
                  <td>
                    {{precificacaoSelecionada.frete}}
                  </td>
                  <td>
                    {{precificacaoSelecionada.valorFreteRodo | number:'1.2-2'}}
                  </td>
                </tr>
                <tr>
                  <td>
                    Ferro
                  </td>
                  <td>
                    {{precificacaoSelecionada.freteFerro}}
                  </td>
                  <td>
                    {{precificacaoSelecionada.valorFreteFerro  | number:'1.2-2'}}
                  </td>
                </tr>
              </tbody>
            </table>


          </th>
          <td colspan="3">
            <table class="table  table-bordered">
              <thead class="bg-dark text-white">
                <tr>
                  <th>
                    Hedge
                  </th>
                  <th>
                    Valor
                  </th>
                  <th>
                    Data
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>
                    Mercado
                  </th>
                  <td>
                    {{hedgeMercado(precificacaoSelecionada.tipoNegocioString,precificacaoSelecionada)  | number:'1.2-2'}}
                  </td>
                  <td>
                    {{precificacaoSelecionada.dataMercString}}
                  </td>
                </tr>
                <tr>
                  <th>
                    Rodo
                  </th>
                  <td>
                    {{hedgeRodo(precificacaoSelecionada.tipoNegocioString,precificacaoSelecionada)  | number:'1.2-2'}}
                  </td>
                  <td>
                    {{precificacaoSelecionada.dataRodoString}}
                  </td>
                </tr>
                <tr>
                  <th>
                    Ferro
                  <td>
                    {{hedgeFerro(precificacaoSelecionada.tipoNegocioString,precificacaoSelecionada)  | number:'1.2-2'}}
                  </td>
                  <td>
                    {{precificacaoSelecionada.dataFerroString}}
                  </td>
                </tr>
              </tbody>
            </table>
          </td>

        </tr>
        <tr>

          <th colspan="6">
            <table class="table  table-bordered">
              <thead class="bg-dark text-white">
                <tr>
                  <th colspan="2">
                    Oferta
                  </th>
                  <th>
                    BID
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    U$
                  </td>
                  <td class="text-center">
                    <span class="{{marcarComoSelecionado(2, MoedaEnum.DOLAR,precificacaoSelecionada) }} ">
                      {{precificacaoSelecionada.oferta | number:'1.2-2' }}
                    </span>
                  </td>
                  <td>
                    <span class="{{marcarComoSelecionado(1, MoedaEnum.DOLAR,precificacaoSelecionada) }} ">
                      {{precificacaoSelecionada.bid | number:'1.2-2'}}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    R$
                  </td>
                  <td class="text-center">
                    <span class="{{marcarComoSelecionado(2, MoedaEnum.REAL,precificacaoSelecionada) }} ">
                      {{precificacaoSelecionada.ofertaRs | number:'1.2-2'}}
                    </span>
                  </td>
                  <td>
                    <span class="{{marcarComoSelecionado(1, MoedaEnum.REAL,precificacaoSelecionada) }} ">
                      {{precificacaoSelecionada.bidRs | number:'1.2-2'}}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </th>

        </tr>
      </tbody>
    </table>
  </div>

</ng-template>


<ng-template #popContent let-greeting="greeting">

  <button class="btn btn-sm btn-outline-success mr-2" data-toggle="tooltip" data-placement="top"
          (click)="concluir(modalConcluirNegociacao,prec)">
    <i class="mdi mdi-check"></i>
  </button>
  <button class="btn btn-sm btn-outline-info mr-2" data-toggle="tooltip" data-placement="top"
          (click)="open(mymodal, prec);">
    <i class="mdi mdi-magnify"></i>
  </button>
  <button class="btn btn-sm btn-outline-danger" data-toggle="tooltip" data-placement="top"
          (click)="status(prec,3, true)">
    <i class="mdi mdi-close"></i>
  </button>

</ng-template>


