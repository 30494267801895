<main>
  <nb-card  [nbSpinner]="loading">
    <nb-card-header>
      <h3> {{'Menus.CurvaCambio' | translate}}</h3>
    </nb-card-header>
   
      <div class="row px-3">
        <div class="form-group col-md-3 col-sm-12">
          <label> {{'dataInicio' | translate}}</label>
          <input type="date" class="form-control" [(ngModel)]="dto.dataInicio" pattern="[0-9]{2}-[0-9]{2}-[0-9]{4}" readonly>
        </div>
        <div class="form-group col-md-3 col-sm-12">
          <label> {{'dataFim' | translate}}</label>
          <div class="form-group">
            <div class="input-group">
              <input class="form-control" placeholder="dd/mm/yyyy" name="DataFim" [(ngModel)]="dto.dataFim" ngbDatepicker #DataFim="ngbDatepicker" (click)="DataFim.toggle()" required />
            </div>
          </div>
        </div>
        <div class="form-group col-md-1 col-sm-12" *ngIf="dto.check">
          <label>{{'periodo' | translate}}</label>
          <input type="number" class="form-control" [(ngModel)]="dto.periodo">
        </div>
        <div class="form-group col-md-1 col-sm-12">
          <label>{{'lista' | translate}}</label>
          <input type="checkbox" class="form-check h-50" [(ngModel)]="dto.check" />
        </div>
        <div class="d-flex align-items-center col-md-2 col-sm-12">
          <button class="btn btn-success" (click)="obterDolarFuturo()">{{'btn.calcular'|translate}} <i class="mdi mdi-calculator"></i></button>
        </div>
        <div class="d-flex align-items-center justify-content-end  col-md-2 col-sm-12" *ngIf="dto.check">
          <button (click)="exportexcel()" class="btn btn-outline-dark">
            <i class="mdi mdi-file-excel mdi-18px"></i>
            {{'btn.exportarPlanilha'|translate}}
          </button>
        </div>
      </div>
      <div class="d-flex mt-3" *ngIf="obteveDolar && !dto.check">
        <div class="col-6">
          <strong>{{'dolarFuturoCompra' | translate}}</strong>
          <p>R$ {{dolarFuturo.dolarCompra | number:'1.4'}}</p>
        </div>
        <div class="col-6">
          <strong>{{'dolarFuturoVenda' | translate}}</strong>
          <p>R$ {{dolarFuturo.dolarVenda | number:'1.4'}}</p>
        </div>
      </div>
      <div *ngIf="dto.check" class="table-gp col-md-12 col-xl-12 col-lg-12 col-sm-12" >
        <table id="tbDolarFuturo" class="table">
          <thead class="thead-dark">
            <tr>
              <th>{{'data'|translate}}</th>
              <th>{{'dolarFuturoCompra'|translate}}</th>
              <th>{{'dolarFuturoVenda'|translate}}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let dolarFuturo of listaDolarFuturo">
              <td>
                {{dolarFuturo.dataFim | date:'dd/MM/yyyy'}}
              </td>
              <td>
                {{dolarFuturo.dolarCompra | number:'1.4'}}
              </td>
              <td>
                {{dolarFuturo.dolarVenda | number:'1.4'}}
              </td>
            </tr>

            <tr *ngIf="listaDolarFuturo == null || listaDolarFuturo.length == 0">
              <td colspan="3" class="text-center">Nenhuma cotação listada</td>
            </tr>
          </tbody>
        </table>
      </div>
   
  </nb-card>
</main>
