import { Component, OnInit } from "@angular/core";
import { ToastService } from '../services/toastService';
import { Router } from '@angular/router';
import { ServicoCBotService } from "../services/servicoCBoatService";
import { LoginService } from "../services/loginService";
import { EstadoService } from "../services/estadoService";

@Component({
  selector: 'app-sign-up',
  templateUrl: './signUp.html',
})

export class SignUpComponent implements OnInit {

  constructor(
    private toastService: ToastService,
    private loginService: LoginService,
    private estadoService: EstadoService,
    private servicoCbotService: ServicoCBotService,
    private router: Router) {
  }
  listaTipoUsuario: any[];
  listaEstadosAtivos: DtoEstado[];
  cnpjMask = [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/,];

  ngOnInit(): void {
    this.servicoCbotService.getAllTipoUsuario().subscribe(result => this.listaTipoUsuario = result);
    this.estadoService.obterTodosAtivos().subscribe(result => this.listaEstadosAtivos = result);
  }

  public usuario: NovoUsuario = new NovoUsuario(null,"", "", "", "", "", "", "", "", false, "");
  passo1 = true;

  newAccount() {

    this.validarNovoUsuario();
    this.usuario.tipoUsuario = +this.usuario.tipoUsuario;
    this.loginService.signUp(this.usuario).subscribe((result) => {

      if (result.codigo != 200) {
        this.toastService.show(result.mensagem, { classname: 'bg-danger text-light', delay: 5000 });
        return;
      }

      this.toastService.show("Cadastro realizado com sucesso", { classname: 'bg-success text-light', delay: 5000 });
      this.router.navigate(['/aguardandoAprovacao']);
    })
  }

  validarNovoUsuario() {
    if (this.usuario.tipoUsuario == null) {
      this.toastService.show("Por favor, selecione o perfil", { classname: 'bg-warning text-light', delay: 5000 })
      return;
    }

    if (this.usuario.email.trim() == "") {
      this.toastService.show("Por favor, preencha o e-mail", { classname: 'bg-warning text-light', delay: 5000 })
      return;
    }

    if (this.usuario.password.trim() == "") {
      this.toastService.show("Por favor, preencha a senha", { classname: 'bg-warning text-light', delay: 5000 })
      return;
    }

    if (this.usuario.estadosDeAcesso == null || this.usuario.estadosDeAcesso.length == 0) {
      this.toastService.show("Por favor, selecione um estado", { classname: 'bg-warning text-light', delay: 5000 })
      return;
    }

    if (this.usuario.grupoEmpresarial.trim() == "") {
      this.toastService.show("Por favor, preencha o Grupo Empresarial", { classname: 'bg-warning text-light', delay: 5000 })
      return;
    }

    if (this.usuario.cnpj.trim() == "") {
      this.toastService.show("Por favor, preencha o CNPJ", { classname: 'bg-warning text-light', delay: 5000 })
      return;
    }

    if (this.usuario.razaoSocial.trim() == "") {
      this.toastService.show("Por favor, preencha a Razão Social", { classname: 'bg-warning text-light', delay: 5000 })
      return;
    }

    if (this.usuario.nomeFantasia.trim() == "") {
      this.toastService.show("Por favor, preencha o Nome Fantasia", { classname: 'bg-warning text-light', delay: 5000 })
      return;
    }

    if (this.usuario.inscricaoEstadual.trim() == "") {
      this.toastService.show("Por favor, preencha a Inscrição Estadual", { classname: 'bg-warning text-light', delay: 5000 })
      return;
    }

    if (this.usuario.endereco.trim() == "") {
      this.toastService.show("Por favor, preencha o Endereço", { classname: 'bg-warning text-light', delay: 5000 })
      return;
    }

    if (!this.usuario.aceitouTermos)
      this.toastService.show("Aceito os Termos de Uso para continuar", { classname: 'bg-warning text-light', delay: 5000 })
    return;
  }
}


export class DtoEstado {
  idEstado: number;
  nomeEstado: string;
  uf: string;

  constructor(
    idEstado: number,
    nomeEstado: string,
    uf: string
  ) {
    this.idEstado = idEstado;
    this.nomeEstado = nomeEstado;
    this.uf = uf;
  }
}


export class NovoUsuario {

  tipoUsuario: number;
  email: string;
  password: string;
  grupoEmpresarial: string;
  cnpj: string;
  razaoSocial: string;
  nomeFantasia: string;
  inscricaoEstadual: string;
  endereco: string;
  estadosDeAcesso: DtoEstado[] = [];
  aceitouTermos: boolean;
  nome: string;

  constructor(
    tipoUsuario: number,
    email: string,
    password: string,
    grupoEmpresarial: string,
    cnpj: string,
    razaoSocial: string,
    nomeFantasia: string,
    inscricaoEstadual: string,
    endereco: string,
    aceitouTermos: boolean,
    nome: string) {

    this.tipoUsuario = tipoUsuario;
    this.email = email;
    this.password = password;
    this.grupoEmpresarial = grupoEmpresarial;
    this.cnpj = cnpj;
    this.razaoSocial = razaoSocial;
    this.nomeFantasia = nomeFantasia;
    this.inscricaoEstadual = inscricaoEstadual;
    this.endereco = endereco
    this.aceitouTermos = aceitouTermos;
    this.nome = nome;
  }
}


export interface ITipoUsuario {
  id: number;
  descricao: string;
}
