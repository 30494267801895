import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DtoUsuarioAutenticado } from '../dtos/DtoUsuarioAutenticado';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  public currentUserSubject: BehaviorSubject<DtoUsuarioAutenticado>;
  public currentUser: Observable<DtoUsuarioAutenticado>;

  constructor(private http: HttpClient) {
    var possuiUsuario = localStorage.getItem('currentUser') != "undefined";
    var user = possuiUsuario ? JSON.parse(localStorage.getItem('currentUser')) : null;

    this.currentUserSubject = new BehaviorSubject<DtoUsuarioAutenticado>(user);
    this.currentUser = this.currentUserSubject.asObservable();
  }

  baseUrl = "api/Usuario/"

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8'
    })
  }

  public logIn(dto) {
    return this.http.post<any>(this.baseUrl + "LogInAsync", JSON.stringify(dto), this.httpOptions);
  }

  public signUp(dto) {
    return this.http.post<any>(this.baseUrl + "SignUp", JSON.stringify(dto), this.httpOptions);
  }

  public logOut() {
    return this.http.post<any>(this.baseUrl + "Logout", this.httpOptions).pipe(map(retorno => {
      localStorage.removeItem('currentUser');
      localStorage.removeItem('ambienteSelecionado');

      this.currentUserSubject.next(null);
      return retorno;
    }));
  }

  public get currentUserValue(): DtoUsuarioAutenticado {
    return this.currentUserSubject.value;
  }

  public cadastrarUsuario(dto) {
    return this.http.post<any>(this.baseUrl + "CadastrarUsuario", dto, this.httpOptions);
  }

  public editarUsuario(dto) {
    return this.http.post<any>(this.baseUrl + "EditarUsuario", dto, this.httpOptions);
  }

  public inativarUsuario(dto) {
    return this.http.post<any>(this.baseUrl + "InativarUsuario/" + dto, this.httpOptions);
  }

  public obterListaUsuario(dto) {
    return this.http.post<any>(this.baseUrl + "ObterListaUsuario", dto, this.httpOptions);
  }

  public obterUsuarioPorId(id: number) {
    return this.http.get<any>(this.baseUrl + "ObterUsuarioPorId/" + id);
  }

  public verificarUsuarioLogado(id: number) {
    return this.http.get<any>(this.baseUrl + "VerificarUsuarioLogado/" + id);
  }

  public precisaAtualizarDados(id: number) {
    return this.http.get<any>(this.baseUrl + "PrecisaAtualizarDados/" + id);
  }

  public atualizarCookieEstadoSelecionado(id: number, idEstado: number) {
    return this.http.post<any>(this.baseUrl + "AtualizarCookieEstadoSelecionado?idUsuario=" + id + "&idEstado=" + idEstado, this.httpOptions);
  }

  public entrarEmContato(contato) {
    return this.http.post<any>(this.baseUrl + "EntrarEmContato", JSON.stringify(contato), this.httpOptions);
  }

  public atualizarDepois(id: number) {
    return this.http.post<any>(this.baseUrl + "AtualizarDepois/" + id, this.httpOptions);
  }

  public obterInfoEmpresa(id: number) {
    return this.http.get<any>(this.baseUrl + "ObterInfoEmpresa/" + id);
  }

  public obterInfoEmpresaPorUsuario(id: number) {
    return this.http.get<any>(this.baseUrl + "ObterInfoEmpresaPorUsuario/" + id);
  }

  public aprovarCadastro(id: number) {
    return this.http.post<any>(this.baseUrl + "AprovarCadastro/" + id, this.httpOptions);
  }

  public recusarCadastro(id: number) {
    return this.http.post<any>(this.baseUrl + "RecusarCadastro/" + id, this.httpOptions);
  }

  public qtdUsuarioPendente() {
    return this.http.get<any>(this.baseUrl + "QtdUsuarioPendente");
  }
  public pesquisarUsuarioPorNome(nome) {
    return this.http.get<any>(this.baseUrl + "PesquisarUsuarioPorNome/" + nome);
  }

}
