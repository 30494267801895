import { Injectable } from '@angular/core';
import { NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';


/**
 * This Service handles how the date is represented in scripts i.e. ngModel.
 */
@Injectable()
export class CustomAdapter extends NgbDateAdapter<string> {

  readonly DELIMITER = '/';

  fromModel(value: string): NgbDateStruct {
    let result: NgbDateStruct = null;
    if (value) {
      if (typeof (value) === "object") {
        return value;
      }

      let date = value.split(this.DELIMITER);
      result = {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10)
      };
    }
    return result;
  }

  formatModel(number: number): string {
    if (number < 10)
      return '0' + number;

    return number.toString();
  }

  toModel(date: NgbDateStruct): string {
    let result: string = null;
    if (date) {
      result = this.formatModel(date.day) + this.DELIMITER + this.formatModel(date.month) + this.DELIMITER + date.year;
    }
    return result;
  }
}

/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {

  readonly DELIMITER = '/';

  parse(value: string): NgbDateStruct {
    let result: NgbDateStruct = null;
    if (value) {

      if (typeof (value) === "object") {
        return value;
      }

      let date = value.split(this.DELIMITER);
      result = {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10)
      };
    }
    return result;
  }

  formatModel(number: number): string {
    if (number < 10)
      return '0' + number;

    return number.toString();
  }

  format(date: NgbDateStruct): string {
    let result: string = null;
    if (date) {
      result = this.formatModel(date.day) + this.DELIMITER + this.formatModel(date.month) + this.DELIMITER + date.year;
    }
    return result;
  }
}