import { Injectable } from '@angular/core';
import { LoginService } from './loginService';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {

  constructor(private loginService: LoginService) {

  }

  estaLogado(): boolean {
    return this.loginService.currentUserValue != null;
  }

  ehAdministrador(): boolean {
    return this.loginService.currentUserValue.tipoUsuario == "Administrador";
  }

  ehTrading(): boolean {
    return this.loginService.currentUserValue.tipoUsuario == "Trading";
  }

  podeVisualizarDropDownEstados(): boolean {
    return this.ehAdministrador();
  }

}
