 <header>
    <div class="card-cotacao">
      <span class="group-cotacao">
        <label class="compra">{{'dolarCompra'|translate}}:<span class="value">{{resposta.cotacaoCompra}}</span></label>
        <label class="venda">{{'dolarVenda'|translate}}:<span class="value">{{resposta.cotacaoVenda}}</span></label>
      </span>
      <span class="data-cotacao">
        {{'dataCotacao' | translate}} - {{resposta.dataHoraCotacao | date:'dd/MM/yyyy HH:mm:ss'}}
      </span>
    </div>
  </header>
