import { Component, ViewChild } from '@angular/core';
import { PrecificacaoService } from '../../../services/precificacaoService';
import { ToastService } from '../../../services/toastService';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { TipoTransacaoEnum } from '../../../enums/TipoTransacaoEnum';
import { MoedaEnum } from '../../../enums/MoedaEnum';
import { MatTableDataSource } from '@angular/material/table';
import { MatMenuTrigger } from '@angular/material/menu';
import { ProdutoService } from '../../../services/produtoService';
@Component({
  selector: 'app-listagemPrecificacao',
  templateUrl: './listagemPrecificacao.html',
  styleUrls: ['./listagemPrecificacao.styled.css']
})
export class ListagemPrecificacao {
  modalOptions: NgbModalOptions;
  title = 'ng-bootstrap-modal-demo';
  closeResult: string;
  filtro: any = {
    tamanhoPagina: 100,
    numeroPagina: 1,
    idProduto: 1
  };
  listaPrecificacao: any[] = [];
  precificacaoSelecionada: {};
  listaGraos: any[] = [];
  loading = false;
  readonly TipoTransaoEnum = TipoTransacaoEnum;
  readonly MoedaEnum = MoedaEnum;
  readonly fatorSoja: number = 0.367454;
  readonly fatorMilho: number = 0.393685;
  public dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  public columnsFilters = {};
  @ViewChild(MatMenuTrigger, { static: false }) public matMenu: MatMenuTrigger;
  selectedOption: string = 'contains';
  filtrarPorTexto: string;
  tamanhosPaginas = [25, 50, 75, 100];
  constructor(private precificacaoService: PrecificacaoService,
    private toastService: ToastService,
    private modalService: NgbModal,
    private produtoService: ProdutoService) {
    this.modalOptions = {
      size: 'lg'
    }
  }

  ngOnInit() {
    this.obterListaProdutos();
    this.obterListaPrecificacao(1);
  }

  obterListaProdutos() {
    this.produtoService.obterListaProduto().subscribe(response => {
      if (response) {
        this.listaGraos = response;
      }
    })
  }

  closeMenu() {
    this.matMenu.closeMenu();
  }

  applyFilter(columnName: string, operationType: string, searchValue: string) {

    this.columnsFilters[columnName] = {};
    this.columnsFilters[columnName][operationType] = searchValue;
    this.filter();
    this.closeMenu();
  }

  limparCamposFiltro() {
    this.selectedOption = "contains";
    this.filtrarPorTexto = null;
  }

  onMenuOpened(columnName: string) {
    this.limparCamposFiltro();
    if (this.columnsFilters[columnName]) {
      let chave = Object.keys(this.columnsFilters[columnName])[0];
      let valor = Object.values(this.columnsFilters[columnName])[0];

      this.selectedOption = "" + chave;
      this.filtrarPorTexto = "" + valor;
    }
  }

  jaFiltrado(columnName: string) {
    return this.columnsFilters[columnName];
  }

  clearFilter(columnName: string) {
    if (this.columnsFilters[columnName]) {
      delete this.columnsFilters[columnName];
      this.filter();
    }
    this.closeMenu();
  }

  formatarPrecoBrasil(price: number) {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(price);
  }

  formatarPrecoDolar(price: number) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(price);
  }

  obterListaPrecificacao(pagina) {
    this.loading = true;
    this.filtro.pagina = pagina;
    this.precificacaoService.obterListaNegocioConcluido(this.filtro).subscribe(response => {
      this.loading = false;
      if (response.codigo === 200) {
        this.listaPrecificacao = response.result.retorno;
        this.listaPrecificacao.forEach(p => {
        
          p.bidDolarEREal = `${this.formatarPrecoDolar(p.valorBid)} | ${this.formatarPrecoBrasil(p.valorBidRs)}`;
          p.ofertaDolarEReal = `${this.formatarPrecoDolar(p.valor)} | ${this.formatarPrecoBrasil(p.valorRs)}`;
        })
        this.dataSource.data = this.listaPrecificacao;
        
        this.filtro.total = response.result.qtdTotal
      }
      else
        this.toastService.show(response.mensagem, { classname: 'bg-danger text-light', delay: 5000 })
    })
  }

  private filter() {

    this.dataSource.filterPredicate = (data: any, filter: string) => {

      let find = true;

      for (var columnName in this.columnsFilters) {

        let currentData = ("" + data[columnName]).normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim().toLowerCase();

        if (!this.columnsFilters[columnName]) {
          return false;
        }

        let searchValue = this.columnsFilters[columnName]["contains"];

        if (!!searchValue && currentData.indexOf("" + searchValue.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim().toLowerCase()) < 0) {

          find = false;
          return find;
        }

        searchValue = this.columnsFilters[columnName]["equals"];

        if (!!searchValue && currentData != ("" + searchValue).normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim().toLowerCase()) {
          find = false;
          return find;
        }

        searchValue = this.columnsFilters[columnName]["greaterThan"];

        if (!!searchValue && currentData <= ("" + searchValue).normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim().toLowerCase()) {
          find = false;
          return find;
        }

        searchValue = this.columnsFilters[columnName]["lessThan"];

        if (!!searchValue && currentData >= ("" + searchValue).normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim().toLowerCase()) {
          find = false;
          return find;
        }

        searchValue = this.columnsFilters[columnName]["startWith"];

        if (!!searchValue && !currentData.startsWith("" + searchValue.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim().toLowerCase())) {
          find = false;
          return find;
        }

        searchValue = this.columnsFilters[columnName]["endWith"];

        if (!!searchValue && !currentData.endsWith("" + searchValue.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim().toLowerCase())) {
          find = false;
          return find;
        }
      }
      return find;

    };

    this.dataSource.filter = null;
    this.dataSource.filter = 'activate';

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  mostrarTransacaoSelecionada(tipoTransaoEnum: TipoTransacaoEnum, precificacao): boolean {
    return precificacao.tipoTransacao == tipoTransaoEnum;
  }

  mostrarPrecificacaoSelecionado(tipoTransaoEnum: TipoTransacaoEnum, moedaEnum: MoedaEnum, precificacao): boolean{
    return this.mostrarTransacaoSelecionada(tipoTransaoEnum, precificacao) && precificacao.moedaTransacaoId == moedaEnum;
  }

  indicarCampoSelecionadoModal(tipoTransaoEnum: TipoTransacaoEnum, moedaEnum: MoedaEnum, precificacao) {
    var indicadorSelecionado = "bg-success";
    var estaSelecionado = this.mostrarPrecificacaoSelecionado(tipoTransaoEnum, moedaEnum, precificacao);

    if (!estaSelecionado)
      return "";


    if (TipoTransacaoEnum.OFFER == tipoTransaoEnum) {
      var offerEstaDentroDoEsperado = precificacao.valorBid > precificacao.valor && estaSelecionado;
      if (!offerEstaDentroDoEsperado) {
        indicadorSelecionado = "bg-danger";
      }
    }

    return indicadorSelecionado;
  }

  marcarComoSelecionado(tipoTransaoEnum: TipoTransacaoEnum, moedaEnum: MoedaEnum, precificacao): string {
    var indicadorSelecionado = "badge badge-pill badge-success";
    var estaSelecionado = this.mostrarPrecificacaoSelecionado(tipoTransaoEnum, moedaEnum, precificacao);

    if (!estaSelecionado)
      return "";
    var valorBid = parseFloat(precificacao.valorBid).toFixed(2);
    var valor = parseFloat(precificacao.valor).toFixed(2);

    if (TipoTransacaoEnum.OFFER == tipoTransaoEnum) {

      var offerEstaDentroDoEsperado = valorBid >= valor && estaSelecionado;
      if (!offerEstaDentroDoEsperado) {
        indicadorSelecionado = "badge badge-pill badge-danger";
      }
    }
    return indicadorSelecionado;
  }


 
  obterFatorPorProduto(produto) {

    if (produto == 'SOJA')
      return this.fatorSoja;

    return this.fatorMilho;
  }

  open(content) {
    this.modalService.open(content, { backdrop: false }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  selecionarPrecificacao(precificacao) {
    this.precificacaoSelecionada = precificacao;
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

 
}
