import { Component, ViewEncapsulation } from '@angular/core';
import { LoginService } from '../services/loginService';
import { ToastService } from '../services/toastService';
import { Router } from '@angular/router';
import { EstadoService } from '../services/estadoService';
import { ProdutoEnum } from '../enums/ProdutoEnum';
import { PerfilEnum } from '../enums/PerfilEnum';
import { SolicitarVinculoService } from '../services/solicitarVinculoService';

@Component({
  selector: 'app-aside-menu',
  templateUrl: './aside-menu.component.html',
  styleUrls: ['./aside-menu.component.css'],
})
export class AsideMenuComponent {
  ambienteSelecionado: any;

  precificacaoSoja: boolean = false;
  precificacaoMilho: boolean = false;
  menuCurvaCambio: boolean = false;
  menuFrete: boolean = false;
  menuPremio: boolean = false;
  menuCBOT: boolean = false;
  menuInconterm: boolean = false;
  menuPortos: boolean = false;
  menuComponente: boolean = false;
  menuPrecificacao: boolean = false;
  menuBookOfertas: boolean = false;
  menuConcluidos: boolean = false;
  menuUsuarios: boolean = false;
  menuConfig: boolean = false;
  menuEstado: boolean = false;
  userId: number;
  numSolicitacaoVinculos: number;
  qtdUsuarioSolicitPendente: number;

  constructor(private toastService: ToastService, private loginService: LoginService, private router: Router,
    private estadoService: EstadoService,
    private vinculoService: SolicitarVinculoService) { }
  isExpanded = false;

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  public deslogar() {
    this.loginService.logOut().subscribe(response => {
      if (response.codigo === 200) {
        this.toastService.show('Logout efetuado com sucesso!', { classname: 'bg-success text-light', delay: 5000 })
        this.router.navigate(['/sign-in']);
      }
      else
        this.toastService.show(response.mensagem, { classname: 'bg-danger text-light', delay: 5000 })
    });
  }

  qtdUsuarioPendente() {
    this.loginService.qtdUsuarioPendente().subscribe(res => {
      this.qtdUsuarioSolicitPendente = res;
    });
  }

  irParaMeuPerfil() {
    this.router.navigate(['/usuario/editar/' + this.userId]);
  }

  obterEstadoPorId(id: number) {
    this.estadoService.obterPorId(id).subscribe(response => {
      this.ambienteSelecionado = response.nomeEstado;
    })
  }

  public expandir() {
    const element = document.querySelector(".gp-navbar-nav");
    element.classList.toggle("active");
  }

  public dropmenu() {
    const element = document.querySelector(".gp-navbar-nav");
    element.classList.remove("active");
  }

  private tratarMenus() {

    var currentUser = this.loginService.currentUserValue;

    if (currentUser != null) {
      this.userId = currentUser.usuarioId;
      let roles = currentUser.listaPerfil.map(a => { return a.idPerfil.toString() });
      let produtosCbot = currentUser.produtosCbot;

      this.menuCurvaCambio = roles.indexOf(PerfilEnum.CURVACAMBIO.toString()) > -1;
      this.menuFrete = roles.indexOf(PerfilEnum.FRETE.toString()) > -1;
      this.menuPremio = roles.indexOf(PerfilEnum.PREMIO.toString()) > -1;
      this.menuCBOT = roles.indexOf(PerfilEnum.CBOT.toString()) > -1;
      this.menuInconterm = false;
      this.menuPortos = roles.indexOf(PerfilEnum.PORTOS.toString()) > -1;
      this.menuComponente = roles.indexOf(PerfilEnum.COMPONENTE.toString()) > -1;
      this.menuPrecificacao = roles.indexOf(PerfilEnum.PRECIFICAÇÃO.toString()) > -1;
      this.menuBookOfertas = roles.indexOf(PerfilEnum.BOOKDEOFERTAS.toString()) > -1;
      this.menuConcluidos = roles.indexOf(PerfilEnum.BOOKDEOFERTAS.toString()) > -1;
      this.menuUsuarios = roles.indexOf(PerfilEnum.OPERADOR.toString()) > -1;
      this.menuConfig = roles.indexOf(PerfilEnum.OPERADOR.toString()) > -1;
      this.menuEstado = roles.indexOf(PerfilEnum.OPERADOR.toString()) > -1;
      this.precificacaoSoja = produtosCbot.find(a => a == ProdutoEnum.SOJA) > -1;
      this.precificacaoMilho = produtosCbot.find(a => a == ProdutoEnum.MILHO) > -1;
    }
  }

  numSolicitacoesVinculosRequest() {

    this.vinculoService.obterQtdSolicitacoes(this.userId).subscribe(res => {
      this.numSolicitacaoVinculos = res;
    });
  }

  ngOnInit() {
    this.tratarMenus();
    this.numSolicitacoesVinculosRequest();
    setInterval(() => {
      this.qtdUsuarioPendente();
    }, 10000);
  }
}
