<div class="login-wrapper">
  <div class="login-view">
    <span class="wrapper-hero">
      <h1>Projeto BRIC</h1>
      <p>Auxiliando você a encontrar a precificação de Agrícola.</p>
    </span>
    <footer>
      ©Bigfield Desenvolvimento - Copyright 2020
    </footer>
  </div>
  <div class="login-form">
    <div class="card w-100">
      <div class="card-body">
        <form class="col-12" #login="ngForm" novalidate>
          <h3 class="h3 text-center">Insira o e-mail para continuar</h3>
          <div class="form-group">
            <label>Email</label>
            <input type="text" class="form-control" [class.is-invalid]="Email.invalid && Email.touched && Email.errors.email" [(ngModel)]="dados.email" name="Email" #Email required email placeholder="email@email.com.br">
          </div>
          <div class="form-group">
            <button class="btn btn-success btn-lg w-100" (click)="recuperarSenha()" [disabled]="login.form.invalid">
              <span *ngIf="loadingRecuperarSenha" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              Recuperar senha
            </button>
          </div>
          <div class="form-group">
            <button class="btn btn-secondary btn-lg w-100" [routerLink]='["/sign-in"]'>Voltar</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
