export enum PerfilEnum {
  CURVACAMBIO = 2,
  PREMIO = 3,
  PORTOS = 4,
  COMPONENTE = 5,
  PRECIFICAÇÃO = 6,
  BOOKDEOFERTAS = 7,
  FRETE = 8,
  OPERADOR = 9,
  CBOT = 10
}
