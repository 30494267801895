import { Component, OnDestroy, OnInit } from '@angular/core';
import { CotacaoService } from '../../services/cotacaoService';
import { CurvaCambioService } from '../../services/CurvaCambioService';
import { ToastService } from '../../services/toastService';
import { NgbModal, ModalDismissReasons, NgbModalOptions, NgbDateAdapter, NgbDateParserFormatter, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import * as XLSX from 'xlsx';
import { CustomAdapter, CustomDateParserFormatter } from '../../datepicker.custom.adapter';
import { Globals } from '../../globals/globals';

@Component({
  selector: 'app-listagemCurva',
  templateUrl: './listagemCurvaCambio.html',
  styleUrls: ['./listagemCurvaCambio.component.css'],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
  ]

})
export class ListagemCurvaCambio implements OnInit, OnDestroy {
  newDate: Date = new Date();

  modalOptions: NgbModalOptions;
  title = 'ng-bootstrap-modal-demo';
  closeResult: string;
  resposta: any = {};
  listaCurvaCambio: any[] = [];
  curvaSelecionado: any = {};
  dolarFuturo: any = {};
  obteveDolar: boolean = false;
  dto: any;
  taxaDI: any = {};
  filtro: any = {
    tamanhoPagina: 10,
    pagina: 1,
  };
  listaDolarFuturo: any[] = [];
  loading = false;
  setIntervalId: any;
  constructor(private cotacaoService: CotacaoService,
    private curvaCambioService: CurvaCambioService,
    private toastService: ToastService,
    private modalService: NgbModal,
    private calendar: NgbCalendar,
    private globals: Globals) {
    this.modalOptions = {
      backdrop: 'static',
      backdropClass: 'customBackdrop'
    }

  }

  ngOnInit(): void {

    this.dto = {
      dataInicio: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()).toISOString().split('T')[0],
      dataFim: this.calendar.getToday(),
      spread: 0.015,
      periodo: 15,
      taxaCdi: 0,
      taxaCupom: 2.6,
      check: false
    };

    this.globals.customVariable.subscribe({
      next: newValue => {
        this.resposta = newValue;
      }
    });

  }

  ngOnDestroy(): void {
    //clearInterval(this.setIntervalId);
  }

  obterDolarDoDia() {
    this.cotacaoService.obterCotacaoDolarDeHoje().subscribe(res => {
      var retApi = res.USDBRL;

      this.resposta = { cotacaoCompra: Number(retApi.bid), cotacaoVenda: Number(retApi.ask), dataHoraCotacao: retApi.create_date };
    });
  }

  obterListaCurvaCambio(pagina) {
    this.filtro.pagina = pagina;

    this.curvaCambioService.obterListaCurvaCambio(this.filtro).subscribe(response => {
      if (response.codigo === 200) {
        this.listaCurvaCambio = response.result.retorno;
        this.filtro.total = response.result.qtdTotal;
      }
      else
        this.toastService.show(response.mensagem, { classname: 'bg-danger text-light', delay: 5000 })
    })
  }

  selecionarCurva(id) {
    this.curvaSelecionado = this.listaCurvaCambio.find((curva) => {
      return curva.curvaCambioId === id;
    })
  }

  inativarCurvaCambio() {
    this.curvaCambioService.inativarCurvaCambio(this.curvaSelecionado.curvaCambioId).subscribe(response => {
      if (response.codigo === 200) {
        this.toastService.show('Curva de Cambio inativado com sucesso!', { classname: 'bg-success text-light', delay: 5000 })
        this.listaCurvaCambio = this.listaCurvaCambio.filter((curva) => {
          return curva.curvaCambioId !== this.curvaSelecionado.curvaCambioId;
        })
      }
      else {
        this.toastService.show(response.mensagem, { classname: 'bg-danger text-light', delay: 5000 })
      }
      this.modalService.dismissAll();
    });
  }

  obterTaxaDI() {
    this.curvaCambioService.obterTaxaDI().subscribe(response => {
      if (response.codigo === 200) {
        this.taxaDI = JSON.parse(response.result);
      }
      else {
        this.toastService.show(response.mensagem, { classname: 'bg-danger text-light', delay: 5000 })
      }
    })
  }

  formatDateNumberModel(number: number): string {
    if (number < 10)
      return '0' + number;

    return number.toString();
  }

  obterDolarFuturo() {
    var dtoForm = Object.assign({}, this.dto);
    if (typeof (dtoForm.dataFim) === "object") {
      dtoForm.dataFim = dtoForm.dataFim.year + '-' + this.formatDateNumberModel(dtoForm.dataFim.month) + '-' + this.formatDateNumberModel(dtoForm.dataFim.day);
    }
    else if (/[0-9]{2}[-\/][0-9]{2}[-\/][0-9]{4}/.test(dtoForm.dataFim)) {
      dtoForm.dataFim = dtoForm.dataFim.replace(/([0-9]{2})[-\/]([0-9]{2})[-\/]([0-9]{4})/, "$3-$2-$1");
    }

    if (dtoForm.check) {
      dtoForm.dolarCompra = this.resposta.cotacaoCompra;
      dtoForm.dolarVenda = this.resposta.cotacaoVenda;
      if (dtoForm.taxaCdi == null)
        dtoForm.taxaCdi = 0;
      if (dtoForm.spread == null)
        dtoForm.spread = 0;
      if (dtoForm.taxaCupom == null)
        dtoForm.taxaCupom = 0;
      if (dtoForm.periodo == null) {
        dtoForm.periodo == 15;
      }
      this.loading = true;
      this.curvaCambioService.obterDolarFuturoPorPeriodo(dtoForm).subscribe(response => {
        this.loading = false;
        if (response.codigo === 200) {
          this.listaDolarFuturo = response.result;
        }
        else {
          this.toastService.show(response.mensagem, { classname: 'bg-danger text-light', delay: 5000 })
        }
      })
    }
    else {
      dtoForm.dolarCompra = this.resposta.cotacaoCompra;
      dtoForm.dolarVenda = this.resposta.cotacaoVenda;
      if (dtoForm.taxaCdi == null)
        dtoForm.taxaCdi = 0;
      if (dtoForm.spread == null)
        dtoForm.spread = 0;
      if (dtoForm.taxaCupom == null)
        dtoForm.taxaCupom = 0;
      this.loading = true;
      this.curvaCambioService.obterDolarFuturo(dtoForm).subscribe(response => {
        this.loading = false;
        if (response.codigo === 200) {
          this.dolarFuturo = response.result;
          this.obteveDolar = true;
        }
        else {
          this.toastService.show(response.mensagem, { classname: 'bg-danger text-light', delay: 5000 })
        }
      })
    }
  }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  exportexcel(): void {
    /* table id is passed over here */
    let tbDolarFuturo = document.getElementById('tbDolarFuturo');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(tbDolarFuturo);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Dolar Futuro');

    /* save to file */
    XLSX.writeFile(wb, 'CurvaDeCambio.xlsx');

  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

}
