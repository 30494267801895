import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class TaxaDIService {
    constructor(private http: HttpClient) { }

    httpOptions = {
        headers: new HttpHeaders({
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': '*',
            'Content-Type': 'text/html',
        })
    }

    resposta: object = {};

    public obterTaxaDI() {

        return this.http.get<any>(`/taxaDi/ConsultarTaxaDi/ConsultarTaxaDICetip.aspx`);
    }

}
