import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PortoService {
  constructor(private http: HttpClient) { }

  baseUrl = "api/Porto/"

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8'
    })
  }

  public cadastrarPorto(dto) {
    return this.http.post<any>(this.baseUrl + "CadastrarPorto", JSON.stringify(dto), this.httpOptions);
  }

  public editarPorto(dto) {
    return this.http.post<any>(this.baseUrl + "EditarPorto", JSON.stringify(dto), this.httpOptions);
  }

  public inativarPorto(dto) {
    return this.http.post<any>(this.baseUrl + "InativarPorto?idPorto=" + dto, this.httpOptions);
  }

  public obterListaPorto(dto) {
    return this.http.post<any>(this.baseUrl + "ObterListaPorto", JSON.stringify(dto), this.httpOptions);
  }

  public obterListaPortoPrecificacao(dto) {
    return this.http.post<any>(this.baseUrl + "ObterListaPortoPrecificacao", JSON.stringify(dto), this.httpOptions);
  }

  public obterPortoPorId(id) {
    return this.http.get<any>(this.baseUrl + "ObterPortoPorId?portoId=" + id, this.httpOptions);
  }

  public obterTodosAtivos() {
    return this.http.get<any>(this.baseUrl + "ObterTodosAtivos", this.httpOptions);
  }

  public obterTodosAtivosPrecificacao() {
    return this.http.get<any>(this.baseUrl + "ObterTodosAtivosPrecificacao", this.httpOptions);
  }

  public ObterTodosAtivosPorEstadoLogado() {
    return this.http.get<any>(this.baseUrl + "ObterTodosAtivosPorEstadoLogado", this.httpOptions);
  }
}
