import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class IncotermService {
    constructor(private http: HttpClient) { }

    baseUrl = "api/Incoterm/"

    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json; charset=utf-8'
        })
    }

    public obterListaIncoterm(dto) {
        return this.http.post<any>(this.baseUrl + "ObterListaIncoterm", JSON.stringify(dto), this.httpOptions);
    }

    public cadastrarIncoterm(dto) {
        return this.http.post<any>(this.baseUrl + "CadastrarIncoterm", JSON.stringify(dto), this.httpOptions);
    }

    public inativarIncoterm(dto) {
        return this.http.post<any>(this.baseUrl + "InativarIncoterm", JSON.stringify(dto), this.httpOptions);
    }

    public editarIncoterm(dto) {
        return this.http.post<any>(this.baseUrl + "EditarIncoterm", JSON.stringify(dto), this.httpOptions);
    }
}
