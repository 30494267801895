<main>
  <nb-card [nbSpinner]="loading">
    <nb-card-header>
      <h3>{{'Menus.Usuários'|translate}}</h3>
    </nb-card-header>
    <nb-card-body>
      <form #usuarioForm="ngForm" novalidate>
        <div class="d-flex">
          <div class="col-2 d-flex align-self-end mb-3">
            <button class="btn btn-success" [routerLink]="['/usuario/criar']">
              {{'btn.cadastrar'|translate}} <i class="mdi mdi-account"></i>
            </button>
          </div>
        </div>

      </form>
      <hr />
      <div class="d-flex">
        <div class="form-group col-2 pl-0">
          <label>
            {{'usuarios.codigo'|translate}}
          </label>
          <input type="number" min="1" class="form-control" [(ngModel)]="filtro.codigo">
        </div>
        <div class="form-group col-2 pl-0">
          <label>
            {{'usuarios.nome'|translate}}
          </label>
          <input type="text" class="form-control" [(ngModel)]="filtro.nome" (ngModelChange)="filtro.nome = $event.toUpperCase()">
        </div>
        <div class="form-group col-2 pl-0">
          <label>
            {{'usuarios.email'|translate}}
          </label>
          <input type="text" class="form-control" [(ngModel)]="filtro.email" (ngModelChange)="filtro.email = $event.toUpperCase()">

        </div>
        <div class="form-group col-2 pl-0">
          <label>
            {{'usuarios.situacaoCadastroDesc'|translate}}
          </label>
          <select class="form-control" [(ngModel)]="filtro.situacaoCadastral" name="situacaoCadastral">
            <option [ngValue]="null">SELECIONE</option>
            <option [ngValue]="2">APROVADO</option>
            <option [ngValue]="1">PENDENTE</option>
            <option [ngValue]="3">RECUSADO</option>
          </select>
        </div>
        <div class="form-group col-2 pl-0">
          <label>
            {{'usuarios.status'|translate}}
          </label>
          <select class="form-control" [(ngModel)]="filtro.status" name="status">
            <option [ngValue]="null">SELECIONE</option>
            <option [ngValue]="true">ATIVO</option>
            <option [ngValue]="false">INATIVO</option>
          </select>
        </div>
        <div class="col-1 d-flex align-self-end mb-3">
          <button class="btn btn-success" (click)="obterListaUsuario(1)">
            {{'btn.filtrar'|translate}} <i class="mdi mdi-filter"></i>
          </button>
        </div>
      </div>
      <table class="table">
        <thead class="table-dark">
          <tr>
            <td>
              {{'usuarios.codigo'|translate}}
            </td>
            <td>
              {{'usuarios.nome'|translate}}
            </td>
            <td>
              {{'usuarios.email'|translate}}
            </td>
            <td>
              {{'usuarios.status'|translate}}
            </td>
            <td>
              {{'usuarios.situacaoCadastroDesc'|translate}}
            </td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let usuario of listaUsuario">
            <td>{{usuario.usuarioId}}</td>
            <td>{{usuario.nome.toUpperCase()}}</td>
            <td>{{usuario.email.toUpperCase()}}</td>
            <td>{{usuario.ativo? 'ATIVO':'INATIVO'}}</td>
            <td>{{usuario.situacaoCadastroDesc.toUpperCase()}}</td>
            <td>
              <div class="d-flex">
                <button class="btn btn-outline-warning mr-2" *ngIf="usuario.situacaoCadastro === situacaoCadastralEnum.PENDENTE" title="{{'btn.validarCadastro'|translate}}" (click)="open(modalValidarCadastro);obterUsuarioValidarCad(usuario)">
                  <i class="mdi mdi-account-alert"></i>
                </button>

                <button class="btn btn-outline-success mr-2" title="{{'btn.editar'|translate}}" [routerLink]="['/usuario/editar/'+usuario.usuarioId]">
                  <i class="mdi mdi-pencil"></i>
                </button>

                <button class="btn btn-outline-danger" (click)="open(mymodal);selecionarUsuario(usuario.usuarioId)">
                  <i class="mdi mdi-delete"></i>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <ngb-pagination [collectionSize]="filtro.total" [pageSize]="filtro.tamanhoPagina" [(page)]="filtro.numeroPagina"
                      [maxSize]="10" [rotate]="true" [boundaryLinks]="true" (pageChange)="obterListaUsuario($event)"></ngb-pagination>

    </nb-card-body>
  </nb-card>
</main>


<!-- Modals  -->

<ng-template #mymodal let-modal>
  <div class="modal-header">
    <h5 class="modal-title"> {{'usuarios.inativar'|translate}}</h5>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p> {{'usuarios.inativar'|translate}}?</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="modal.dismiss('Cross click')" data-dismiss="modal"> {{'btn.fechar'|translate}}</button>
    <button type="button" class="btn btn-danger" (click)="inativarUsuario()"> {{'sim'|translate}}</button>
  </div>
</ng-template>

<ng-template #modalValidarCadastro let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Validar o Cadastro</h5>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{usuarioSelecionado.nome}}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-warning" (click)="recusar();" data-dismiss="modal"> {{'btn.recusar'|translate}}</button>
    <button type="button" class="btn btn-success" (click)="aprovar();"> {{'btn.aprovar'|translate}}</button>
  </div>
</ng-template>
